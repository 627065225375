import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Alert
} from 'reactstrap'
// import EnermoSummaryWidgets from '../Widgets/Summary-Widgets';
import SensorsSummaryWidgets from '../Widgets/Summary-Widgets-Sensors';
import UtilitieSummaryWidgets from '../Widgets/Summary-Widgets-Utilities';
// import WeatherWidgets from '../Widgets/Weather-Widgets';
import Select from 'react-select';
import {connect} from 'react-redux'
import { BarLoader } from 'react-spinners'
import { fetchVisualModel, fetchVisualModelDevice, fetchVisualModelUtilities } from '../../../redux/actions/visual-modeling.act';
import { fetchDevices } from '../../../redux/actions/devices.act'
import { fetchSensorList } from '../../../redux/actions/sensor-list.act';
import { fetchUtilities } from '../../../redux/actions/utility-list.act'
import { fetchLiveData, fetchLiveDataByIdDeviceAndUnixTimeService, 
    fetchLiveDataByIdDeviceIdSensorAndUnixTimeService, liveMonitoringFetchCancelationService, liveMonitoringFetchRestart} from '../../../redux/actions/live-monitoring.act'
import { fetchKwhAverage, fetchSensorHistoryGrouping, fetchSensorSensorAlertGrouping, 
    fetchDeviceActiveTime, processLiveMonitoringFetchCancelation, processLiveMonitoringFetchRestart} from '../../../redux/actions/processing-live-monitoring.act'
import { getMonthlyKwhSets, setMonthlyKwhSets} from '../../../redux/actions/Dashboard/monthly-kwh-sets.act'
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/id';
import VisualModelingDrawing from '../Visual-Modeling/Visual-Modeling-Drawing';
import PowerConsumptionChart from '../../../components/Power-Consumption-Area-Chart/index.jsx';
import PowerConsumptionBarChart from '../../../components/Power-Consumption-Bar';
import UtilityUsageRank from '../../../components/Utility-Usage-Rank';
import SensorsHistoryLineChart from '../../../components/Sensor-History-Line';
import PowerAlertBarChart from '../../../components/Power-Alert-Bar'; 
import {TIME_FORECAST} from '../../../constants';
import {ToastContainer} from 'react-toastify';
import {getSensorIdByName} from '../../../helpers'
import CarbonEmission from './Carbon-Emission-Cards/'
import TariffCards from './Tarrif-Cards/index'
import BudgetCards from './Budget-Cards/'
import BudgetChart from './Budget-Chart/'
//3rd Party API Imports
import {fetchMyLocation, fetchTodayWeather} from '../../../services/3rd-parties/api-weather-meta-weather.service'
/* eslint-disable import/no-webpack-loader-syntax */
import allDeviceKWhAverageMonthlyService from "worker-loader!./Workers/allDeviceKWhAverageMonthlyService.worker.js";
var allDeviceKWhAverageMonthlySW = null
var globalAllDeviceKwhAvrg =  {
    allKwhDataMonthly: [],
    totalAllKwHDataMonthly: 0,
}
var globalAllDeviceTarrif =  {
    normal: 0,
    peak: 0,
}
class Dashboard extends Component {

    constructor(props){

        super(props);
        this.props.liveMonitoringFetchRestart()
        this.props.processLiveMonitoringFetchRestart()
        this.state = {
            selectedVisualMapData : [],
            selectedVisualMapDataLoaded : false,
            hideDatatable: false,
            selectedVisualModelLocation : {value:1},
            visualMapLocationData : [],
            locationFloorPlan : '',
            floorPlan : null,
            selectedInstalledSensors : [],
            selectedUtility : [],
            liveData : [],
            allAvailableSensors: [],
            sensorNames:[],
            startConsumptionDate: '',
            endConsumptionDate: '',
            startAlertDate: '',
            endAlertDate: '',
            startSensorsHistoryDate : '',
            endSensorsHistoryDate : '',
            startSankeyDate: '',
            endSankeyDate: '',
            comparatorConsumption:[],
            selectedcomparatorConsumption: {value:1},
            comparatorForecast:[],
            selectedcomparatorForecast: {value:1},
            timeForecast:TIME_FORECAST,
            selectedcomparatorAlert: {value:1},
            comparatorAlert:[],
            selectedTimeForecast: {value:1},
            comparatorConsumptionBadges: [],
            comparatorForecastBadges: [],
            comparatorAlertBadges: [],
            isConsumptionDateFill : false,
            isAlertDateFill : false,
            isForecastDateFill : false,
            consumptionData: [],
            utilityConsumption: [],
            alertData: [],
            sensorsHistoryData : [],
            myLocation: null,
            todaysWeather: {},
            selectedDeviceForConsumptionHistory: null,
            consumptionHistoryLoading: false,
            sensorsHistoryLoading: false,
            alertsHistoryLoading: false,
            utilityConsumptionLoading: false,
            allKwhData : [],
            allKwhDataPerLocation : [],
            drawingDataState: [],
            allLocationSensorsId: [],
            selectedLocationSensorsId: [],
            allMData : {}
        }

    }

     fetchedMyLocationAndTodaysWeather = async() => {
        let locationData = ""
        let weatherData = {}
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition( async position => {
                locationData = await fetchMyLocation(position.coords.latitude, position.coords.longitude)
                weatherData = await fetchTodayWeather(locationData)
                this.setState({
                    todaysWeather: weatherData,
                    myLocation: locationData
                })
            })
        } else { 
                //default
                weatherData = await fetchTodayWeather("jakarta")
                this.setState({
                    todaysWeather: weatherData,
                    myLocation: "jakarta"
                }) 
        }
    }

    temporaryRemoveDuplicateVMLocation(visualModelData){
        let tempVisualModelData = []
        let alreadyCheckLocationId = []
        visualModelData.forEach(vm => {
            let checkLocationIdByFilter = alreadyCheckLocationId.filter(id => id === vm.id_location)
            if(checkLocationIdByFilter.length === 0){
               tempVisualModelData.push(vm)
               alreadyCheckLocationId.push(vm.id_location)
            }
        })
        return tempVisualModelData
    }

    async drawingData(){
        try{
            const {visualModelData, visualModelDeviceData, visualModelUtilityData, devicesData, utilitiesData} = this.props
            let drawingData = this.temporaryRemoveDuplicateVMLocation(visualModelData.result)
            drawingData.forEach(data => {
                data.boundaries = visualModelDeviceData.result
                    .filter(vmd => vmd.id_visual_model === data.id)
                    data.boundaries.forEach(device => {
                        device.sensor_name=device.device_name
                        device.id = device.id_device
                        device.sensor_desc = devicesData.result
                                                .filter(d => d.id === device.id_device) || []
                        device.sensor_desc = device.sensor_desc.length > 0? device.sensor_desc[0].device_desc : null
                        device.widthAfterResize= device.width_after_resize
                        device.heightAfterResize=device.height_after_resize
                    })
                data.utilities = visualModelUtilityData.result
                    .filter(vmu => vmu.id_visual_model === data.id)
                    data.utilities.forEach(utility => {
                        utility.id = utility.id_utility
                        utility.id_device = utilitiesData.result
                                                    .filter(u => u.id === utility.id_utility) || []
                        utility.id_device = utility.id_device.length > 0? utility.id_device[0].id_device : null                   
                        utility.typeId = utilitiesData.result
                                                .filter(u => u.id === utility.id_utility) || []
                        utility.typeId = utility.typeId.length > 0? utility.typeId[0].id_utility_type : null
                        utility.widthAfterResize= utility.width_after_resize
                        utility.heightAfterResize=utility.height_after_resize
                    })
            })
            this.setState({
                drawingDataState: drawingData
            })
        } catch(error){
            console.log(error)
        }
    }

    async gatherAllMData(){
        const drawingData= this.state.drawingDataState
        const {sensorData} = this.props
        let drawingDataToLoop = drawingData
        let tempStart = moment().startOf('month').unix()
        let tempEnd = moment().endOf('month').unix()
        for(const drawingData of drawingDataToLoop) {
            for(const d of  drawingData.boundaries) {
                    if(d.sensor_desc.includes("[M]")){
                        let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                         await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(d.id, getNeededSensorId, tempStart, tempEnd)
                            .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                            .then(result  => {
                                let tempAllMData = this.state.allMData
                                tempAllMData[d.sensor_desc] = result
                                this.setState({
                                    allMData : tempAllMData
                                })
                            })
                    }
            }
        }
    }

    async componentDidMount(){
    try{
        // this.fetchedMyLocationAndTodaysWeather()
        allDeviceKWhAverageMonthlySW = new allDeviceKWhAverageMonthlyService();
        globalAllDeviceKwhAvrg =  {
            allKwhDataMonthly: [],
            totalAllKwHDataMonthly: 0,
        }
        globalAllDeviceTarrif =  {
            normal: 0,
            peak: 0,
        }
        await this.props.fetchVisualModel()
        await this.props.fetchVisualModelDevice()
        await this.props.fetchVisualModelUtilities()
        await this.props.fetchDevices();
        await this.props.fetchSensorList()
        await this.props.fetchUtilities()
        await this.props.getMonthlyKwhSets()
        this.setState({
            allAvailableSensors: this.props.sensorData.result
        })
        await this.drawingData()
        const data = this.state.drawingDataState
        if(data.length > 0){
            await this.createSelectFromVisualMapData();
            let sensorNamesArr = [];
            let sensorsDataTemp = []
            let allLocationSensorsIdTemp = []
            let selectedLocationSensorsIdTemp = []
            let availableDeviceToSelectElectricityTemp = []
            let availableDeviceToSelectTemp = []
            if(data){ 
                data.sort((a, b) => (a.layout_name > b.layout_name ? 1 : -1)) // ascending sort by layout name
                data.forEach((d, index) => {
                    let isLocationSameAsSelected = d.id_location === data[0].id_location
                    d.boundaries.forEach(d =>{
                        let getElectricitySensorId = getSensorIdByName(this.props.sensorData.result, d.id, "edel")
                        if(getElectricitySensorId && index === 0){
                            availableDeviceToSelectElectricityTemp.push({'label': d.sensor_desc, 'value': d.id})
                        }
                        if(index === 0){
                            availableDeviceToSelectTemp.push({'label': d.sensor_desc, 'value': d.id})
                            sensorNamesArr.push(d.sensor_desc);
                            sensorsDataTemp.push(d)
                        }
                        if(getElectricitySensorId&& d.sensor_desc.includes("[M]")){
                            allLocationSensorsIdTemp.push(getElectricitySensorId)
                        }
                        if(isLocationSameAsSelected && getElectricitySensorId&& d.sensor_desc.includes("[M]")){
                            selectedLocationSensorsIdTemp.push(getElectricitySensorId)
                        }
                    })
                })
                this.setState({
                    availableDeviceToSelect: availableDeviceToSelectTemp,
                    availableDeviceToSelectElectricity: availableDeviceToSelectElectricityTemp,
                    selectedLiveMonitoringDevice: availableDeviceToSelectTemp[0],
                    selectedLiveMonitoringDevice2: availableDeviceToSelectTemp.length > 1 ? availableDeviceToSelectTemp[1] : availableDeviceToSelectTemp[0],
                    selectedDeviceForConsumptionHistory: availableDeviceToSelectTemp[0],
                    selectedDeviceForSensorsHistory: availableDeviceToSelectTemp[0],
                    selectedDeviceForAlertHistory: availableDeviceToSelectTemp[0],
                    sensorNames :sensorNamesArr,
                    allLocationSensorsId: allLocationSensorsIdTemp,
                    selectedLocationSensorsId: selectedLocationSensorsIdTemp
                });

                this.setState({
                    sensorsHistoryLoading: true,
                    alertsHistoryLoading: true,
                    consumptionHistoryLoading: true,
                    utilityConsumptionLoading : true
                })
                await this.gatherAllMData()
                this.allDeviceKWhAverageMonthlyService()
                this.kWhService()
                this.liveMonitoringService()
                this.timerID = setInterval(() => this.liveMonitoringService(), 15000);
                this.timerID2 = setInterval(() => this.kWhService(), 3600000);
                this.utilitiesConsumptionService()
                this.timerID3 = setInterval(() => this.utilitiesConsumptionService(), 3600000);
                this.allDeviceKWhAverageService()
                this.timerID4 = setInterval(() => this.allDeviceKWhAverageService(), 3600000);
                // this.allDeviceKWhAverageServiceEachLocation(this.state.selectedVisualMapData.layout_name)
                // this.timerID6 = setInterval(() => this.allDeviceKWhAverageServiceEachLocation(this.state.selectedVisualMapData.layout_name), 3600000)
                // this.allDeviceKWhAverageMonthlyServiceEachLocation(this.state.selectedVisualMapData.layout_name)
                // this.timerID7 = setInterval(() => this.allDeviceKWhAverageMonthlyServiceEachLocation(this.state.selectedVisualMapData.layout_name), 3600000)

                //Default Graphs
                let tempStart =  moment().startOf('day')
                let tempEnd =  moment().endOf('day')
                let tempStartUnix = tempStart.unix()
                let tempEndUnix = tempEnd.unix()
                // let tempStartUnix2 =  moment().subtract(7, 'days').startOf('day').unix()
                // let tempEndUnix2 =  moment().endOf('day').unix()
                let dataTempKwh = [];
                let dataTempSensorsHistory = [];
                let dataTempAlert = [];
                let selectedDeviceConsumptionHistoryId = Number(this.state.selectedDeviceForConsumptionHistory.value)
                let selectedDeviceSensorHistoryId = Number(this.state.selectedDeviceForSensorsHistory.value) 
                let selectedDeviceForAlertHistoryId = Number(this.state.selectedDeviceForAlertHistory.value)

                let getNeededSensorId = getSensorIdByName(this.props.sensorData.result, selectedDeviceConsumptionHistoryId, "edel")
                this.props.fetchKwhAverage(selectedDeviceConsumptionHistoryId, getNeededSensorId, tempStartUnix, tempEndUnix)
                .then(() =>{return this.props.kWhAverage.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchKwhAverage(selectedDeviceConsumptionHistoryId, getNeededSensorId, tempStartUnix, tempEndUnix, {page: 1, size : totalData})
                //      })
                .then(result => {
                    if(result){
                        dataTempKwh = result
                        this.setState({
                            consumptionData : dataTempKwh,
                            consumptionHistoryLoading: false,
                        })
                    }
                })
                .catch((e)=> {console.log(e)})

                this.props.fetchSensorHistoryGrouping(selectedDeviceSensorHistoryId, tempStartUnix, tempEndUnix)
                .then(() =>{return this.props.sensorHistoryGrouping.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchSensorHistoryGrouping(selectedDeviceSensorHistoryId, tempStartUnix2, tempEndUnix2, {page: 1, size : totalData})
                //      })
                .then(result => {
                    if(result){
                        dataTempSensorsHistory = result
                        this.setState({
                            sensorsHistoryData : dataTempSensorsHistory,
                            sensorsHistoryLoading: false,
                            utilityConsumptionLoading : false
                        })
                    }
                })
                .catch((e)=> {console.log(e)})

                 this.props.fetchSensorSensorAlertGrouping(selectedDeviceForAlertHistoryId, tempStartUnix, tempEndUnix)
                .then(() =>{return this.props.sensorAlertrouping.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchSensorSensorAlertGrouping(selectedDeviceForAlertHistoryId, tempStartUnix, tempEndUnix, {page: 1, size : totalData})
                //      })
                .then(result => {
                    if(result){
                        dataTempAlert = result
                        this.setState({
                            alertData : dataTempAlert,
                            alertsHistoryLoading: false,
                        })
                    }
                })
                .catch((e)=> {console.log(e)})

                this.allDeviceKWhForDailyTariffsService()
                // this.timerID5 = setInterval(() => this.allDeviceKWhForDailyTariffsService(), 3600000);
                // this.allDeviceKWhForDailyTariffsServiceEachLocation(this.state.selectedVisualMapData.layout_name)
                // this.timerID8 = setInterval(() => this.allDeviceKWhForDailyTariffsServiceEachLocation(this.state.selectedVisualMapData.layout_name), 3600000)
                // this.allDeviceKWhForDailyTariffsMonthlyService()
                // this.timerID9 = setInterval(() => this.allDeviceKWhForDailyTariffsMonthlyService(), 3600000)
                
                this.setState({
                    startAlertDate: null,
                    endAlertDate: null,
                    isAlertDateFill : false,
                    sensorsHistoryData : dataTempSensorsHistory,
                })
            }
        }
    } catch{

    }

    }

    componentWillUnmount() {
        try{
            clearInterval(this.timerID);
            clearInterval(this.timerID2);
            clearInterval(this.timerID3);
            clearInterval(this.timerID4);
            clearInterval(this.timerID5);
            clearInterval(this.timerID6);
            clearInterval(this.timerID7);
            clearInterval(this.timerID8);
            clearInterval(this.timerID9);
            this.props.liveMonitoringFetchCancelationService()
            this.props.processLiveMonitoringFetchCancelation()
            allDeviceKWhAverageMonthlySW.terminate()
            this.setState = (state,callback)=>{
                return;
            }
        }catch(e){}
    }

    async allDeviceKWhAverageService(){
        try{
            this.setState({allKwhData : 0})
            const {sensorData} = this.props
            const drawingData= this.state.drawingDataState
            let tempStart = moment().startOf('day').unix()
            let tempEnd = moment().add(1,'days').startOf('day').unix()
            let drawingDataToLoop = drawingData
            let deviceTotal = 0
            await Promise.all(
                drawingDataToLoop.map(async drawingData => {
                    await drawingData.boundaries.map(async d => {
                    if(d.sensor_desc.includes("[M]")){
                        let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                        await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(d.id, getNeededSensorId, tempStart, tempEnd)
                        .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                        .then(result => {
                            if(!result)
                            return
                            let resultFinalIndex = result.length-1
                            this.setState({allKwhData : this.state.allKwhData
                                            + ((result[resultFinalIndex].value - result[0].value)/1000)})
                        })
                        .catch((e)=> {console.log(e)})
                        deviceTotal += 1
                    }
                })
            }))
            this.setState({
                allKwhDataDeviceTotal : deviceTotal
            })
        }catch(error){
            console.log(error)
        }
    }

    async allDeviceKWhAverageServiceEachLocation(locationName){
        try{
            this.setState({allKwhDataEachLocation : 0})
            const {sensorData} = this.props
            const drawingData= this.state.drawingDataState
            let tempStart = moment().startOf('day').unix()
            let tempEnd = moment().add(1,'days').startOf('day').unix()
            let drawingDataToLoop = drawingData
            drawingDataToLoop = drawingDataToLoop.filter(data => data.layout_name === locationName)
            let deviceTotal = 0
            await Promise.all(
                    await drawingDataToLoop[0].boundaries.map(async d => {
                        if(true){
                            this.props.fetchLiveDataByIdDeviceAndUnixTimeService(d.id, tempStart, tempEnd)
                            .then(() =>{return this.props.liveDataByIdDeviceTimeUnix.result})
                            .then(result  => {
                                if(!result)
                                return
                                let resultFinalIndex = result.length-1
                                this.setState({allKwhDataEachLocation : this.state.allKallKwhDataEachLocationwhData
                                                + ((result[resultFinalIndex].value - result[0].value/1000))})
                            })
                            .catch((e)=> {console.log(e)})
                        deviceTotal += 1}
                }))
            this.setState({
                allKwhDataDeviceTotalEachLocation : deviceTotal
            })
        }catch(error){
            console.log(error)
        }
    }

    async allDeviceKWhAverageMonthlyService(){
        try{
            this.setState({allKwhDataMonthly : [], totalAllKwHDataMonthly: 0})
            const {allMData} = this.state
            const drawingData= this.state.drawingDataState
            let todaysDate = Number(moment().format('DD'))
            let thisYearMonth = moment().format('YYYY-MM')
            let drawingDataToLoop = drawingData
            let tempAllKwhDataMonthly = []
            let tempAllTarrifDataMonthly = []
            let x = 0
            let interval
            for(const drawingData of drawingDataToLoop) {
                for(const  d of  drawingData.boundaries) {
                        if(d.sensor_desc.includes("[M]")){
                                    let result = allMData[d.sensor_desc]
                                    if(!result) return
                                    allDeviceKWhAverageMonthlySW.postMessage({
                                        "result" : result,
                                        "todaysDate" : todaysDate,
                                        "thisYearMonth" : thisYearMonth,
                                        "tempAllKwhDataMonthly": tempAllKwhDataMonthly,
                                        "tempAllTarrifDataMonthly": tempAllTarrifDataMonthly
                                    })
                        }
            }}
            allDeviceKWhAverageMonthlySW.onmessage = async function(e) {
                for (const kWh of e.data["allKwh"]){
                    globalAllDeviceKwhAvrg.totalAllKwHDataMonthly += await kWh["kwhAvg"]
                    let index = globalAllDeviceKwhAvrg.allKwhDataMonthly.findIndex(data => moment(data.date, "YYYY-MM-DD").toString() === moment(kWh["date"], "YYYY-MM-DD").toString())
                    if(index >= 0)
                        globalAllDeviceKwhAvrg.allKwhDataMonthly[index]["kwhAvg"] += Number(kWh["kwhAvg"])
                    else
                        globalAllDeviceKwhAvrg.allKwhDataMonthly.push({
                            "kwhAvg": Number(kWh["kwhAvg"]),
                            "date" : kWh["date"]
                        }) 
                }

                for (const kWh of e.data["allTarrif"]){
                    globalAllDeviceTarrif.normal += await kWh["normal"]
                }

            }
        }catch(error){
            console.log(error)
        }
    }

    async allDeviceKWhAverageMonthlyServiceEachLocation(locationName){
     try{
            this.setState({allKwhDataMonthlyEachLocation : [], totalAllKwHEachLocationDataMonthly:0})
            const {sensorData} = this.props
            const drawingData= this.state.drawingDataState
            let tempStart = moment().startOf('month').unix()
            let tempEnd = moment().endOf('month').unix()
            let drawingDataToLoop = await drawingData
            drawingDataToLoop = await drawingDataToLoop.filter(data => data.layout_name === locationName)
            let tempAllKwhDataMonthly = []
            // let deviceTotal = 0
            await Promise.all(
                drawingDataToLoop.map(async drawingData => {
                    await drawingData.boundaries.map(async d => {
                        // if(d.sensor_desc.includes("[M]")){
                            // let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                            this.props.fetchLiveDataByIdDeviceAndUnixTimeService(d.id, tempStart, tempEnd)
                            .then(() =>{return this.props.liveDataByIdDeviceTimeUnix.result})
                            .then(result  => {
                                            if(!result) return
                                            result.forEach(r => {
                                                let index = tempAllKwhDataMonthly.findIndex(data => data.date === r.date)
                                                if(index >= 0)
                                                    tempAllKwhDataMonthly[index]["kwhAvg"] = +(tempAllKwhDataMonthly[index]["kwhAvg"] + r["kwhAvg"]).toFixed(2)
                                                 else
                                                    tempAllKwhDataMonthly.push(r)                                 
                                            })
                                            const PUT_IN_LOWER_POSITION = -1
                                            const PUT_IN_HIGHER_POSITION = 1
                                            let sortedResult = tempAllKwhDataMonthly.sort((a, b) =>
                                                     (new Date(a.date) < new Date(b.date) ? PUT_IN_LOWER_POSITION : PUT_IN_HIGHER_POSITION))         
                                            let x = 0
                                            sortedResult.forEach((kWh, index) => {
                                                if(kWh["kwhAvg"] > 9999)
                                                    return
                                                x += kWh["kwhAvg"]
                                            })
                                            this.setState({
                                                totalAllKwHEachLocationDataMonthly: x,
                                                allKwhDataMonthlyEachLocation: sortedResult
                                            })
                                        })
                            // deviceTotal += 1
                        // }
                    })
                })
            )
            .catch((e)=> {console.log(e)})
        }catch(error){
            console.log(error)
        }
    }
    
    //S.O.S
    //  async allDeviceKWhForDailyTariffsMonthlyService(){
    //     try{
    //         this.setState({allKwhDataWhenNormalTimeMonthly: 0, allKwhDataWhenPeakTimeMonthly: 0})
    //         const {allMData} = this.state
    //         const drawingData= this.state.drawingDataState
    //         let tempAllTarrifDataMonthly = []
    //         let todaysDate = Number(moment().format('DD'))
    //         let thisYearMonth = moment().format('YYYY-MM')
    //         let drawingDataToLoop = drawingData
    //         for(const drawingData of drawingDataToLoop) {
    //             for(const  d of  drawingData.boundaries) {
    //                 if(d.sensor_desc.includes("[M]")){
    //                         let result = allMData[d.sensor_desc]
    //                         if(!result) return
    //                         allDeviceKWhForDailyTariffsMonthlySW.postMessage({
    //                             "result" : result,
    //                             "todaysDate" : todaysDate,
    //                             "thisYearMonth" : thisYearMonth,
    //                             "tempAllTarrifDataMonthly": tempAllTarrifDataMonthly
    //                         })
    //                 }
    //         }}
    //         allDeviceKWhForDailyTariffsMonthlySW.onmessage = async function(e) {
    //             for (const kWh of e.data){
    //                 globalAllDeviceTarrif.normal += kWh["normal"]
    //                 globalAllDeviceTarrif.peak += kWh["peak"]
    //             }
    //         }
    //     }catch(error){
    //         console.log(error)
    //     }
    // }
    
    async allDeviceKWhForDailyTariffsService(){
        try{
            this.setState({allKwhDataWhenNormalTime: 0, allKwhDataWhenPeakTime: 0})
            const {sensorData} = this.props
            const drawingData= this.state.drawingDataState
            //Prepare time for normal tariff time 22:00 - 17:00 next day
            let tempStartFirstNormal =  moment().startOf('day').unix()
            let tempEndFirstNormal = moment().hours(17).minutes(0).seconds(0).unix()
            let tempStartSecondNormal =  moment().hours(22).minutes(0).seconds(0).unix()
            let tempEndSecondNormal =  moment().endOf('day').unix()
            //Prepare time for peak tariff time 17:00 - 22:00 today
            let tempStartPeak = moment().hours(17).minutes(0).seconds(0).unix()
            let tempEndPeak = moment().hours(22).minutes(0).seconds(0).unix()
            // if(moment() < moment('17:00:00', "hh:mm:ss")){
            //     tempStartPeak = moment().subtract(1, 'days').hours(17).minutes(0).seconds(0).unix()
            //     tempEndPeak = moment().subtract(1, 'days').hours(22).minutes(0).seconds(0).unix()           
            // }
            let drawingDataToLoop = drawingData
            await Promise.all(
                drawingDataToLoop.map(async drawingData => {
                    await drawingData.boundaries.map(async d => {
                    if(d.sensor_desc.includes("[M]")){
                        let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                        await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(d.id, getNeededSensorId, tempStartFirstNormal, tempEndFirstNormal)
                        .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                        .then(result => {
                            if(!result)
                            return
                            let resultFinalIndex = result.length-1
                            if(!result[resultFinalIndex])
                                return
                            console.log()
                            this.setState({allKwhDataWhenNormalTime : this.state.allKwhDataWhenNormalTime
                                            + Number((result[resultFinalIndex].value - result[0].value)/1000)})
                        })
                        await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(d.id, getNeededSensorId, tempStartSecondNormal, tempEndSecondNormal)
                        .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                        .then(result => {
                            if(!result)
                            return
                             if(!result[resultFinalIndex])
                                return
                            let resultFinalIndex = result.length-1
                            this.setState({allKwhDataWhenNormalTime :  this.state.allKwhDataWhenNormalTime
                                            + Number((result[resultFinalIndex].value - result[0].value)/1000)})
                        })
                        await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(d.id, getNeededSensorId, tempStartPeak, tempEndPeak)
                        .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                        .then(result => {
                            if(!result)
                            return
                            let resultFinalIndex = result.length-1
                            if(!result[resultFinalIndex])
                            return
                            this.setState({allKwhDataWhenPeakTime : this.state.allKwhDataWhenPeakTime
                                            + Number((result[resultFinalIndex].value - result[0].value)/1000)})
                        })
                }
                    })
                })
            )
        }catch(error){
            console.log(error)
        }
    }

    async allDeviceKWhForDailyTariffsServiceEachLocation(locatioName){
        try{
            this.setState({allKwhDataWhenNormalTimeEachLocation: 0, allKwhDataWhenPeakTimeEachLocation: 0})
            const {sensorData} = this.props
            const drawingData= this.state.drawingDataState
            //Prepare time for normal tariff time 22:00 - 17:00 next day
            let tempStartFirstNormal =  moment().startOf('day').unix()
            let tempEndFirstNormal = moment().hours(17).minutes(0).seconds(0).unix()
            let tempStartSecondNormal =  moment().hours(22).minutes(0).seconds(0).unix()
            let tempEndSecondNormal =  moment().endOf('day').unix()
            //Prepare time for peak tariff time 17:00 - 22:00 today
            let tempStartPeak = moment().hours(17).minutes(0).seconds(0).unix()
            let tempEndPeak = moment().hours(22).minutes(0).seconds(0).unix()
            if(moment() < moment('17:00:00', "hh:mm:ss")){
                tempStartPeak = moment().subtract(1, 'days').hours(17).minutes(0).seconds(0).unix()
                tempEndPeak = moment().subtract(1, 'days').hours(22).minutes(0).seconds(0).unix()           
            }
            let drawingDataToLoop = drawingData
            drawingDataToLoop = drawingDataToLoop.filter(data => data.layout_name === locatioName)
            await Promise.all(
                    await drawingDataToLoop[0].boundaries.map(async d => {
                    if(d.sensor_desc.includes("[M]")){
                        let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                        this.props.fetchKwhAverage(d.id, getNeededSensorId, tempStartFirstNormal, tempEndFirstNormal)
                                    .then(() =>{ return this.props.kWhAverage.result })
                                    // .then(result => { 
                                    //         let totalData = result.meta.totalData
                                    //         return this.props.fetchKwhAverage(d.id, getNeededSensorId, tempStart, tempEnd, {page: 1, size : totalData})
                                    //      })
                                    .then(() => {
                                        let result = this.props.kWhAverage.result
                                        if(!result)
                                            return
                                        if(result[0]){
                                            let resultKwh = result[0]["kwhAvg"]
                                            this.setState({allKwhDataWhenNormalTimeEachLocation : this.state.allKwhDataWhenNormalTimeEachLocation + resultKwh})
                                        }
                                    })
                                    .catch((e)=> {console.log(e)})
                        }
                    }),
                    await drawingDataToLoop[0].boundaries.map(async d => {
                    if(d.sensor_desc.includes("[M]")){
                        let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                        this.props.fetchKwhAverage(d.id, getNeededSensorId, tempStartSecondNormal, tempEndSecondNormal)
                                    .then(() =>{ return this.props.kWhAverage.result })
                                    // .then(result => { 
                                    //         let totalData = result.meta.totalData
                                    //         return this.props.fetchKwhAverage(d.id, getNeededSensorId, tempStart, tempEnd, {page: 1, size : totalData})
                                    //      })
                                    .then(() => {
                                        let result = this.props.kWhAverage.result
                                        if(!result)
                                            return
                                        if(result[0]){
                                            let resultKwh = result[0]["kwhAvg"]
                                            this.setState({allKwhDataWhenNormalTimeEachLocation : this.state.allKwhDataWhenNormalTimeEachLocation + resultKwh})
                                        }
                                    })
                                    .catch((e)=> {console.log(e)})
                        }
                    }),
                    await drawingDataToLoop[0].boundaries.map(async d => {
                    if(d.sensor_desc.includes("[M]")){
                        let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                        this.props.fetchKwhAverage(d.id, getNeededSensorId, tempStartPeak, tempEndPeak)
                                    .then(() =>{ return this.props.kWhAverage.result })
                                    // .then(result => { 
                                    //         let totalData = result.meta.totalData
                                    //         return this.props.fetchKwhAverage(d.id, getNeededSensorId, tempStart, tempEnd, {page: 1, size : totalData})
                                    //      })
                                    .then(() => {
                                        let result = this.props.kWhAverage.result
                                        if(!result)
                                            return
                                        if(result[0]){
                                            let resultKwh = result[0]["kwhAvg"]
                                            this.setState({allKwhDataWhenPeakTimeEachLocation : this.state.allKwhDataWhenPeakTimeEachLocation + resultKwh})
                                        }
                                    })
                                    .catch((e)=> {console.log(e)})
                        }
                    })
            )
        }catch(error){
            console.log(error)
        }
    }

    async kWhService(){
        try{
            this.setState({kwhRate : 0})
            const {selectedVisualMapData} = this.state
            const {sensorData} = this.props
            let tempStart = moment().subtract(1, 'hours').unix()
            let tempEnd = moment().unix()
            let tempAllKwhData = []
            let devicesTotal = 0
            await Promise.all(
            selectedVisualMapData.boundaries.map(async d => {
                    devicesTotal += 1
                    let getNeededSensorId = getSensorIdByName(sensorData.result, d.id, "edel")
                    await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(d.id, getNeededSensorId, tempStart, tempEnd)
                    .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                        .then(result  => {
                            if(!result)
                                return
                            let resultFinalIndex = result.length-1
                            tempAllKwhData.push({
                                device_id : d.id,
                                value : (result[resultFinalIndex].value - result[0].value)/1000
                            })
                        })
                        .catch((e)=> {console.log(e)})
                })
            ).then(() =>           
             this.setState({
                allKwhDataPerLocation : tempAllKwhData,
                allDevicePerLocationTotal : devicesTotal,
            }))
        }catch{
            
        }
    }

    async utilitiesConsumptionService(){
        try{
            const {selectedVisualMapData} = this.state
            const {sensorData} = this.props
            let tempStart = moment().startOf('day').unix()
            let tempEnd = moment().endOf('day').unix()
            let tempUtilities = []
            const drawingData= this.state.drawingDataState
            if(this.props.utilitiesData.result){
                drawingData.filter(item => item.layout_name === (selectedVisualMapData.layout_name ? selectedVisualMapData.layout_name : null)
                )[0]
                    .utilities.forEach(data => {
                        tempUtilities.push(
                            this.props.utilitiesData.result
                                    .filter(item => item.id === data.id)[0]
                        )
                })
            }
            let tempUtilityWattHours = [] 
            let totalAllKwh = 0
            await Promise.all(                
                    tempUtilities.map(async utility => {
                        let getNeededSensorId = getSensorIdByName(sensorData.result, utility.id_device, "edel")
                        await this.props.fetchLiveDataByIdDeviceIdSensorAndUnixTimeService(utility.id_device, getNeededSensorId, tempStart, tempEnd)
                                    .then(() =>{return this.props.liveDataByIdDeviceIdSensorTimeUnix.result})
                                    .then(result  => {
                                        if(!result)
                                        return
                                        let resultFinalIndex = result.length-1
                                        let kWhTotal = (result[resultFinalIndex].value - result[0].value)/1000
                                        tempUtilityWattHours.push({
                                            id: utility.id,
                                            typeId: utility.id_utility_type,
                                            utilityName : utility.utility_name,
                                            typeName : utility.utility_type_name,
                                            usagePercentage : kWhTotal
                                        })                                        
                                        totalAllKwh = (parseFloat(totalAllKwh) + parseFloat(kWhTotal)).toFixed(2)                                        })
                                    .catch((e)=> {console.log(e)}) 
                        return
                    })
            )
            .then(() => {
                tempUtilityWattHours.map(utility => {
                    utility.usagePercentage = Math.round((100 * parseFloat(utility.usagePercentage))/totalAllKwh)
                    return
                })
            })
            .then(() => {
                this.setState({
                    utilityConsumption : tempUtilityWattHours,
                })
            })
        } catch{}
    }

    async liveMonitoringService() {
        if(this.state.selectedVisualMapDataLoaded){
            const {selectedVisualMapData} = this.state;
            let dataObj = {}
            let tempSensorNames = {}
            let tempStart = moment().subtract(1,'hours').unix()
            let tempEnd = moment().endOf('day').unix()
            await Promise.all(
                selectedVisualMapData.boundaries.map((b)=> {
                    let isDoor = false
                    let filterDoor = this.state.allAvailableSensors.filter(sensor => sensor.id_device === b.id)
                    if(filterDoor.some( device => device['sensor_type_parameter'] === 'door' )){
                        tempStart = moment().subtract(1, 'weeks').subtract(1, 'days').startOf('day').unix()
                        isDoor = true
                    }else{
                        tempStart = moment().subtract(1,'hours').unix()
                    }
                     this.props.fetchLiveDataByIdDeviceAndUnixTimeService(b.id, tempStart, tempEnd)
                    .then(() => {   
                        let liveDataResult = this.props.liveDataByIdDeviceTimeUnix.result
                        if(!("device_"+b.id in dataObj))
                            dataObj["device_"+b.id] = []
                        if(!("device_"+b.id in tempSensorNames))
                            tempSensorNames["device_"+b.id] = []
                        if(!liveDataResult)
                            return
                        if(liveDataResult.length > 0 && liveDataResult !== undefined){
                            liveDataResult
                                .forEach((data, index) => {
                                    let parameter = ''
                                    let parameterValue = ''
                                    let dataToPush = []
                                    if(index === 0){
                                        dataToPush = {
                                            "date": moment.unix(data.time_unix).format("YYYY-MM-DD"),
                                            "time": moment.unix(data.time_unix).format("YYYY-MM-DD HH:mm:ss"),
                                        }
                                        parameter = data.type;
                                        parameterValue = data.value 
                                        dataToPush[parameter] = parameterValue
                                        dataObj["device_"+b.id].push(dataToPush)
                                    }
            
                                    if(index > 0){
                                        if(moment.unix(liveDataResult[index-1].time_unix).format("HH:mm:ss") 
                                                                === moment.unix(data.time_unix).format("HH:mm:ss")){
                                            parameter = data.type;
                                            parameterValue = data.value 
                                            dataObj["device_"+b.id][dataObj["device_"+b.id].length-1][parameter] = parameterValue
                                        } else {
                                            dataToPush = {
                                                "date": moment.unix(data.time_unix).format("YYYY-MM-DD"),
                                                "time": moment.unix(data.time_unix).format("YYYY-MM-DD HH:mm:ss"),
                                            }
                                            parameter = data.type;
                                            parameterValue = data.value 
                                            dataToPush[parameter] = parameterValue
                                            dataObj["device_"+b.id].push(dataToPush)
                                        }
                                    }
                                    if(!tempSensorNames["device_"+b.id].includes(parameter)){
                                        tempSensorNames["device_"+b.id].push(parameter)
                                    }

                                    if(index === liveDataResult.length-1){
                                        if(isDoor){
                                            console.log(dataObj["device_"+b.id])
                                            let filterByOpenedDoor = dataObj["device_"+b.id].filter(d => d.door === 1)
                                            let filterByOpenedDoorLastIndex = filterByOpenedDoor.length-1
                                            if(filterByOpenedDoorLastIndex !== -1){
                                                dataObj["device_"+b.id][dataObj["device_"+b.id].length-1]["last_open"] = filterByOpenedDoor[filterByOpenedDoorLastIndex].time
                                            }
                                            if(filterByOpenedDoorLastIndex === -1){
                                                dataObj["device_"+b.id][dataObj["device_"+b.id].length-1]["last_open"] = ""
                                            }
                                        }
                                    }
                                })
                            if(Object.keys(dataObj).length === selectedVisualMapData.boundaries.length)
                                this.setState({
                                    liveData: dataObj,
                                    sensorNames: tempSensorNames
                                });
                        } 
                    })
                })
            )
            .catch(function(error){
                console.log(error)
                //toast.error(handleErrorResponseCode(error))
            });
            
        }
    }

    handleAddNewLayoutClicked = () => {
        this.setState({hideDatatable : true})
    }

    handleAddNewLayoutClosed = () => {
        this.setState({hideDatatable : false})
    }

    async createSelectFromVisualMapData(){
        const filteredVmData= this.state.drawingDataState
        if(filteredVmData){ 
            filteredVmData.sort((a, b) => (a.layout_name > b.layout_name ? 1 : -1)) // ascending sort by layout name
            let tempVisualMapLocationData = [];
            filteredVmData.forEach(data => {
                tempVisualMapLocationData.push({
                    'label' : data.layout_name,
                    'value' : data.id
                })
            })
            this.setState({
                selectedVisualMapData : filteredVmData[0] || null,
                visualMapLocationData : tempVisualMapLocationData,
                selectedVisualMapDataLoaded : true
            });
        }
    }

    handleSelectionDeviceOnChange = (selectedLiveMonitoringDevice) => {
        this.setState({ 
            selectedLiveMonitoringDevice: selectedLiveMonitoringDevice
        })
    }

    handleSelectionDeviceOnChange2 = (selectedLiveMonitoringDevice2) => {
        this.setState({ 
            selectedLiveMonitoringDevice2: selectedLiveMonitoringDevice2
        })
    }


    handleSelectionDeviceOnChangeForConsumptionHistory = (selectedDeviceForConsumptionHistory) => {
        this.setState({ 
            selectedDeviceForConsumptionHistory: selectedDeviceForConsumptionHistory
        })
    }

    handleSelectionDeviceOnChangeForSensorsHistory = (selectedDeviceForSensorsHistory) => {
        this.setState({ 
            selectedDeviceForSensorsHistory: selectedDeviceForSensorsHistory
        })
    }

    handleSelectionDeviceOnChangeForAlertHistory = (selectedDeviceForAlertHistory) => {
        this.setState({ 
            selectedDeviceForAlertHistory: selectedDeviceForAlertHistory
        })
    }

    handleLocationOnChange = async (selectedVisualModelLocation) => { 
        try{
            this.setState({selectedVisualMapDataLoaded : false, selectedLocationSensorsId: []})
            await this.drawingData()     
            const vmData= this.state.drawingDataState
            const filteredVmDataById = vmData.filter(item => item.id === selectedVisualModelLocation.value)[0];
            let availableDeviceToSelectTemp = []
            let availableDeviceToSelectElectricityTemp = []
            let sensorNamesArr = [];
            let selectedLocationSensorsIdTemp = []
            filteredVmDataById.boundaries.forEach(async d =>{
                let filterEdel = this.state.allAvailableSensors.filter(sensor => sensor.id_device === d.id)
                if(filterEdel.some( vendor => vendor['sensor_type_parameter'] === 'edel' )){
                    availableDeviceToSelectElectricityTemp.push({'label': d.sensor_desc, 'value': d.id})
                    selectedLocationSensorsIdTemp.push(this.state.allAvailableSensors.filter(sensor => sensor.id_device === d.id && sensor.sensor_type_parameter === "edel")[0].id)
                }
                availableDeviceToSelectTemp.push({'label': d.sensor_desc, 'value': d.id})
                sensorNamesArr.push(d.sensor_desc);
            })

            this.setState({
                availableDeviceToSelect: availableDeviceToSelectTemp,
                availableDeviceToSelectElectricity: availableDeviceToSelectElectricityTemp,
                selectedLiveMonitoringDevice: availableDeviceToSelectTemp[0],
                selectedLiveMonitoringDevice2: availableDeviceToSelectTemp.length > 1 ? availableDeviceToSelectTemp[1] : availableDeviceToSelectTemp[0],
                selectedDeviceForConsumptionHistory: availableDeviceToSelectTemp[0],
                selectedDeviceForSensorsHistory: availableDeviceToSelectTemp[0],
                selectedDeviceForAlertHistory: availableDeviceToSelectTemp[0],
                sensorNames :sensorNamesArr,
                selectedLocationSensorsId: selectedLocationSensorsIdTemp
            });

            this.setState({ 
                selectedVisualModelLocation: selectedVisualModelLocation,
                selectedVisualMapData : filteredVmDataById,
                sensorNames : sensorNamesArr,
                liveData: [],
                availableDeviceToSelect: availableDeviceToSelectTemp,
                availableDeviceToSelectElectricity: availableDeviceToSelectElectricityTemp,
                selectedLiveMonitoringDevice: availableDeviceToSelectTemp[0],
                selectedLiveMonitoringDevice2: availableDeviceToSelectTemp.length > 1 ? availableDeviceToSelectTemp[1] : availableDeviceToSelectTemp[0],
                selectedDeviceForConsumptionHistory: availableDeviceToSelectTemp[0],
                selectedDeviceForSensorsHistory: availableDeviceToSelectTemp[0],
                selectedDeviceForAlertHistory: availableDeviceToSelectTemp[0]
            }, async () => {

                this.setState({selectedVisualMapDataLoaded : true})

                await this.liveMonitoringService()
                await this.kWhService()
                this.utilitiesConsumptionService()

                this.setState({
                    sensorsHistoryLoading: true,
                    alertsHistoryLoading: true,
                    consumptionHistoryLoading: true,
                    utilityConsumptionLoading : true
                })

                let tempStart =  moment().startOf('day')
                let tempEnd =  moment().endOf('day')
                let tempStartUnix = tempStart.unix()
                let tempEndUnix = tempEnd.unix()
                // let tempStartUnix2 =  moment().subtract(7, 'days').startOf('day').unix()
                // let tempEndUnix2 =  moment().endOf('day').unix()
                let dataTempKwh = [];
                let dataTempSensorsHistory = [];
                let dataTempAlert = [];
                let selectedDeviceConsumptionHistoryId = Number(this.state.selectedDeviceForConsumptionHistory.value)
                let selectedDeviceSensorHistoryId = Number(this.state.selectedDeviceForSensorsHistory.value) 
                let selectedDeviceForAlertHistoryId = Number(this.state.selectedDeviceForAlertHistory.value)
    
                let getNeededSensorId = getSensorIdByName(this.props.sensorData.result, selectedDeviceConsumptionHistoryId, "edel")

                // this.allDeviceKWhAverageServiceEachLocation(filteredVmDataById.layout_name)
                // this.allDeviceKWhAverageMonthlyServiceEachLocation(filteredVmDataById.layout_name)
                // this.allDeviceKWhForDailyTariffsServiceEachLocation(filteredVmDataById.layout_name)

                this.props.fetchKwhAverage(selectedDeviceConsumptionHistoryId, getNeededSensorId, tempStartUnix, tempEndUnix)
                .then(() =>{return this.props.kWhAverage.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchKwhAverage(selectedDeviceConsumptionHistoryId, getNeededSensorId, tempStartUnix, tempEndUnix, {page: 1, size : totalData})
                //      })
                .then(result => {
                    if(result){
                        dataTempKwh = result
                        this.setState({
                            consumptionData : dataTempKwh,
                            consumptionHistoryLoading: false,
                        })
                    }
                })
                .catch((e)=> {console.log(e)})
    
                this.props.fetchSensorHistoryGrouping(selectedDeviceSensorHistoryId, tempStartUnix, tempEndUnix)
                .then(() =>{return this.props.sensorHistoryGrouping.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchSensorHistoryGrouping(selectedDeviceSensorHistoryId, tempStartUnix2, tempEndUnix2, {page: 1, size : totalData})
                //      })
                .then(result => {
                    if(result){
                        dataTempSensorsHistory = result
                        this.setState({     
                            sensorsHistoryData : dataTempSensorsHistory,
                            sensorsHistoryLoading: false,
                            utilityConsumptionLoading : false
                        })
                    }
                })
                .catch((e)=> {console.log(e)})
    
                this.props.fetchSensorSensorAlertGrouping(selectedDeviceForAlertHistoryId, tempStartUnix, tempEndUnix)
                .then(() =>{return this.props.sensorAlertrouping.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchSensorSensorAlertGrouping(selectedDeviceForAlertHistoryId, tempStartUnix, tempEndUnix, {page: 1, size : totalData})
                //      })
                .then(result => {
                    if(result){
                        dataTempAlert = result
                        this.setState({
                            alertData : dataTempAlert,
                            alertsHistoryLoading: false,
                        })
                    }
                })
                .catch((e)=> {console.log(e)})
 
            })

        }catch{
            
        }
    }
    
    showConsumptionDatePicker = () => {
        moment.locale('id');
        let startDateStateConsumption = moment(this.state.startConsumptionDate);
        let endDateStateConsumption= moment(this.state.endConsumptionDate);
        return(
            <DateRangePicker
                startDateId="startConsumptionDate"
                endDateId="endConsumptionDate"
                startDate={startDateStateConsumption.isValid() ? moment(startDateStateConsumption,'M/D/YYYY') : null}
                endDate={endDateStateConsumption.isValid() ? moment(endDateStateConsumption,'M/D/YYYY') : null}
                isOutsideRange={(day) => day.isAfter(moment(startDateStateConsumption,'M/D/YYYY').hour(12).add(30, 'days')) || day.isAfter(moment().hour(12))}
                onDatesChange={this.handleConsumptionChangeDate}
                focusedInput={this.state.focusedInput1}
                onFocusChange={focusedInput1 => this.setState({focusedInput1})}
                orientation={this.state.orientation1}
                openDirection={this.state.openDirection1}
                showClearDates={true}
                minimumNights={0}
            />
        )
    }

    handleConsumptionChangeDate = async ({startDate, endDate}) => {
        // console.log(startConsumptionDate, endConsumptionDate)
        let startValue;
        let endValue;
        let tempStart;
        let tempEnd ;

        if(startDate !== null || endDate !== null){
            let correctorStart = moment(startDate).startOf('day');
            let correctorEnd = moment(endDate).endOf('day')

            startValue = correctorStart
            endValue = correctorEnd
            
            tempStart = moment(startValue).unix();
            tempEnd = moment(endValue).unix();

            let dataResult = [];
            if(startDate !== null && endDate !== null){
                this.setState({
                    consumptionHistoryLoading : true
                })
                let selectedDeviceConsumptionHistoryId = Number(this.state.selectedDeviceForConsumptionHistory.value)
                let getNeededSensorId = getSensorIdByName(this.props.sensorData.result, selectedDeviceConsumptionHistoryId, "edel")
                await this.props.fetchKwhAverage(selectedDeviceConsumptionHistoryId, getNeededSensorId, tempStart, tempEnd)
                .then(() =>{return this.props.kWhAverage.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchKwhAverage(selectedDeviceConsumptionHistoryId, getNeededSensorId, tempStart, tempEnd, {page: 1, size : totalData})
                //      })
                .then(() => {
                    let result = this.props.kWhAverage.result
                    if(result){
                        dataResult = result
                    }
                })
                .catch((e)=> {console.log(e)})         
            }
            this.setState({
                startConsumptionDate: startValue,
                endConsumptionDate: endValue,
                isConsumptionDateFill: true,
                consumptionData : dataResult,
                consumptionHistoryLoading: false
            })
        } else {
            this.setState({
                startConsumptionDate: null,
                endConsumptionDate: null,
                isConsumptionDateFill: true,
            })
        }
    }

    showAlertDatePicker = () => {
        moment.locale('id');
        let startDateStateAlert = moment(this.state.startAlertDate);
        let endDateStateAlert= moment(this.state.endAlertDate);
        return(
            <DateRangePicker
                startDateId="startAlertDate"
                endDateId="endAlertDate"
                startDate={startDateStateAlert.isValid() ? moment(startDateStateAlert,'M/D/YYYY') : null}
                endDate={endDateStateAlert.isValid() ? moment(endDateStateAlert,'M/D/YYYY') : null}
                isOutsideRange={(day) => day.isAfter(moment(startDateStateAlert,'M/D/YYYY').hour(12).add(30, 'days')) || day.isAfter(moment().hour(12))}
                onDatesChange={this.handleAlertChangeDate}
                focusedInput={this.state.focusedInput2}
                onFocusChange={focusedInput2 => this.setState({focusedInput2})}
                orientation={this.state.orientation2}
                openDirection={this.state.openDirection2}
                showClearDates={true}
                minimumNights={0}
            />
        )
    }

    handleAlertChangeDate = async ({startDate, endDate}) => {
        let startValue;
        let endValue;
        let tempStart;
        let tempEnd ;
        let correctorStart = moment(startDate).startOf('day');
        let correctorEnd = moment(endDate).endOf('day')
        startValue = correctorStart
        endValue = correctorEnd
        tempStart = moment(startValue).unix();
        tempEnd = moment(endValue).unix();
        let dataResult = [];

        if(startDate !== null || endDate !== null){
            if(startDate !== null && endDate !== null){
                this.setState({
                    alertsHistoryLoading : true
                })
        
                await this.props.fetchSensorSensorAlertGrouping(this.state.selectedDeviceForAlertHistory.value, tempStart, tempEnd)
                .then(() =>{return this.props.sensorAlertrouping.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchSensorSensorAlertGrouping(this.state.selectedDeviceForAlertHistory.value, tempStart, tempEnd, {page: 1, size : totalData})
                //      })
                .then(() => {
                    let result = this.props.sensorAlertrouping.result
                    if(result){
                        dataResult = result
                    }
                })
                .catch((e)=> {console.log(e)})
            }
            this.setState({
                startAlertDate: startValue,
                endAlertDate: endValue,
                isAlertDateFill : true,
                alertData : dataResult,
                alertsHistoryLoading: false
            })
            
            // console.log(startValue, endValue)
            // this.props.fetchFleetReport(this.props.match.params.id, startValue, endValue, true);
        } else {
            this.setState({
                startAlertDate: null,
                endAlertDate: null,
            })
        }
    
    }

    showSensorsHistoryDatePicker = () => {
        moment.locale('id');
        let startDateState = moment(this.state.startSensorsHistoryDate);
        let endDateState= moment(this.state.endSensorsHistoryDate);
        return(
            <DateRangePicker
                startDateId="startSensorsHistoryDate"
                endDateId="endSensorsHistoryDate"
                startDate={startDateState.isValid() ? moment(startDateState,'M/D/YYYY') : null}
                endDate={endDateState.isValid() ? moment(endDateState,'M/D/YYYY') : null}
                isOutsideRange={(day) => day.isAfter(moment(startDateState,'M/D/YYYY').hour(12).add(30, 'days')) || day.isAfter(moment().hour(12))}
                onDatesChange={this.handleSensorsHistoryChangeDate}
                focusedInput={this.state.focusedInput3}
                onFocusChange={focusedInput3 => this.setState({focusedInput3})}
                orientation={this.state.orientation3}
                openDirection={this.state.openDirection3}
                showClearDates={true}
                minimumNights={0}
            />
        )

    }

    handleSensorsHistoryChangeDate = async ({startDate, endDate}) => {
        // console.log(startAlertDate, endAlertDate)
        let startValue;
        let endValue;
        let tempStart;
        let tempEnd ;
        let correctorStart = moment(startDate).startOf('day');
        let correctorEnd = moment(endDate).endOf('day')
        startValue = correctorStart
        endValue = correctorEnd
        tempStart = moment(startValue).unix();
        tempEnd = moment(endValue).unix();
        let dataResult = [];

        if(startDate !== null || endDate !== null){
            if(startDate !== null && endDate !== null){
                this.setState({
                    sensorsHistoryLoading : true
                })

                await this.props.fetchSensorHistoryGrouping(this.state.selectedDeviceForSensorsHistory.value, tempStart, tempEnd)
                .then(() =>{return this.props.sensorHistoryGrouping.result })
                // .then(result => { 
                //         let totalData = result.meta.totalData
                //         return this.props.fetchSensorHistoryGrouping(this.state.selectedDeviceForSensorsHistory.value, tempStart, tempEnd, {page: 1, size : totalData})
                //      })
                .then(() => {
                    let result = this.props.sensorHistoryGrouping.result
                    if(result){
                        dataResult = result
                    }
                })
                .catch((e)=> {console.log(e)})
            }
            this.setState({
                startSensorsHistoryDate: startValue,
                endSensorsHistoryDate: endValue,
                //isAlertDateFill : true,
                sensorsHistoryData : dataResult,
                sensorsHistoryLoading: false
            })
            
            // console.log(startValue, endValue)
            // this.props.fetchFleetReport(this.props.match.params.id, startValue, endValue, true);
        } else {
            this.setState({
                startSensorsHistoryDate: null,
                endSensorsHistoryDate: null,
            })
        }
    
    }

    render() {
        const {selectedVisualModelLocation, visualMapLocationData, selectedVisualMapData, selectedVisualMapDataLoaded, availableDeviceToSelect} = this.state;
        //selectedLiveMonitoringDevice2
        const {selectedLiveMonitoringDevice, availableDeviceToSelectElectricity} = this.state;
        const {selectedDeviceForConsumptionHistory, selectedDeviceForSensorsHistory, selectedDeviceForAlertHistory} = this.state; 
        //kwhRate, allDevicePerLocationTotal allKwhDataDeviceTotal
        const {allKwhData, allKwhDataMonthly, totalAllKwHDataMonthly, allKwhDataEachLocation, allLocationSensorsId} = this.state; 
        const {consumptionData, alertData, sensorsHistoryData, utilityConsumption} = this.state;
        const {sensorNames, liveData, allAvailableSensors, allKwhDataWhenNormalTime, allKwhDataWhenPeakTime, selectedLocationSensorsId} = this.state;
        const {totalAllKwHEachLocationDataMonthly, allKwhDataMonthlyEachLocation, allKwhDataWhenNormalTimeEachLocation, allKwhDataWhenPeakTimeEachLocation} = this.state
        // const {allKwhDataWhenNormalTimeMonthly, allKwhDataWhenPeakTimeMonthly} = this.state
        //3rd party state myLocation 
        // const {todaysWeather} = this.state
        // console.log("ALLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")
        // console.log(totalAllKwHDataMonthly)
        // console.log(totalAllKwHEachLocationDataMonthly)

        // const BASE_LINK_WEATHER = "https://www.metaweather.com"
        return (
            <div className="animated fadeIn">
                {this.state.drawingDataState.length === 0? 
                    <Alert color="warning">
                        Create a visual model to build the dashboard!
                    </Alert> : null}
                    <Card>
                        <CardHeader>
                            <b>Monthly Consumption</b>
                        </CardHeader>
                        <CardBody>
                        {globalAllDeviceKwhAvrg.allKwhDataMonthly? globalAllDeviceKwhAvrg.allKwhDataMonthly.length > 0 ?
                            <BudgetChart monthlyKwhSets={globalAllDeviceKwhAvrg.allKwhDataMonthly}/> 
                        : <Col md={3} xs={12}><BarLoader color={'#123abc'} loading={!globalAllDeviceKwhAvrg.allKwhDataMonthly.length > 0}/></Col>
                        : <Col md={3} xs={12}><BarLoader color={'#123abc'} loading={!globalAllDeviceKwhAvrg.allKwhDataMonthly}/></Col>}            
                        </CardBody>
                    </Card>
                <h5>Billing and Carbon Estimation</h5>
                <hr></hr>
                {/* {allLocationSensorsId.length > 0? */}
                <React.Fragment>
                    <Row>
                        <TariffCards withoutME={true} realKwhNormal={globalAllDeviceTarrif.normal? globalAllDeviceTarrif.normal : 0} realKwhPeak={globalAllDeviceKwhAvrg.totalAllKwHDataMonthly? globalAllDeviceKwhAvrg.totalAllKwHDataMonthly: 0}
                                    mainTextTotal={"This Month Total Est. Bill"} mainTextNormal={"This Month Normal Est. Bill"} mainTextPeak={"This Month Peak Est. Bill"}/>
                        <CarbonEmission realKwh={globalAllDeviceKwhAvrg.totalAllKwHDataMonthly} mainText={"This Month Est. Carbon Emission"}/>
                    </Row>
                    <BudgetCards realKwh={globalAllDeviceKwhAvrg.allKwhDataMonthly? globalAllDeviceKwhAvrg.allKwhDataMonthly.length > 0? globalAllDeviceKwhAvrg.totalAllKwHDataMonthly: 0 : 0} isMonthlyBudget={true}
                                    mainTextRealKwh={"Real kWh This Month"} mainTextRunRate={"Runrate This Month"} 
                                    mainTextEstimation={"This Month Estimation"} mainTextAllLocationBudget={"Budget"}/>
                    <Row>
                        <TariffCards withoutME={false} realKwhNormal={allKwhDataWhenNormalTime} realKwhPeak={allKwhDataWhenPeakTime} 
                                    mainTextTotal={"Today Total Est. Bill"} mainTextNormal={"Today Normal Est. Bill"} mainTextPeak={"Today Peak Est. Bill"}/>
                        <CarbonEmission realKwh={allKwhData} mainText={"Today Est. Carbon Emission"}/>
                    </Row> 
                </React.Fragment>
                {/* : <Col md={12} xs={12}><BarLoader color={'#123abc'} loading={allLocationSensorsId.length <= 0}/><br /></Col>
                } */}
                {/* <Row>
                    {selectedVisualMapData.length === 0? null :
                    <React.Fragment>
                        <Col md={3} xs={12}>
                        allKwhDataDeviceTotal
                            <EnermoSummaryWidgets header={allKwhData? (allKwhData/allKwhDataDeviceTotal).toFixed(2) + " kWh": "-"} mainText={"Today's kWh usage rate"} icon={'fa fa-bolt'} color={'primary'}/>
                        </Col>
                        <Col md={3} xs={12}>
                        allDevicePerLocationTotal
                            <EnermoSummaryWidgets header={kwhRate? (kwhRate/allDevicePerLocationTotal).toFixed(2) + " kWh": "-"} mainText={selectedVisualMapData.layout_name? 
                                            "Today's kWh usage rate on " + selectedVisualMapData.layout_name : null} icon={'fa fa-bolt'} color={'success'}/>
                        </Col>
                    </React.Fragment>
                    }
                    {myLocation?
                        <Col md={3} xs={12}>
                            <EnermoSummaryWidgets header={myLocation} mainText={'Location'} icon={'fa fa-map-marker'} color={'primary'}/>
                        </Col> : <Col md={3} xs={12}><br /><br /><BarLoader color={'#123abc'} loading={!myLocation}/></Col>
                    }
                    {todaysWeather.the_temp ?
                        <Col md={3} xs={12}>
                            <WeatherWidgets temperature={Math.round(todaysWeather.the_temp) + "° C"} 
                                            humidity={todaysWeather.humidity + " Humidity"} 
                                            airQuality={todaysWeather.humidity < 60 ? "Good Air Quality " : "Poor Air Quality "} 
                                            icon={BASE_LINK_WEATHER+"/static/img/weather/ico/"
                                                        +todaysWeather.weather_state_abbr+".ico"} />
                        </Col> : <Col md={3} xs={12}><br /><br /><BarLoader color={'#123abc'} loading={!todaysWeather.the_temp}/></Col>
                    }
                </Row> */}
                {/* todaysWeather.the_temp ||  */}
                {/* {selectedLiveMonitoringDevice? */}
                <Card>
                    <CardHeader>
                        <Row style={{"display": "flex",
                                    "align-items": "center",
                                    "justify-content": "center"
                                    }}> 
                            <Col md={3} xs={12} style={{"font-weight": "bold"}}>
                                Live Monitoring {selectedVisualMapData.layout_name }
                            </Col>
                            <Col md={2} xs={12} style={{"text-align":"right"}}>Change Live Device</Col>
                            <Col md={2} xs={12}>
                                    <Select options={availableDeviceToSelect} 
                                            value={selectedLiveMonitoringDevice}
                                            onChange={this.handleSelectionDeviceOnChange} 
                                            disabled={!selectedLiveMonitoringDevice} 
                                            clearable={false} 
                                            backspaceRemoves={false} 
                                            deleteRemoves={false}
                                            />
                            </Col>
                            <Col md={2} xs={12} style={{"text-align":"right"}}>Change Location</Col>
                            <Col md={3} xs={12}>
                                    <Select options={visualMapLocationData} 
                                            value={selectedVisualModelLocation}
                                            onChange={this.handleLocationOnChange} 
                                            // disabled={!selectedVisualMapDataLoaded || this.state.sensorsHistoryLoading} //|| this.state.sensorsHistoryLoading || this.state.alertsHistoryLoading|| this.state.consumptionHistoryLoading
                                            clearable={false} 
                                            backspaceRemoves={false} 
                                            deleteRemoves={false}
                                            />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {selectedVisualMapDataLoaded && selectedLiveMonitoringDevice ?
                        <PowerConsumptionChart data={liveData["device_"+selectedLiveMonitoringDevice.value]} 
                                                sensors={sensorNames["device_"+selectedLiveMonitoringDevice.value]}
                                                deviceId={selectedLiveMonitoringDevice.value} /> 
                        : null}              
                    </CardBody>
                </Card>
                
                <React.Fragment>
                    <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 5000}}/>
                    {/* <h5>Specific Location</h5>
                    <hr></hr> */}
                    {/* {selectedLocationSensorsId.length > 0? */}
                    {/* <React.Fragment>    
                        <Row>
                        <TariffCards  realKwhNormal={allKwhDataWhenNormalTimeEachLocation? allKwhDataWhenNormalTimeEachLocation*Number(moment().format('DD')): 0} realKwhPeak={allKwhDataWhenPeakTimeEachLocation? allKwhDataWhenPeakTimeEachLocation*Number(moment().format('DD')): 0} 
                                    mainTextTotal={selectedVisualMapData.layout_name + " Monthly Total Tariff"} mainTextNormal={selectedVisualMapData.layout_name + " Monthly Total Normal Tariff"} mainTextPeak={selectedVisualMapData.layout_name + " Monthly Total Peak Tariff"}/>

                            <CarbonEmission realKwh={totalAllKwHEachLocationDataMonthly} mainText={selectedVisualMapData.layout_name + " Carbon Emission Total"}/>
                        </Row>
                        <BudgetCards realKwh={allKwhDataMonthlyEachLocation? allKwhDataMonthlyEachLocation.length > 0? totalAllKwHEachLocationDataMonthly: 0 : 0} isMonthlyBudget={true}
                                        mainTextRealKwh={selectedVisualMapData.layout_name + " Real kWh This Month"} mainTextRunRate={selectedVisualMapData.layout_name + " Runrate This Month"} 
                                        mainTextEstimation={selectedVisualMapData.layout_name  + " This Month Estimation"} mainTextAllLocationBudget={selectedVisualMapData.layout_name  + " Budget"}/>
                        <Row>
                            <TariffCards  realKwhNormal={allKwhDataWhenNormalTimeEachLocation} realKwhPeak={allKwhDataWhenPeakTimeEachLocation} 
                                    mainTextTotal={selectedVisualMapData.layout_name  + " Today Tariff"} mainTextNormal={selectedVisualMapData.layout_name  + " Today Normal Tariff"} mainTextPeak={selectedVisualMapData.layout_name  + " Today Peak Tariff"}/>
                            <CarbonEmission realKwh={allKwhDataEachLocation} mainText={selectedVisualMapData.layout_name + " Carbon Emission Today"}/>
                        </Row>
                        </React.Fragment> */}
                    {/* // : <Col md={12} xs={12}><BarLoader color={'#123abc'} loading={selectedLocationSensorsId.length <= 0}/><br /></Col>
                    // } */}
                    {/* {availableDeviceToSelect? availableDeviceToSelect.length > 1?
                    <Card>
                        <CardHeader>
                            <Row style={{"display": "flex",
                                        "align-items": "center",
                                        "justify-content": "center"
                                        }}> 
                                <Col md={3} xs={6} style={{"font-weight": "bold"}}>
                                    Live Monitoring {selectedVisualMapData.layout_name }
                                </Col>
                                <Col md={2} xs={6} style={{"text-align":"right"}}>Change Live Device</Col>
                                <Col md={2} xs={6}>
                                        <Select options={availableDeviceToSelect} 
                                                value={selectedLiveMonitoringDevice2}
                                                onChange={this.handleSelectionDeviceOnChange2} 
                                                disabled={!selectedLiveMonitoringDevice2} 
                                                clearable={false} 
                                                backspaceRemoves={false} 
                                                deleteRemoves={false}
                                                />
                                </Col>
                                <Col md={2} xs={12} style={{"text-align":"right"}}></Col>
                                <Col md={3} xs={12}>
                                 
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {selectedVisualMapDataLoaded && selectedLiveMonitoringDevice2 ?
                            <PowerConsumptionChart data={liveData["device_"+selectedLiveMonitoringDevice2.value]} 
                                                   sensors={sensorNames["device_"+selectedLiveMonitoringDevice2.value]}
                                                   deviceId={selectedLiveMonitoringDevice2.value} /> 
                            : null}              
                        </CardBody>
                    </Card>  : null : null} */}
                     <Row style={{marginBottom:"20px"}}> 
                        <Col lg={6} md={12} sm={12} xs={12}  style={{marginTop:"17px"}}>
                            <Card>
                                <CardHeader>
                                    Layout Model
                                </CardHeader>
                                <CardBody style={{marginLeft:"17px"}}>
                                {selectedVisualMapDataLoaded ?
                                        <VisualModelingDrawing locationName={selectedVisualMapData.layout_name ? selectedVisualMapData.layout_name : null} 
                                                                        floorPlan={selectedVisualMapData.floor_plan_image_link} 
                                                                        sensors={selectedVisualMapData.boundaries} 
                                                                        utilites={selectedVisualMapData.utilities}
                                                                        isView={true}
                                                                        showLiveData={true}
                                                                        sensor_type={allAvailableSensors} 
                                                                        read_value_electricity={this.state.allKwhDataPerLocation}
                                                                        read_value_tem_hum={liveData}
                                                                        read_value_door={liveData}
                                                                        /> : null
                                }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}  style={{marginTop:"17px"}}>
                        <Card>
                            <CardHeader>
                                Utilities Monitored
                            </CardHeader>
                            </Card>
                            <Row>
                                {selectedVisualMapDataLoaded && selectedVisualMapData.utilities ? selectedVisualMapData.utilities.map(d => (
                                    <Col md={4} xs={12}>
                                        <UtilitieSummaryWidgets utility_name={d.utility_name} 
                                                            utility_type={d.utility_type} 
                                                            monitored_by_device = {selectedVisualMapData.boundaries.filter(s => s.id === d.id_device)[0]? 
                                                                                        selectedVisualMapData.boundaries.filter(s => s.id === d.id_device)[0].sensor_desc: "Device Not Available"} 
                                                            voltage={liveData["device_"+d.id_device] ?liveData["device_"+d.id_device].length > 0? liveData["device_"+d.id_device][liveData["device_"+d.id_device].length-1]["vavg"]? liveData["device_"+d.id_device][liveData["device_"+d.id_device].length-1]["vavg"] : 238.2 :0 : 0}
                                                            current={liveData["device_"+d.id_device] ?liveData["device_"+d.id_device].length > 0? liveData["device_"+d.id_device][liveData["device_"+d.id_device].length-1]["iavg"] :0 : 0}
                                                            read_value={liveData["device_"+d.id_device]?
                                                                            liveData["device_"+d.id_device].length > 0? 
                                                                            // liveData["device_"+d.id_device][liveData["device_"+d.id_device].length-1]["stat"] === 1 && 
                                                                            Math.abs(moment(liveData["device_"+d.id_device][liveData["device_"+d.id_device].length-1]["time"])
                                                                            .diff(moment().format("YYYY-MM-DD HH:mm:ss"), 'minutes')) <= 5  ? "Monitored" : "Unmonitored" 
                                                                            : "Unknown" : "Unknown"}
                                                            // read_value={
                                                            //     this.state.liveData.filter(item => item === "device_"+d.id_device)
                                                            // }
                                                            unit={"kWh"} 
                                                            icon={'fa fa-shower'}
                                                            color={'primary'}/>
                                    </Col>
                                )) : null}
                            </Row>
                            <Card>
                            <CardHeader>
                                Monitoring Devices
                            </CardHeader>
                            </Card>
                            <Row>
                                {selectedVisualMapDataLoaded && selectedVisualMapData.boundaries ? allAvailableSensors ?
                                    selectedVisualMapData.boundaries.map(d => (
                                    <Col md={4} xs={12}>
                                        <SensorsSummaryWidgets 
                                                            sensor_name={d.sensor_desc} 
                                                            // sensor_code={d.sensor_name} 
                                                            sensor_type={allAvailableSensors.filter(s => s.id_device === d.id) || null} 
                                                            read_value_electricity={this.state.allKwhDataPerLocation.filter(item => item.device_id === d.id)}
                                                            read_value_tem_hum={liveData["device_"+d.id]? liveData["device_"+d.id][liveData["device_"+d.id].length-1] : []}
                                                            read_value_door={liveData["device_"+d.id]? liveData["device_"+d.id][liveData["device_"+d.id].length-1] : []}
                                                            sensor_stat={liveData["device_"+d.id]?
                                                                            liveData["device_"+d.id].length > 0? 
                                                                            // liveData["device_"+d.id][liveData["device_"+d.id].length-1]["sdtc"] === 0 &&
                                                                            Math.abs(moment(liveData["device_"+d.id_device][liveData["device_"+d.id_device].length-1]["time"])
                                                                            .diff(moment().format("YYYY-MM-DD HH:mm:ss"), 'minutes')) <= 5  ? "ONLINE" : "OFFLINE" 
                                                                            : "Unknown" : "Unknown"}
                                                            // unit={ d.sensor_desc? d.sensor_desc.includes("Suhu")? "C" : "kWh" : ""} 
                                                            icon={'fa fa-microchip'}
                                                            color={'primary'}/>
                                    </Col>//SHITTY CODE EVERYWHERE
                                )) : <Col md={4} xs={12}><BarLoader color={'#123abc'} loading={!allAvailableSensors}/><br /></Col> : null}
                            </Row>
                        </Col>
                
                    </Row> 
                    <Row>     
                        <Col md={12} xs={12}>
                        <Card>
                                <CardHeader>
                                    <Row style={{"display": "flex",
                                                "align-items": "center",
                                                "justify-content": "center"
                                                }}>
                                        <Col md={12} xs={12}>
                                        {selectedVisualMapDataLoaded? "Consumption History (" + selectedVisualMapData.layout_name + ")" : "Consumption History"}
                                        </Col>
                                        <Col md={4} xs={12} style={{"text-align":"right"}}>Select Device</Col>
                                <       Col md={4} xs={12}>
                                        <Select options={availableDeviceToSelectElectricity} 
                                                value={selectedDeviceForConsumptionHistory}
                                                onChange={this.handleSelectionDeviceOnChangeForConsumptionHistory} 
                                                disabled={!selectedDeviceForConsumptionHistory || this.state.consumptionHistoryLoading} 
                                                clearable={false} 
                                                backspaceRemoves={false} 
                                                deleteRemoves={false}
                                                />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                {selectedVisualMapDataLoaded && selectedDeviceForConsumptionHistory?
                                <CardBody>
                                    {!this.state.consumptionHistoryLoading?
                                    <React.Fragment>
                                        <Row style={{marginLeft:"20px", marginBottom:"20px"}}>
                                                {this.showConsumptionDatePicker()}
                                        </Row>
                                        <Row>
                                            <PowerConsumptionBarChart data={consumptionData}/>
                                        </Row> 
                              
                                        </React.Fragment> : <Col md={12} xs={12}><BarLoader color={'#123abc'} loading={this.state.consumptionHistoryLoading}/></Col>}
                                </CardBody> : null}
                            </Card>
                        </Col>
                        <Col md={12} xs={12}>
                        <Card>
                                <CardHeader>
                                    <Row style={{"display": "flex",
                                                "align-items": "center",
                                                "justify-content": "center"
                                                }}> 
                                        <Col md={12} xs={12}>
                                        {selectedVisualMapDataLoaded? "Sensor History (" +selectedVisualMapData.layout_name + ")" : "Sensor History"}
                                        </Col>
                                        <Col md={4} xs={12} style={{"text-align":"right"}}>Select Device</Col>
                                        <Col md={4} xs={12}>
                                        <Select options={availableDeviceToSelect} 
                                                value={selectedDeviceForSensorsHistory}
                                                onChange={this.handleSelectionDeviceOnChangeForSensorsHistory} 
                                                // disabled={!selectedDeviceForSensorsHistory || this.state.sensorsHistoryLoading} 
                                                clearable={false} 
                                                backspaceRemoves={false} 
                                                deleteRemoves={false}
                                                />
                                        </Col> 
                                    </Row>
                                </CardHeader>
                                {selectedVisualMapDataLoaded && selectedDeviceForSensorsHistory?
                                <CardBody>
                                    {!this.state.sensorsHistoryLoading?
                                    <React.Fragment>
                                        <Row style={{marginLeft:"20px", marginBottom:"20px"}}>
                                                {this.showSensorsHistoryDatePicker()}
                                        </Row>
                                        <Row>
                                            <SensorsHistoryLineChart data={sensorsHistoryData} sensors={allAvailableSensors.filter(s => s.id_device === selectedDeviceForSensorsHistory.value)}/>
                                        </Row>                                   
                                    </React.Fragment> : <Col md={12} xs={12}><BarLoader color={'#123abc'} loading={this.state.sensorsHistoryLoading}/></Col>}
                                </CardBody> : null}
                            </Card> 
                        </Col>
                        <Col md={6} xs={12}>          
                            <Card>
                                <CardHeader>
                                    <Row style={{"display": "flex",
                                                "align-items": "center",
                                                "justify-content": "center"
                                                }}>
                                        <Col md={12} xs={12}>
                                        {selectedVisualMapDataLoaded? "Significant Energy User (" + selectedVisualMapData.layout_name + ")" : "Consumption History"}
                                        </Col>
                                        <br /><br /> 
                                    </Row>
                                </CardHeader>
                                {selectedVisualMapDataLoaded ?
                                <CardBody>
                                    {!this.state.utilityConsumptionLoading?
                                    <React.Fragment>
                                        <Row>
                                            <Col md={12} xs={12}>
                                            <UtilityUsageRank utilityUsage={utilityConsumption} />
                                                {/* <PowerConsumptionPieChart data={utilityConsumption} /> */}
                                            </Col>
                                        </Row>
                                    </React.Fragment> : <Col md={12} xs={12}><BarLoader color={'#123abc'} loading={this.state.utilityConsumptionLoading}/></Col>}
                                </CardBody> : null}
                            </Card>
                        </Col>
                        <Col md={6} xs={12}>
                            <Card>
                                <CardHeader>
                                    <Row style={{"display": "flex",
                                                "align-items": "center",
                                                "justify-content": "center"
                                                }}> 
                                        <Col md={4} xs={12}>
                                        {selectedVisualMapDataLoaded? "Alert History (" +selectedVisualMapData.layout_name + ")" : "Alert History"}
                                        </Col>
                                        <Col md={4} xs={12} style={{"text-align":"right"}}>Select Device</Col>
                                        <Col md={4} xs={12}>
                                        <Select options={availableDeviceToSelect} 
                                                value={selectedDeviceForAlertHistory}
                                                onChange={this.handleSelectionDeviceOnChangeForAlertHistory} 
                                                // disabled={!selectedDeviceForAlertHistory || this.state.alertsHistoryLoading} 
                                                clearable={false} 
                                                backspaceRemoves={false} 
                                                deleteRemoves={false}
                                                />
                                        </Col> 
                                    </Row>
                                </CardHeader>
                                {selectedVisualMapDataLoaded && selectedDeviceForAlertHistory?
                                <CardBody>
                                     {!this.state.alertsHistoryLoading?
                                    <React.Fragment>
                                        <Row style={{marginLeft:"20px", marginBottom:"20px"}}>
                                                {this.showAlertDatePicker()}
                                        </Row>
                                        <Row>
                                            <PowerAlertBarChart data={alertData} sensors={allAvailableSensors.filter(s => s.id_device === selectedDeviceForAlertHistory.value)} />
                                        </Row>
                                    </React.Fragment> : <Col md={12} xs={12}><BarLoader color={'#123abc'} loading={this.state.alertsHistoryLoading}/></Col>}
                                </CardBody> : null}
                            </Card>
                            </Col>
                    </Row>  
                    </React.Fragment>
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    totalSensor: state.sensorsStore.totalSensor,
    inProgressSensor: state.sensorsStore.inProgress,
    successSensor: state.sensorsStore.success,
    sensorData: state.sensorsStore.sensorData,
    
    llTotalUtility: state.utilitiesStore.llTotalUtility,
    inProgressUtilityList: state.utilitiesStore.inProgress,
    successUtilityList: state.utilitiesStore.success,
    utilitiesData: state.utilitiesStore.llUtilityData,

    liveData: state.liveDataStore.liveData,
    inProgressLiveData: state.liveDataStore.inProgress,
    successLiveData: state.liveDataStore.success,

    liveDataByIdDeviceTimeUnix: state.liveDataByIdDeviceTimeUnixStore.liveData,
    inProgressLiveDataByIdDeviveTimeUnix: state.liveDataByIdDeviceTimeUnixStore.inProgress,
    successLiveDataByIdDeviveTimeUnix: state.liveDataByIdDeviceTimeUnixStore.success,

    liveDataByIdDeviceIdSensorTimeUnix: state.liveDataByIdDeviceIdSensorTimeUnixStore.liveData,
    inProgressLiveDataByIdDeviceIdSensorTimeUnix: state.liveDataByIdDeviceIdSensorTimeUnixStore.inProgress,
    successLiveDataByIdDeviceIdSensorTimeUnix: state.liveDataByIdDeviceIdSensorTimeUnixStore.success,

    kWhAverage : state.processingLiveMonitoringKwhAverageStore.kwhAverage,
    inProgressKwhAverage : state.processingLiveMonitoringKwhAverageStore.inProgress,
    sucessKwhAverag : state.processingLiveMonitoringKwhAverageStore.success,

    sensorHistoryGrouping : state.processingLiveMonitoringSensorHistoryGroupingStore.sensorHistoryGrouping,
    inProgressSensorHistoryGrouping: state.processingLiveMonitoringSensorHistoryGroupingStore.inProgress,
    sucessSensorHistoryGrouping : state.processingLiveMonitoringSensorHistoryGroupingStore.success,

    sensorAlertrouping : state.processingLiveMonitoringSensorAlertGroupingStore.sensorAlertrouping,
    deviceActiveTime : state.processingLiveMonitoringDeviceActiveTimeStore.deviceActiveTime,
    inProgressSensorAlertrouping: state.processingLiveMonitoringDeviceActiveTimeStore.inProgress,
    sucessSensorAlertrouping : state.processingLiveMonitoringDeviceActiveTimeStore.success,

    inProgressVisualModel: state.visualModelReducerStore.inProgress,
    successVisualModel: state.visualModelReducerStore.success,
    visualModelData : state.visualModelReducerStore.visualModelData,

    inProgressVisualModelDevice: state.visualModelDeviceReducerStore.inProgress,
    successVisualModelDevice: state.visualModelDeviceReducerStore.success,
    visualModelDeviceData : state.visualModelDeviceReducerStore.visualModelDeviceData,

    inProgressVisualModelUtility: state.visualModelUtilitiesReducerStore.inProgress,
    successVisualModelUtility: state.visualModelUtilitiesReducerStore.success,
    visualModelUtilityData : state.visualModelUtilitiesReducerStore.visualModelUtilitiesData,

    inProgressDevice: state.devicesStore.inProgress,
    successDeviceFetch: state.devicesStore.success,
    devicesData : state.devicesStore.devicesData,

    successGetMonthlyKwhSets: state.monthlyKwhSetsStore.success,
    monthlyKwhSets : state.monthlyKwhSetsStore.sets,

})


export default connect(mapStateToProps, {fetchVisualModel, fetchVisualModelDevice, fetchVisualModelUtilities,
                                        fetchLiveDataByIdDeviceIdSensorAndUnixTimeService, 
                                        fetchLiveDataByIdDeviceAndUnixTimeService, fetchSensorList, 
                                        fetchLiveData, fetchKwhAverage, fetchSensorHistoryGrouping,
                                        fetchUtilities, fetchDevices, getMonthlyKwhSets, setMonthlyKwhSets,
                                        processLiveMonitoringFetchCancelation, processLiveMonitoringFetchRestart, liveMonitoringFetchRestart,
                                        fetchSensorSensorAlertGrouping, fetchDeviceActiveTime, liveMonitoringFetchCancelationService})(Dashboard);

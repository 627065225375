import {
    FETCH_GENERAL_SETTING_LIST,
    RECEIVE_GENERAL_SETTING_LIST,
    FAILED_GENERAL_SETTING_LIST,
    UPSERT_GENERAL_SETTING,

    FETCH_BUDGET_SETTING_LIST,
    RECEIVE_BUDGET_SETTING_LIST,
    FAILED_BUDGET_SETTING_LIST,
    UPSERT_BUDGET_SETTING
} from "../types/setting.types";

import {fetchGeneralSettingService, upsertGeneralSettingService,
            fetchBudgetSettingService, upsertBudgetSettingService}
from  "../../services/setting.service";

//General Settings
export const fetchGeneralSetting = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_GENERAL_SETTING_LIST,
        });
        const response = await fetchGeneralSettingService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_GENERAL_SETTING_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_GENERAL_SETTING_LIST,
        });
       return Promise.reject(error);
    }
};

export const upsertGeneralSetting = (generalSetting) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await upsertGeneralSettingService(generalSetting, auth);
      dispatch({
        type: UPSERT_GENERAL_SETTING,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
};

//Budget Settings
export const fetchBudgetSetting = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_BUDGET_SETTING_LIST,
        });
        const response = await fetchBudgetSettingService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_BUDGET_SETTING_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_BUDGET_SETTING_LIST,
        });
       return Promise.reject(error);
    }
};

export const upsertBudgetSetting = (budgetSetting) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await upsertBudgetSettingService(budgetSetting, auth);
      dispatch({
        type: UPSERT_BUDGET_SETTING,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
};
export const FETCH_LIVE_MONITORING_DATA = 'FETCH_LIVE_MONITORING_DATA'
export const RECEIVE_LIVE_MONITORING_DATA = 'RECEIVE_LIVE_MONITORING_DATA'
export const FAILED_LIVE_MONITORING_DATA = 'FAILED_LIVE_MONITORING_DATA'
export const FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX = 'FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX'
export const RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX = 'RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX'
export const FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX = 'FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_TIME_UNIX'
export const FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX = 'FETCH_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX'
export const RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX = 'RECEIVE_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX'
export const FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX = 'FAILED_LIVE_MONITORING_DATA_BY_ID_DEVICE_ID_SENSOR_TIME_UNIX'
export const FETCH_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX = 'FETCH_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX'
export const RECEIVE_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX = 'RECEIVE_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX'
export const FAILED_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX = 'FAILED_LIVE_MONITORING_DATA_BY_MULTI_ID_DEVICE_ID_SENSOR_TIME_UNIX'
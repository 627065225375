import * as API_LINKS from './api.links';
import axios from 'axios';

let controllerLiveMonitoring =null

export function fetchLiveMonitoringService(auth, idDevice){
    return  axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                signal: controllerLiveMonitoring.signal,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(idDevice),
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
        }

export function fetchLiveMonitoringByIdDeviceAndUnixTimeService(auth, idDevice, startUnixTime, endUnixTime){
    // console.log(auth)
    return  axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                signal: controllerLiveMonitoring.signal,

                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(idDevice),
                    start_date: String(startUnixTime),
                    end_date: String(endUnixTime),
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
        }

export function fetchLiveMonitoringByIdDeviceIdSensorAndUnixTimeService(auth, idDevice, idSensor, startUnixTime, endUnixTime){
    return  axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                signal: controllerLiveMonitoring.signal,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(idDevice),
                    id_sensor: String(idSensor),
                    start_date: String(startUnixTime),
                    end_date: String(endUnixTime)
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
        }

export async function fetchLiveMonitoringByMultiIdDevicesAndUnixTimeService(auth, idDevices, startUnixTime, endUnixTime){
    let fetchMultiDevices = []
    for(let id of idDevices){
        fetchMultiDevices.push(
            axios({
                method: 'post',
                url: API_LINKS.LIVE_MONITORING_FETCH_URL,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id : String(id),
                    start_date: String(startUnixTime),
                    end_date: String(endUnixTime),
                }
            })
        )
    }
    Promise.all([...fetchMultiDevices])
    .then(responses => {
        console.log(responses)
        return responses
    })
    return null
}

export function liveMonitoringFetchRestartService(){
    controllerLiveMonitoring = new AbortController();
}

export function liveMonitoringFetchCancelation(){
    controllerLiveMonitoring.abort()
}
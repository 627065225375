import {
    CREATE_SETS,
    EMPTY_SETS,
    FAILED
} from "../../types/Audit/audit-group.type";

const initialState = {
    sets: [],
    success: false,
}

export function auditGroupSetsReducer(state = initialState, action){
    const {type, payload} = action;
    switch (type) {
        case CREATE_SETS:
            return Object.assign({}, state, {sets: payload, success: true})
        case EMPTY_SETS:
            return ({sets: [], success: false})
        case FAILED:
            return ({sets: [], success: false})
        default:
            return state
    }
}

import * as API_LINKS from './api.links';
import axios from 'axios';

export function fetchUtilitiesService(auth){
    return  axios({
                method: 'post',
                url: API_LINKS.UTILITY_LIST_URL,
                headers: {
                    Authorization: auth
                },
            });
}

export function editUtilityService(editObj, auth){
    return axios({
        method: 'post',
        url: API_LINKS.UTILITY_EDIT_URL,
        headers: {
            Authorization: auth
        },
        data: {
            id: editObj.id,
            id_location: editObj.id_location,
            utility_name: editObj.utility_name,
            desc: editObj.desc,
            id_utility_type: editObj.id_utility_type,
            id_resource_usage_type: editObj.id_resource_usage_type,
            id_usage_schedule: editObj.id_usage_schedule,
            fabrication_date: editObj.fabrication_date,
            installation_date: editObj.installation_date,
            id_device: editObj.id_device,
            utility_wattage : editObj.utility_wattage
        } 
    })
}

export function deleteUtilityService(utilityId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.UTILITY_DELETE_URL,
        headers: {
            Authorization: auth
        },
        data : {id : utilityId}
    })
}
import {
    FETCH_LL_UTILITY_TYPE_LIST,
    RECEIVE_LL_UTILITY_TYPE_LIST,
    FAILED_LL_UTILITY_TYPE_LIST,
    CREATE_UTILITY_TYPE,
    EDIT_UTILITY_TYPE,
    DELETE_UTILITY_TYPE
} from "../types/utility-types.types";

const defaultLLUtilityTypeState = {
    llUtilityTypeData: [],
    llTotalUtilityType: 0,
    llUtilityTypeSelect: [],
    success: true,
    inProgress: false,
}

export function utilityTypeReducer(state = defaultLLUtilityTypeState, action){

    const { type, payload } = action

    switch (type) {
        case FETCH_LL_UTILITY_TYPE_LIST:
            return ({llUtilityTypeData: [], success: true, llTotalUtilityType: 0, llUtilityTypeSelect: [], inProgress: true})

        case RECEIVE_LL_UTILITY_TYPE_LIST:
            var _totalUtilityType = payload.result.length;
            var _selectUtilityType = []
            if(payload.result !== []) {
                var _utilityTypeData = payload.result

                _utilityTypeData.forEach((p, index) => {
                    var _util = {
                        'label': _utilityTypeData[index].utility_type_name + ' - ' + _utilityTypeData[index].utility_type_desc,
                        'value': _utilityTypeData[index].id
                    }
                    _selectUtilityType.push(_util)
                })
            }
            return Object.assign({}, state, {llUtilityTypeData: payload, success: true, llTotalUtilityType: _totalUtilityType, llUtilityTypeSelect: _selectUtilityType, inProgress: false})

        case FAILED_LL_UTILITY_TYPE_LIST:
            return ({llUtilityTypeData: [], success: false, llTotalUtilityType: 0, llUtilityTypeSelect: [], inProgress: false})

        case CREATE_UTILITY_TYPE:
            return state

        case EDIT_UTILITY_TYPE:
            return state

        case DELETE_UTILITY_TYPE:
            return state

        default:
            return state
    }
}
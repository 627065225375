import {
    FETCH_DEVICE_LIST,
    RECEIVE_DEVICE_LIST,
    FAILED_DEVICE_LIST,
    CREATE_DEVICE_TYPE,
    EDIT_DEVICE_TYPE,
    DELETE_DEVICE_TYPE,
    FETCH_DEVICE_FABRICATOR_LIST,
    RECEIVE_DEVICE_FABRICATOR_LIST,
    FAILED_DEVICE_FABRICATOR_LIST
} from "../types/devices.types";

const defaultdevicesState = {
    devicesData: [],
    totalDevices: 0,
    devicesSelect: [],
    success: true,
    inProgress: false,
}

export function devicesReducer(state = defaultdevicesState, action){

    const { type, payload } = action

    switch (type) {
        case FETCH_DEVICE_LIST:
            return ({devicesData: [], success: true, deviceTotal: 0, devicesSelect: [], inProgress: true})

        case RECEIVE_DEVICE_LIST:
            var _totalDevice = payload.result.length;
            var _selectDevices = []
            if(payload.result !== []) {
                var _devicesData = payload.result

                _devicesData.forEach((p, index) => {
                    var _util = {
                        'label': _devicesData[index].device_name,
                        'value': _devicesData[index].id
                    }
                    _selectDevices.push(_util)
                })
            }
            return Object.assign({}, state, {devicesData: payload, success: true, totalDevices: _totalDevice, devicesSelect: _selectDevices, inProgress: false})

        case FAILED_DEVICE_LIST:
            return ({devicesData: [], success: false, totalDevices: 0, devicesSelect: [], inProgress: false})

        case CREATE_DEVICE_TYPE:
            return state

        case EDIT_DEVICE_TYPE:
            return state

        case DELETE_DEVICE_TYPE:
            return state

        default:
            return state
    }
}

const defaultDeviceFabricatorState = {
    deviceFabricatorData: [],
    totalDeviceFabricator: 0,
    deviceFabricatorSelect: [],
    success: true,
    inProgress: false,
}

export function fetchDeviceFabricatorListReducer(state = defaultDeviceFabricatorState, action){
    const {type, payload} = action;
    switch (type) {
        case FETCH_DEVICE_FABRICATOR_LIST:
            return ({deviceFabricatorData: [], success: true, totalDeviceFabricator: 0, deviceFabricatorSelect: [], inProgress: true})
        case RECEIVE_DEVICE_FABRICATOR_LIST:
            var _totalDeviceFabricator = payload.result.length;
            var _selectDeviceFabricator = []
            if(payload.result !== []) {
                var _deviceFabricatorData = payload.result

                _deviceFabricatorData.forEach((p, index) => {
                    var _loc = {
                        'label': _deviceFabricatorData[index].device_fabricator_name + ' - ' + _deviceFabricatorData[index].device_fabricator_desc,
                        'value': _deviceFabricatorData[index].id
                    }
                    _selectDeviceFabricator.push(_loc)
                })
            }
            return Object.assign({}, state, {deviceFabricatorData: payload, success: true, totalDeviceFabricator: _totalDeviceFabricator, deviceFabricatorSelect: _selectDeviceFabricator, inProgress: false})
        case FAILED_DEVICE_FABRICATOR_LIST:
            return ({deviceFabricatorData: [], success: false, totalDeviceFabricator: 0, deviceFabricatorSelect: [], inProgress: false})
        default:
            return state
    }
}
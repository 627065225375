import {
    FETCH_KWH_AVERAGE,
    RECEIVE_KWH_AVERAGE,
    FAILED_KWH_AVERAGE,

    FETCH_SENSOR_HISTORY_GROUP_AVERAGE,
    RECEIVE_SENSOR_HISTORY_GROUP_AVERAGE,
    FAILED_SENSOR_HISTORY_GROUP_AVERAGE,

    FETCH_SENSOR_ALERT_GROUP_AVERAGE,
    RECEIVE_SENSOR_ALERT_GROUP_AVERAGE,
    FAILED_SENSOR_ALERT_GROUP_AVERAGE,

    FETCH_DEVICE_ACTIVE_TIME,
    RECEIVE_DEVICE_ACTIVE_TIME,
    FAILED_DEVICE_ACTIVE_TIME,

    FETCH_NORMAL_PEAK_TARIFF,
    RECEIVE_NORMAL_PEAK_TARIFF,
    FAILED_NORMAL_PEAK_TARIFF
} from "../types/processing-live-monitoring.types";

import {fetchKwhAverageService, fetchSensorHistoryGroupingService,
         fetchSensorSensorAlertGroupingService, fetchDeviceActiveTimeService,
         fetchNormalPeakTarifService,
         processLiveMonitoringFetchCancelationService, processLiveMonitoringRestartService} from  "../../services/processing-live-monitoring.service";

export const fetchKwhAverage = (idDevice, idSensor, startUnixTime, endUnixTime, meta = {page: 1, size : 1000}) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_KWH_AVERAGE,
        });
        const response = await fetchKwhAverageService(auth, idDevice, idSensor, startUnixTime, endUnixTime, meta);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_KWH_AVERAGE,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_KWH_AVERAGE,
        });
       
    }
}

export const fetchSensorHistoryGrouping = (idDevice, startUnixTime, endUnixTime, meta = {page: 1, size : 1000}) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_SENSOR_HISTORY_GROUP_AVERAGE,
        });
        const response = await fetchSensorHistoryGroupingService(auth, idDevice, startUnixTime, endUnixTime, meta);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_SENSOR_HISTORY_GROUP_AVERAGE,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_SENSOR_HISTORY_GROUP_AVERAGE,
        });
       
    }
}

export const fetchSensorSensorAlertGrouping = (idDevice, startUnixTime, endUnixTime, meta = {page: 1, size : 1000}) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_SENSOR_ALERT_GROUP_AVERAGE,
        });
        const response = await fetchSensorSensorAlertGroupingService(auth, idDevice, startUnixTime, endUnixTime, meta);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_SENSOR_ALERT_GROUP_AVERAGE,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_SENSOR_ALERT_GROUP_AVERAGE,
        });
       
    }
}

export const fetchDeviceActiveTime = (idDevice, idSensor, startUnixTime, endUnixTime, meta = {page: 1, size : 1000}) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_DEVICE_ACTIVE_TIME,
        });
        const response = await fetchDeviceActiveTimeService(auth, idDevice, idSensor, startUnixTime, endUnixTime, meta);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_DEVICE_ACTIVE_TIME,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_DEVICE_ACTIVE_TIME,
        });
       
    }
}

export const fetchNormalPeakTarif = (idSensorArr,  dateRange, tariff) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_NORMAL_PEAK_TARIFF,
        });
        const response = await fetchNormalPeakTarifService(auth,  idSensorArr,  dateRange, tariff);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_NORMAL_PEAK_TARIFF,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_NORMAL_PEAK_TARIFF,
        });
       
    }
}

export const processLiveMonitoringFetchRestart = () => async (dispatch) => {
    try {
        await processLiveMonitoringRestartService();
    } catch(error) {
       
    }
};


export const processLiveMonitoringFetchCancelation = () => async (dispatch) => {
    try {
        await processLiveMonitoringFetchCancelationService();
    } catch(error) {
       
    }
};

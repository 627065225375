import React, { Component } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from 'recharts';
import {SENSORS_COLOR_IN_GRAPH} from "../../constants";

class PowerAlertBarChart extends Component { 
    
    constructor(props){
        super(props);

        this.state = {
          sestsofData : [],
          sensorCheckBox : [],
          sensorToShow: [],
        }
    }
    
    componentWillReceiveProps(nextProps) {
      if(nextProps.data){
          this.setState({
            sestsofData : nextProps.data,
            sensorCheckBox : nextProps.sensors,
            sensorToShow: nextProps.sensors,
          })  
      }
  }

  toggleCheckedSensorLegend(sensorName, isChecked){
    let tempSensorToShow = this.state.sensorToShow
    if(isChecked){
        tempSensorToShow.push(sensorName)
    }
    if(!isChecked){
        tempSensorToShow = tempSensorToShow.filter(item => item !== sensorName)
    }
    this.setState({
        sensorToShow: tempSensorToShow
    })
  } 

    render() {
       const {sestsofData, sensorToShow, sensorCheckBox} = this.state;
       return (
        <React.Fragment>
        {sensorCheckBox &&  sestsofData.length > 0? 
            sensorCheckBox.length === 0 ? 
              null
             :
             <React.Fragment>
             </React.Fragment> : null}
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                width={500}
                height={300}
                data={sestsofData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                {
                    sensorToShow.map((s, index) => (
                      <Bar dataKey={s.sensor_type} stackId={1} fill={SENSORS_COLOR_IN_GRAPH[index]} />                   
                    )) 
                }
              </BarChart>
            </ResponsiveContainer>
          </React.Fragment>
        );
      }
}

export default PowerAlertBarChart

import * as API_LINKS from './api.links';
import axios from 'axios';


//General Setting
export function fetchGeneralSettingService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.GENERAL_SETTING_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function upsertGeneralSettingService(generalSetting, auth){
    return axios({
        method: 'post',
        url: API_LINKS.GENERAL_SETTING_UPSERT_URL,
        headers: {
            Authorization: auth
        },
        data: generalSetting 
    })
}

//Budget Setting
export function fetchBudgetSettingService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.BUDGET_SETTING_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function upsertBudgetSettingService(budgetSetting, auth){
    return axios({
        method: 'post',
        url: API_LINKS.BUDGET_SETTING_UPSERT_URL,
        headers: {
            Authorization: auth
        },
        data: budgetSetting 
    })
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody,  Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';
import { BarLoader } from 'react-spinners'
import {SENSOR_TYPES_BY_CATEGORY} from '../../../constants/index'

const propTypes = {
  sensor_name: PropTypes.string,
  sensor_type: PropTypes.string,
  read_value : PropTypes.number,
  unit : PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  footer: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  sensor_name: 'Sensor Name',
  sensor_type: 'Sensor Type',
  read_value: '0',
  Unit: '',
  icon: 'fa fa-bolt',
  color: 'primary',
  variant: '0',
  link: '#',
};

class SensorSummaryWidgets extends Component {
  render() {
    const {sensor_type } = this.props;
    return (
      <React.Fragment>
        {sensor_type? this.sensorCardFactory(sensor_type) 
          :  <Col md={4} xs={12}><BarLoader color={'#123abc'} loading={!sensor_type}/><br /></Col>}
      </React.Fragment>
    );
  }

  sensorCardFactory = (sensor_type) => {
    let sensor_type_arr = []
    sensor_type.map((t)=>(sensor_type_arr.push(t.sensor_type)))
    let isSensorElectricityCategory = SENSOR_TYPES_BY_CATEGORY['electricity'].some(r=> sensor_type_arr.indexOf(r) >= 0)
    let isSensorTempHumCategory = SENSOR_TYPES_BY_CATEGORY['temperatureHumidity'].some(r=> sensor_type_arr.indexOf(r) >= 0)
    let isSensorDoor= SENSOR_TYPES_BY_CATEGORY['door'].some(r=> sensor_type_arr.indexOf(r) >= 0)
    if(isSensorElectricityCategory)
      return this.electricitySensor()
    if(isSensorTempHumCategory)
      return this.tempHumSensor()
    if(isSensorDoor)
      return this.doorSensor()
  }
  
  electricitySensor = () => {
    const { className, cssModule, sensor_name, sensor_code, sensor_type, read_value_electricity, unit, icon, color, footer, link, children, variant, sensor_stat, ...attributes } = this.props;
  
    const padding = (variant === '0' ? { card: 'p-3', icon: 'p-3', lead: 'mt-2' } : (variant === '1' ? {
      card: 'p-0', icon: 'p-4', lead: 'pt-3',
    } : { card: 'p-0', icon: 'p-4 px-5', lead: 'pt-3' }));
  
    const card = { style: 'clearfix', color: color, icon: icon, classes: '' };
    card.classes = mapToCssModules(classNames(className, card.style, padding.card), cssModule);
  
    const lead = { style: 'h5 mb-0', color: color, classes: '' };
    lead.classes = classNames(lead.style, 'text-' + card.color, padding.lead, "font-weight-bold");
  
    const blockIcon = function (icon) {
      const classes = classNames(icon, padding.icon, 'font-2xl mr-3 float-right');
      return (<i className={classes}></i>);
    };
    return(     
      <Card >
      <CardBody className={card.classes} {...attributes}>
        <Row> 
          <Col md={12} xs={12} style={{backgroundColor: '#e67e22'}}>{blockIcon(card.icon)} </Col>
          <Col md={12} xs={12} className={lead.classes}>{sensor_name}</Col>
          <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'grey'}}>{sensor_code}</Col>
          <Col md={12} xs={12} className="text-muted text-uppercase font-weight-bold font-xs">{
               sensor_type? sensor_type.map((t, index)=> (
                (index < sensor_type.length-1) ? t.sensor_type + ", " : t.sensor_type
              )) : ""
          }</Col>
          <Col md={12} xs={12} className="text-uppercase font-weight-bold">{read_value_electricity[0]? Number(read_value_electricity[0].value).toFixed(2) : "-" } kWh</Col>
          {sensor_stat === "ONLINE"?
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'green'}}>{sensor_stat}</Col>
              :
              sensor_stat === "OFFLINE"?
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>{sensor_stat}</Col>
              :
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>
                            <BarLoader  width={10} color={'#123abc'} loading={true}/>
              </Col>
          }
        </Row>
      </CardBody>
    </Card>)
  }
  
  tempHumSensor = () => {
    const { className, cssModule, sensor_name, sensor_code, sensor_type, read_value_tem_hum, unit, icon, color, footer, link, children, variant, sensor_stat, ...attributes } = this.props;
  
    const padding = (variant === '0' ? { card: 'p-3', icon: 'p-3', lead: 'mt-2' } : (variant === '1' ? {
      card: 'p-0', icon: 'p-4', lead: 'pt-3',
    } : { card: 'p-0', icon: 'p-4 px-5', lead: 'pt-3' }));
  
    const card = { style: 'clearfix', color: color, icon: icon, classes: '' };
    card.classes = mapToCssModules(classNames(className, card.style, padding.card), cssModule);
  
    const lead = { style: 'h5 mb-0', color: color, classes: '' };
    lead.classes = classNames(lead.style, 'text-' + card.color, padding.lead, "font-weight-bold");
  
    const blockIcon = function (icon) {
      const classes = classNames(icon, padding.icon, 'font-2xl mr-3 float-right');
      return (<i className={classes}></i>);
    };
  
    return(     
      <Card >
      <CardBody className={card.classes} {...attributes}>
        <Row> 
          <Col md={12} xs={12} style={{backgroundColor: '#e67e22'}}>{blockIcon(card.icon)} </Col>
          <Col md={12} xs={12} className={lead.classes}>{sensor_name}</Col>
          <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'grey'}}>{sensor_code}</Col>
          <Col md={12} xs={12} className="text-muted text-uppercase font-weight-bold font-xs">{
              sensor_type? sensor_type.map((t, index)=> (
                (sensor_type ? index < sensor_type.length-1? true: false : false) ? t.sensor_type + ", " : t.sensor_type
              )) : ""
          }</Col>
          {read_value_tem_hum?
          read_value_tem_hum.length === 0 ?<Col md={12} xs={12} className="" style={{color:'grey'}}>loading value...</Col>: null
          :<Col md={12} xs={12} className="" style={{color:'grey'}}>loading value...</Col>}
          
          
          {read_value_tem_hum?
          Object.keys(read_value_tem_hum).map(key=> {
            if(String(key).includes("tem"))
              return  <Col md={12} xs={12} className="text-uppercase font-weight-bold">{key} : {read_value_tem_hum[key]? Number(read_value_tem_hum[key]).toFixed(2) : "-" } C</Col>
            if(String(key).includes("hum"))
              return <Col md={12} xs={12} className="text-uppercase font-weight-bold">{key} : {read_value_tem_hum[key]? Number(read_value_tem_hum[key]).toFixed(2) : "-" } RH</Col>
            return null
         }) : null}
          {sensor_stat === "ONLINE"?
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'green'}}>{sensor_stat}</Col>
              :
              sensor_stat === "OFFLINE"?
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>{sensor_stat}</Col>
              :
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>
                            <BarLoader  width={10} color={'#123abc'} loading={true}/>
              </Col>
          }
        </Row>
      </CardBody>
    </Card>)
  }

  doorSensor = () => {
    const { className, cssModule, sensor_name, sensor_code, sensor_type, read_value_door, unit, icon, color, footer, link, children, variant, sensor_stat, ...attributes } = this.props;

    const padding = (variant === '0' ? { card: 'p-3', icon: 'p-3', lead: 'mt-2' } : (variant === '1' ? {
      card: 'p-0', icon: 'p-4', lead: 'pt-3',
    } : { card: 'p-0', icon: 'p-4 px-5', lead: 'pt-3' }));
  
    const card = { style: 'clearfix', color: color, icon: icon, classes: '' };
    card.classes = mapToCssModules(classNames(className, card.style, padding.card), cssModule);
  
    const lead = { style: 'h5 mb-0', color: color, classes: '' };
    lead.classes = classNames(lead.style, 'text-' + card.color, padding.lead, "font-weight-bold");
  
    const blockIcon = function (icon) {
      const classes = classNames(icon, padding.icon, 'font-2xl mr-3 float-right');
      return (<i className={classes}></i>);
    };
  
    return(     
      <Card >
      <CardBody className={card.classes} {...attributes}>
        <Row> 
          <Col md={12} xs={12} style={{backgroundColor: '#e67e22'}}>{blockIcon(card.icon)} </Col>
          <Col md={12} xs={12} className={lead.classes}>{sensor_name}</Col>
          <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'grey'}}>{sensor_code}</Col>
          <Col md={12} xs={12} className="text-muted text-uppercase font-weight-bold font-xs">{
               sensor_type? sensor_type.map((t, index)=> (
                (index < sensor_type.length-1) ? t.sensor_type + ", " : t.sensor_type
              )) : ""
          }</Col>
          {!Array.isArray(read_value_door)?
            <>
          <Col md={12} xs={12} className="text-uppercase font-weight-bold">{read_value_door? Number(read_value_door.door) === 1? 
          "Currently Open" : "Closed" : "..." }</Col>
           <Col md={12} xs={12} className="text-uppercase font-weight-bold">{read_value_door? Number(read_value_door.door) === 1? 
           "" : read_value_door.last_open? "Last Open " + read_value_door.last_open : "-"  : "-" }</Col>
            </> :  <Col md={12} xs={12} className="text-uppercase font-weight-bold">...</Col>
          }
          {/* {sensor_stat === "ONLINE"?
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'green'}}>{sensor_stat}</Col>
              :
              sensor_stat === "OFFLINE"?
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>{sensor_stat}</Col>
              :
              <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>
                            <BarLoader  width={10} color={'#123abc'} loading={true}/>
              </Col>
          } */}
        </Row>
      </CardBody>
    </Card>)
  }
}

SensorSummaryWidgets.propTypes = propTypes;
SensorSummaryWidgets.defaultProps = defaultProps;

export default SensorSummaryWidgets;
import * as API_LINKS from './api.links';
import axios from 'axios';

export function fetchDevicesService(auth){
    return  axios({
                method: 'post',
                url: API_LINKS.DEVICE_LIST_URL,
                headers: {
                    Authorization: auth
                },
            });
}

export function createDeviceService(newDevice, auth){
    console.log(newDevice)
    return axios({
        method: 'post',
        url: API_LINKS.DEVICE_ADD_URL,
        headers: {
            Authorization: auth
        },
        data: newDevice
    })
}

//Installation

export function fetchDeviceInstallationListService(auth, idDevice){
    return  axios({
        method: 'post',
        url: API_LINKS.DEVICE_INSTALLATION_LIST_URL,
        headers: {
            Authorization: auth
        },
        data: {
            id_device: idDevice
        }
    });
}

export function fetchDeviceInstallationAllListService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.DEVICE_INSTALLATION_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
}

export function editDeviceService(device, auth){
    return axios({
        method: 'post',
        url: API_LINKS.DEVICE_EDIT_URL,
        headers: {
            Authorization: auth
        },
        data: device
    })
}

export function deleteDeviceService(deviceId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.DEVICE_DELETE_URL,
        headers: {
            Authorization: auth
        },
        data : {id: deviceId}
    })
}

//Fabricator
export function fetchDeviceFabricationListService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.DEVICE_FABRICATOR_LIST_URL,
        headers: {
            Authorization: auth
        },
    });
  }
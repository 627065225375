import Cookies from 'js-cookie';

import {
    FETCH_LOGIN ,
    RECEIVE_LOGIN,
    FAILED_LOGIN ,
    FETCH_LOGOUT ,
    RECEIVE_LOGOUT,
    FETCH_CHECK_AUTH,
    RECEIVE_CHECK_AUTH,
    GET_CURRENT_USER_PROFILE,
    CHANGE_PASSWORD,
    RECEIVE_CHANGE_PASSWORD_RESULT
} from "../types/auth.type";

import {login, changePasswordService} from "../../services/auth.service";

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !getAccessToken();
export const fetchLogin = (userEmail, userPassword) => async (dispatch) => {
    try {
      const response = await login(userEmail, userPassword);
      dispatch({
        type: FETCH_LOGIN,
      });
      
      if(response.status === 200 && (response.data.status === "success")){
            dispatch({
                type: RECEIVE_LOGIN,
                payload: response.data
            });
            Cookies.set('userSession', response.data.result.name);
            Cookies.set('Automalt', window.btoa(JSON.stringify(response.data.result)));
            localStorage.userSession = response.data.result.name
            localStorage.Automalt = window.btoa(JSON.stringify(response.data.result));
            localStorage.AutomaLvl = response.data.result.id_roles;
            localStorage.AutomaCmp = response.data.result.company;
            localStorage.AutomaEml = response.data.result.email
            sessionStorage.rolesName = response.data.result.roles;
      }
    } catch(error) {
        dispatch({
            type: FAILED_LOGIN,
        });
       return Promise.reject(error);   
    }
};


export const clearAuth = () => async (dispatch) => {

    try {

        await localStorage.clear();

        dispatch({
            type: FETCH_LOGOUT,
        })

        await window.location.reload();

        dispatch({
            type: RECEIVE_LOGOUT
        })

    } catch(error)  {

        return Promise.reject(error);

    }

}

export const checkAuth = () => async (dispatch) => {

    
    try {

        dispatch({
            type: FETCH_CHECK_AUTH
        });

        dispatch({
            type: RECEIVE_CHECK_AUTH
        });

    } catch(error)  {

        return Promise.reject(error);

    }

}

export const getCurrentUser = () => async (dispatch) => {
    dispatch({
        type: GET_CURRENT_USER_PROFILE
    });
}

export const changePassword = (oldPassword, newPassword, confirmedNewPassword) => async (dispatch) => {
    try {
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;

      const response = await changePasswordService(auth, localStorage.AutomaEml, oldPassword, newPassword, confirmedNewPassword);
      dispatch({
        type: CHANGE_PASSWORD,
      });
      
      if(response.status === 200 && (response.data.status === "success")){
            dispatch({
                type: RECEIVE_CHANGE_PASSWORD_RESULT,
                payload: response.data
            });
      }
      return Promise.resolve(response.status);
    } catch(error) {
       return Promise.reject(error);   
    }
};
import React, { Component } from 'react';
import {
    Col,
} from 'reactstrap'
import SummaryWidgets from '../../Widgets/Summary-Widgets';
import {connect} from 'react-redux'
import {fetchBudgetSetting} from '../../../../redux/actions/setting.act'
class TariffCards extends Component {

    constructor(props) {
        super(props);
        this.state ={
            normalTariff: 0,
            peakTariff: 0,
            realKwhNormal : 0,
            realKwhPeak : 0
        }
    }

    componentDidMount(){
        this.props.fetchBudgetSetting()
    }

    componentDidUpdate(prevProps){
        const {budgetSettingData, realKwhNormal, realKwhPeak} = this.props
        if(prevProps.budgetSettingData !== budgetSettingData){
            if(budgetSettingData.result) {
                let getNormalTarrif = budgetSettingData.result.filter(r => 
                    r.id_field_budget === 10001
                )[0]
                let getPeakTarrif = budgetSettingData.result.filter(r => 
                    r.id_field_budget === 10002
                )[0]
                let normalTariffTemp =  getNormalTarrif? getNormalTarrif.budget_limit : 0
                let peakTariffTemp = getPeakTarrif? getPeakTarrif.budget_limit : 0
                this.setState({
                    normalTariff :normalTariffTemp,
                    peakTariff: peakTariffTemp,
                })
            } 
        }

        if(prevProps.realKwhNormal !== realKwhNormal){
            if(this.props.withoutME){
                this.setState({
                    realKwhNormal: realKwhNormal? realKwhNormal: 0,
                })
            }
            this.setState({
                realKwhNormal: realKwhNormal? realKwhNormal-(realKwhNormal*(7.997/100)): 0,
            })
        }

        if(prevProps.realKwhPeak !== realKwhPeak){
            if(this.props.withoutME){
                setTimeout(() => {
                    this.setState({
                        realKwhPeak: realKwhPeak? realKwhPeak: 0,
                    })
                },5000)
             
            }
            this.setState({
                realKwhPeak: realKwhPeak? realKwhPeak-(realKwhPeak*(7.997/100)): 0,
            })
        }

    }

    calculateTodaysNormalTariff = () => {
        const {normalTariff, realKwhNormal} = this.state
        return normalTariff * realKwhNormal
    }

    calculateTodaysPeakTariff = () => {
        const {peakTariff, realKwhPeak, realKwhNormal} = this.state
        if(this.props.withoutME){
            return (realKwhPeak - realKwhNormal) * peakTariff
        }
        return peakTariff * realKwhPeak
    }

    calculateTotalTodaysTariff = () => {
        return this.calculateTodaysNormalTariff() + this.calculateTodaysPeakTariff()
    }

    render(){
        const {budgetSettingData} = this.props
        const {mainTextNormal, mainTextPeak, mainTextTotal} = this.props
        return(
            <React.Fragment>
                {budgetSettingData.result ? 
                    <React.Fragment>
                            <Col md={3} xs={12}>
                                <SummaryWidgets header={'Rp ' + parseFloat(this.calculateTodaysNormalTariff().toFixed()).toLocaleString() + ' (' + this.state.realKwhNormal.toFixed(2) + ' kWh)'} mainText={mainTextNormal} icon={'fa fa-money'} color={'primary'}/>
                            </Col>
                            <Col md={3} xs={12}>
                                <SummaryWidgets header={'Rp ' + parseFloat(this.calculateTodaysPeakTariff().toFixed()).toLocaleString() + ' (' + (this.props.withoutME? (this.state.realKwhPeak - this.state.realKwhNormal).toFixed(2) : this.state.realKwhPeak.toFixed(2)) + ' kWh)'} mainText={mainTextPeak} icon={'fa fa-money'} color={'danger'}/>
                            </Col>
                            <Col md={3} xs={12}>
                                <SummaryWidgets header={'Rp ' + parseFloat(this.calculateTotalTodaysTariff().toFixed()).toLocaleString()} mainText={mainTextTotal} icon={'fa fa-money'} color={'success'}/> 
                            </Col>
                    </React.Fragment>
                        :
                    null
                }
            </React.Fragment>
        )
    }

} 

function mapStateToProps(state) {
    return {
        budgetSettingData :  state.budgetSettingStore.budgetSettingData,
        inProgressBudgetSetting : state.budgetSettingStore.inProgress,
        successBudgetSetting : state.budgetSettingStore.success
    }
}

export default connect(mapStateToProps, {fetchBudgetSetting})(TariffCards);
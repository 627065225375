import React, { Component } from 'react';
import {
    Col,
} from 'reactstrap'
import SummaryWidgets from '../../Widgets/Summary-Widgets';
import {connect} from 'react-redux'
import { BarLoader } from 'react-spinners'
import {fetchBudgetSetting} from '../../../../redux/actions/setting.act'
class CarbonEmission extends Component {

    constructor(props) {
        super(props);
        this.state ={
            carbonEmission: 0,
            realKwh : 0
        }
    }

    componentDidMount(){
        this.props.fetchBudgetSetting()
                  .then(() => {
                        const {budgetSettingData} = this.props
                        if(budgetSettingData.result) {
                            let getCarbonEmission = budgetSettingData.result.filter(r => 
                                r.id_field_budget === 10003
                            )[0]
                            this.setState({
                                carbonEmission : getCarbonEmission? getCarbonEmission.budget_limit : 0,
                            })
                        } 
                  })
    }

    //Change this deprecated method plzzz dont be lazyyy!!!!
    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.realKwh)  
            this.setState({
                realKwh: nextProps.realKwh
            })
    }

    calculateCarbonEmissionPerkWh = () => {
        const {carbonEmission, realKwh} = this.state
        return carbonEmission * realKwh
    }

    render(){
        const {mainText, budgetSettingData} = this.props
        return(
            <React.Fragment>
                {budgetSettingData.result ? 
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={parseFloat(this.calculateCarbonEmissionPerkWh().toFixed(2)).toLocaleString() + ' kg'} mainText={mainText} icon={'fa fa-cloud-upload'} color={'info'}/>
                    </Col>
                     :
                    <div style={{'padding-left' : '45px'}}><br /><BarLoader color={'#123abc'} 
                        loading={!budgetSettingData.result}/><br /><br /></div> 
                }
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        budgetSettingData :  state.budgetSettingStore.budgetSettingData,
        inProgressBudgetSetting : state.budgetSettingStore.inProgress,
        successBudgetSetting : state.budgetSettingStore.success
    }
}

export default connect(mapStateToProps, {fetchBudgetSetting})(CarbonEmission);

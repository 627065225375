import {
    FETCH_VISUAL_MODEL_LIST,
    RECEIVE_VISUAL_MODEL_LIST,
    FAILED_VISUAL_MODEL_LIST,
    CREATE_VISUAL_MODEL,
    EDIT_VISUAL_MODEL,
    DELETE_VISUAL_MODEL,
    
    FETCH_VISUAL_MODEL_DEVICE_LIST,
    RECEIVE_VISUAL_MODEL_DEVICE_LIST,
    FAILED_VISUAL_MODEL_DEVICE_LIST,
    CREATE_VISUAL_MODEL_DEVICE,
    EDIT_VISUAL_MODEL_DEVICE,
    DELETE_VISUAL_MODEL_DEVICE,

    FETCH_VISUAL_MODEL_SENSOR_LIST,
    RECEIVE_VISUAL_MODEL_SENSOR_LIST,
    FAILED_VISUAL_MODEL_SENSOR_LIST,
    CREATE_VISUAL_MODEL_SENSOR,
    EDIT_VISUAL_MODEL_SENSOR,
    DELETE_VISUAL_MODEL_SENSOR,
    
    FETCH_VISUAL_MODEL_UTILITIES_LIST,
    RECEIVE_VISUAL_MODEL_UTILITIES_LIST,
    FAILED_VISUAL_MODEL_UTILITIES_LIST,
    CREATE_VISUAL_MODEL_UTILITIES,
    EDIT_VISUAL_MODEL_UTILITIES,
    DELETE_VISUAL_MODEL_UTILITIES

} from "../types/visual-modeling.types";

import {fetchVisualModelService, createVisualModelService, editVisualModelService,
        fetchVisualModelDeviceService, createVisualModelDeviceService, editVisualModelDeviceService,
        fetchVisualModelSensorService, createVisualModelSensorService, editVisualModelSensorService,
        fetchVisualModelUtilitiesService, createVisualModelUtilitiesService, editVisualModelUtilitiesService,
        deleteVisualModelService, deleteVisualModelDeviceService, deleteVisualModelSensorService, deleteVisualModelUtilitiesService}
from  "../../services/visual-modeling.service";

//Visual Model
export const fetchVisualModel = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_VISUAL_MODEL_LIST,
        });
        const response = await fetchVisualModelService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_VISUAL_MODEL_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_VISUAL_MODEL_LIST,
        });
       return Promise.reject(error);
    }
};

export const createVisualModel = (newVisualModel) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await createVisualModelService(newVisualModel, auth);
      dispatch({
        type: CREATE_VISUAL_MODEL,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
};

export const editVisualModel = device => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await editVisualModelService(device, auth);
      dispatch({
        type: EDIT_VISUAL_MODEL,
        payload: device,
      });

      return Promise.resolve(response.status);

    } catch (err) {
      return Promise.reject(err);
    }
};

export const deleteVisualModel = visualModelId => async (dispatch) => {
  try {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    const response = await deleteVisualModelService(visualModelId, auth);
    dispatch({
      type: DELETE_VISUAL_MODEL,
      payload: { visualModelId },
    });

    return Promise.resolve(response.status);
  } catch (err) {
      return Promise.reject(err);
  }
};

//Visual Model Device
export const fetchVisualModelDevice = () => async (dispatch) => {
  try {
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;
      dispatch({
          type: FETCH_VISUAL_MODEL_DEVICE_LIST,
      });
      const response = await fetchVisualModelDeviceService(auth);
      if(response.status === 200){

              dispatch({
                  type: RECEIVE_VISUAL_MODEL_DEVICE_LIST,
                  payload: response.data
              });

      }
  } catch(error) {
      dispatch({
          type: FAILED_VISUAL_MODEL_DEVICE_LIST,
      });
     return Promise.reject(error);
  }
};

export const createVisualModelDevice = (newVisualModelDevice) => async (dispatch) => {
  try {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    const response = await createVisualModelDeviceService(newVisualModelDevice, auth);
    dispatch({
      type: CREATE_VISUAL_MODEL_DEVICE,
      payload: response.data,
    });
    return Promise.resolve(response.status);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const editVisualModelDevice = device => async (dispatch) => {
  try {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    const response = await editVisualModelDeviceService(device, auth);
    dispatch({
      type: EDIT_VISUAL_MODEL_DEVICE,
      payload: device,
    });

    return Promise.resolve(response.status);

  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteVisualModelDevice = visualModelDeviceId => async (dispatch) => {
  try {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    const response = await deleteVisualModelDeviceService(visualModelDeviceId, auth);
    dispatch({
      type: DELETE_VISUAL_MODEL_DEVICE,
      payload: { visualModelDeviceId },
    });

    return Promise.resolve(response);
  } catch (err) {
      return Promise.reject(err);
  }
};

//Visual Model Sensor
export const fetchVisualModelSensor = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_VISUAL_MODEL_SENSOR_LIST,
        });
        const response = await fetchVisualModelSensorService(auth);
        if(response.status === 200){

                dispatch({
                    type: RECEIVE_VISUAL_MODEL_SENSOR_LIST,
                    payload: response.data
                });

        }
    } catch(error) {
        dispatch({
            type: FAILED_VISUAL_MODEL_SENSOR_LIST,
        });
       return Promise.reject(error);
    }
};

export const createVisualModelSensor = (newVisualModelSensor) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await createVisualModelSensorService(newVisualModelSensor, auth);
      dispatch({
        type: CREATE_VISUAL_MODEL_SENSOR,
        payload: response.data,
      });
      return Promise.resolve(response.status);
    } catch (err) {
      return Promise.reject(err);
    }
};

export const editVisualModelSensor = device => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await editVisualModelSensorService(device, auth);
      dispatch({
        type: EDIT_VISUAL_MODEL_SENSOR,
        payload: device,
      });

      return Promise.resolve(response.status);

    } catch (err) {
      return Promise.reject(err);
    }
};

export const deleteVisualModelSensor = visualModelSensorId => async (dispatch) => {
  try {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    const response = await deleteVisualModelSensorService(visualModelSensorId, auth);
    dispatch({
      type: DELETE_VISUAL_MODEL_SENSOR,
      payload: { visualModelSensorId },
    });

    return Promise.resolve(response);
  } catch (err) {
      return Promise.reject(err);
  }
};

//Visual ModelUtilities Utilities
export const fetchVisualModelUtilities = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_VISUAL_MODEL_UTILITIES_LIST,
        });
        const response = await fetchVisualModelUtilitiesService(auth);
        if(response.status === 200){

                dispatch({
                    type: RECEIVE_VISUAL_MODEL_UTILITIES_LIST,
                    payload: response.data
                });

        }
    } catch(error) {
        dispatch({
            type: FAILED_VISUAL_MODEL_UTILITIES_LIST,
        });
       return Promise.reject(error);
    }
};

export const createVisualModelUtilities = (newVisualModelUtilities) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await createVisualModelUtilitiesService(newVisualModelUtilities, auth);
      dispatch({
        type: CREATE_VISUAL_MODEL_UTILITIES,
        payload: response.data,
      });
      return Promise.resolve(response.status);
    } catch (err) {
      return Promise.reject(err);
    }
};

export const editVisualModelUtilities = device => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await editVisualModelUtilitiesService(device, auth);
      dispatch({
        type: EDIT_VISUAL_MODEL_UTILITIES,
        payload: device,
      });

      return Promise.resolve(response.status);

    } catch (err) {
      return Promise.reject(err);
    }
};

export const deleteVisualModelUtilities = visualModelUtilityId => async (dispatch) => {
  try {
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    const response = await deleteVisualModelUtilitiesService(visualModelUtilityId, auth);
    dispatch({
      type: DELETE_VISUAL_MODEL_UTILITIES,
      payload: { visualModelUtilityId },
    });

    return Promise.resolve(response);
  } catch (err) {
      return Promise.reject(err);
  }
};

import {
    CREATE_SETS,
    RECEIVE_SETS,
    EMPTY_SETS,
    FAILED
} from "../../types/Dashboard/monthly-kwh-sets.type";

export const setMonthlyKwhSets = (sets) => async (dispatch) => {
    try {
        if(!Array.isArray(sets))
        throw Object.assign(
            new Error("Not an array!"),
            { code: 400 }
         );
        if(sets.length === 0){
            dispatch({
                type: EMPTY_SETS,
                payload: []
            });
            return Promise.resolve([])
        }
        dispatch({
            type: CREATE_SETS,
            payload: sets
        });
    } catch(error) {
        dispatch({
            type: FAILED,
        });
       return Promise.reject(error);
    }
};

export const getMonthlyKwhSets = () => async (dispatch) => {
    try {
        dispatch({
            type: RECEIVE_SETS,
        });
    } catch(error) {
        dispatch({
            type: FAILED,
        });
       return Promise.reject(error);
    }
};

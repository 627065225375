import React, { Component } from 'react';
import {
    Col,
    Row
} from 'reactstrap'
import {connect} from 'react-redux'
import { BarLoader } from 'react-spinners'
import SummaryWidgets from '../../Widgets/Summary-Widgets';
import {fetchBudgetSetting} from '../../../../redux/actions/setting.act'
import {countRunrateOnBudget, countPercentage, countEstimation} from '../../../../helpers'
import moment from 'moment'
class BudgetCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            budgetKwh : 0,
            dailyBudget: 0,
            realKwh : 0
        }
    }

    componentDidMount(){
        this.props.fetchBudgetSetting()
                  .then(() => {
                        const {budgetSettingData} = this.props
                        if(budgetSettingData.result) {
                            let getThisMonthBudgetLimit = budgetSettingData.result.filter(r => 
                                r.id_field_budget === Number(moment().format("MYYYY"))
                            )[0]
                            let getDailyBudget = budgetSettingData.result.filter(r => 
                                r.id_field_budget === 10004
                            )[0]
                            this.setState({
                                budgetKwh : getThisMonthBudgetLimit? getThisMonthBudgetLimit.budget_limit : 0,
                                dailyBudget: getDailyBudget? getDailyBudget.budget_limit : 0,
                            })
                        } 
                  })
    }
    
    estimationColorCode = () => {
        const {isMonthlyBudget, realKwh} = this.props
        const {budgetKwh, dailyBudget} = this.state
        let budget = isMonthlyBudget? budgetKwh: dailyBudget
        if(countEstimation(realKwh) > countRunrateOnBudget(budget))
            return 'danger'
        return 'success'
    }

    render(){
        const {isMonthlyBudget, realKwh, budgetSettingData} = this.props
        const {budgetKwh, dailyBudget} = this.state
        const {mainTextRealKwh, mainTextRunRate, mainTextEstimation, mainTextAllLocationBudget} = this.props
        return(
            <React.Fragment>
                {budgetSettingData.result ? 
                    <Row>
                        <Col md={3} xs={12}>
                            <SummaryWidgets header={Number(realKwh).toFixed(2).toLocaleString() + ' kWh (' + countPercentage(realKwh,  isMonthlyBudget? budgetKwh: dailyBudget).toLocaleString() + '%)'} mainText={mainTextRealKwh} icon={'fa fa-usd'} color={'primary'}/> 
                        </Col>
                        <Col md={3} xs={12}>
                            <SummaryWidgets header={Number( isMonthlyBudget? budgetKwh: dailyBudget).toFixed(2).toLocaleString() + ' kWh'} mainText={mainTextAllLocationBudget} icon={'fa fa-usd'} color={'primary'}/>
                        </Col>
                        <Col md={3} xs={12}>
                            <SummaryWidgets header={Number(countRunrateOnBudget( isMonthlyBudget? budgetKwh: dailyBudget)).toFixed(2).toLocaleString() + ' kWh'} mainText={mainTextRunRate} icon={'fa fa-usd'} color={'success'}/>
                        </Col>
                        <Col md={3} xs={12}>
                            <SummaryWidgets header={Number(countEstimation(realKwh)).toFixed(2).toLocaleString() + ' kWh'} mainText={mainTextEstimation} icon={'fa fa-calculator'} color={this.estimationColorCode()}/>
                        </Col>
                    </Row>
                   :
                   <div style={{'padding-left' : '32px'}}><BarLoader color={'#123abc'} 
                        loading={!budgetSettingData.result}/>
                        <br /> </div> 
               }
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        budgetSettingData :  state.budgetSettingStore.budgetSettingData,
        inProgressBudgetSetting : state.budgetSettingStore.inProgress,
        successBudgetSetting : state.budgetSettingStore.success
    }
}

export default connect(mapStateToProps, {fetchBudgetSetting})(BudgetCards);
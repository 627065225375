import React, { Component } from 'react';
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from 'recharts';
import {SENSORS_COLOR_IN_GRAPH} from "../../constants";

class PowerConsumptionBarChart extends Component { 
    
    constructor(props){
        super(props);

        this.state = {
          sestsofData : []
        }
    }
    
    componentWillReceiveProps(nextProps) {
      if(nextProps.data){
          nextProps.data.map(data => (
            data["kwhAvg"] = Number(data["kwhAvg"]).toFixed(2)
          ))
          this.setState({
            sestsofData : nextProps.data
          })  
      }
  }

    render() {
       const {sestsofData} = this.state;
       return (
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              width={500}
              height={300}
              data={sestsofData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
            <Bar dataKey="kwhAvg" stackId="1"  fill={SENSORS_COLOR_IN_GRAPH[1]} />
            <Line type="monotone" dataKey="kwhAvg" strokeWidth={3} stroke={SENSORS_COLOR_IN_GRAPH[0]} />
            </ComposedChart>
          </ResponsiveContainer>
        );
      }
}

export default PowerConsumptionBarChart;

import React, { Component } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from 'recharts';
  import {
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import {SENSOR_COLOR_PER_TYPE} from "../../constants";

class SensorsHistoryLineChart extends Component { 
    
    constructor(props){
        super(props);

        this.state = {
          sestsofData : [],
          sensorCheckBox : [],
          sensorToShow: [],
        }
    }
    
    componentDidMount() {
      if(this.props.data && this.props.sensors){
        this.props.data.map(data => (
          Object.keys(data).forEach(key => (
            data[key] = key !== "date" ? Number(data[key]).toFixed(2) :  data[key]
          ))
        ))
          this.setState({
            sestsofData : this.props.data,
            sensorCheckBox : [...new Set(this.props.sensors.map(o => o.sensor_type_parameter))],
            sensorToShow: [...new Set(this.props.sensors.map(o => o.sensor_type_parameter))],
          })  
      }
  }

  toggleCheckedSensorLegend(sensorName, isChecked){
    let tempSensorToShow = this.state.sensorToShow
    if(isChecked){
        tempSensorToShow.push(sensorName)
    }
    if(!isChecked){
      tempSensorToShow = tempSensorToShow.filter(item => item !== sensorName)
    }

    this.setState({
        sensorToShow: tempSensorToShow
    })
  } 

  additionalInfo(sensor){
    switch(sensor){
        case "iavg" : return "(average current)"
        case "vavg" : return "(average voltage)"
        case "edel" : return "(energy deliver)"
        case "freq" : return "(frequency)"
        case "pfac" : return "(power factor)"
        case "stot" : return "(apparent power)" 
        case "ptot" : return "(active power)"
        case "qtot" : return "(reactive power)" 
        case "sdtc" : return "(data checker)" 
        case "tem1" : return "(Temperature 1)" 
        case "tem2" : return "(Temperature 2)" 
        case "tem3" : return "(Temperature 3)" 
        case "hum1" : return "(Humidity 1)" 
        case "hum2" : return "(Humidity 2)" 
        case "hum3" : return "(Humidity 3)" 
        default : return ""
    }
}

    render() {
       const {sestsofData, sensorToShow, sensorCheckBox} = this.state;
       return (
        <React.Fragment>
        {sensorCheckBox &&  sestsofData.length > 0? 
            sensorCheckBox.length === 0 ? 
              null
             :
             <React.Fragment>
             <Form style={{marginLeft : '27px'}}>
                 <FormGroup check>
                 {
                     sensorCheckBox.map( s => (
                         <Label check style={{paddingLeft : '25px',  "color":SENSOR_COLOR_PER_TYPE[s]}}>
                             <Input 
                                 name={s}
                                 defaultChecked={true}
                                 type="checkbox" 
                                 onChange={(e) => this.toggleCheckedSensorLegend(e.target.name, e.target.checked)}/>{' '}
                                 {s} {this.additionalInfo(s)}
                         </Label>
                 ))}
                 </FormGroup>
             </Form>
             </React.Fragment> : null}
            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                width={500}
                height={300}
                data={sestsofData}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis type="number" domain={[0, 'dataMax + 100']}/>
                <Tooltip />
                {
                    sensorToShow.map((s,index) => (
                        <Line type="monotone" dataKey={s}
                                    stroke={SENSOR_COLOR_PER_TYPE[s]} /*fillOpacity={1}*/
                                    strokeWidth={2}
                                    /*fill={`url(#${s.replace(/\s/g, '')})`}*/ />                        
                    ))
                }
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        );
      }
}

export default SensorsHistoryLineChart

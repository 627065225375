import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import { clearAuth } from '../../../redux/actions/auth.act';
import {connect} from "react-redux";

class Logout extends Component {

    UNSAFE_componentWillMount() {
        this.props.clearAuth();
    }

    render() {
        return (<Redirect to='/login' />)
    }

}

function mapStateToProps(state){
    return {
        authData: state.authStore.authData
    }
}

export default connect(mapStateToProps, {clearAuth}) (Logout);
/*

    Pembuatan ability, sebagai objek yang menyimpan atribut user level
    beserta batasan aksesnya (disimpan dalam properti action & subject), 
    yang telah didefinisikan pada file permission.js

*/ 

import { Ability, AbilityBuilder } from "@casl/ability"
import {permissionsByLevels} from './permissions.js'
import store from '../redux/store.js';

const ability = new Ability();

const defineRulesFor =  user => {

    const { can, rules } = new AbilityBuilder();

    let getPermission = {}

    if(user.level){
        getPermission = permissionsByLevels
                                .filter(arr => arr.level === user.level)[0]
                                .permissions
        
        getPermission.can.forEach( p => {
            can(p.action, p.subject)
        })
    }

    return rules
    
}

store.subscribe(() => {
    let auth = store.getState().authStore;

    if(auth.result){
        let authUser = {
            level : String(auth.result.id_roles),
        }
        ability.update(defineRulesFor(authUser));
    }
});

export default ability;
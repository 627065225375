import {
    FETCH_DEVICE_LIST,
    RECEIVE_DEVICE_LIST,
    FAILED_DEVICE_LIST,
    CREATE_DEVICE_TYPE,
    EDIT_DEVICE_TYPE,
    DELETE_DEVICE_TYPE,
    FETCH_DEVICE_FABRICATOR_LIST,
    RECEIVE_DEVICE_FABRICATOR_LIST,
    FAILED_DEVICE_FABRICATOR_LIST
} from "../types/devices.types";

import {fetchDevicesService, 
        createDeviceService, 
        editDeviceService, 
        deleteDeviceService,
        fetchDeviceFabricationListService} from  "../../services/devices.service";

export const fetchDevices = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_DEVICE_LIST,
        });
        const response = await fetchDevicesService(auth);
        if(response.status === 200){

                dispatch({
                    type: RECEIVE_DEVICE_LIST,
                    payload: response.data
                });

        }
    } catch(error) {
        dispatch({
            type: FAILED_DEVICE_LIST,
        });
       return Promise.reject(error);
    }
};

export const createDevice = (newDevice) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await createDeviceService(newDevice, auth);
      dispatch({
        type: CREATE_DEVICE_TYPE,
        payload: response.data,
      });
      return Promise.resolve(response.status);
    } catch (err) {
      return Promise.reject(err);
    }
};

export const editDevice = device => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await editDeviceService(device, auth);
      dispatch({
        type: EDIT_DEVICE_TYPE,
        payload: device,
      });

      return Promise.resolve(response.status);

    } catch (err) {
      return Promise.reject(err);
    }
};

export const deleteDevice = deviceId => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await deleteDeviceService(deviceId, auth);
      dispatch({
        type: DELETE_DEVICE_TYPE,
        payload: { deviceId },
      });

      return Promise.resolve(response.status);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const fetchDeviceFabricationList = () => async (dispatch) => {
  try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      dispatch({
          type: FETCH_DEVICE_FABRICATOR_LIST,
      });
    const response = await fetchDeviceFabricationListService(auth);
    if(response.status === 200){
          dispatch({
              type: RECEIVE_DEVICE_FABRICATOR_LIST,
              payload: response.data
          });
    }
  } catch(error) {
      dispatch({
          type: FAILED_DEVICE_FABRICATOR_LIST,
      });
     return Promise.reject(error);   
  }
};






import {
    FETCH_SENSOR_LIST,
    RECEIVE_SENSOR_LIST,
    FAILED_SENSOR_LIST,
    EDIT_SENSOR,
    DELETE_SENSOR
} from "../types/sensors.types";
import {fetchSensorListService, editSensorService, deleteSensorService} from  "../../services/sensors.service";

export const fetchSensorList = () => async (dispatch) => {
    try {
        let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        let auth = loginCreds.token;
        
        dispatch({
            type: FETCH_SENSOR_LIST,
        });

      const response = await fetchSensorListService(auth);
      
      if(response.status === 200){
            dispatch({
                type: RECEIVE_SENSOR_LIST,
                payload: response.data
            });
      }
    } catch(error) {
        dispatch({
            type: FAILED_SENSOR_LIST,
        });

       return Promise.reject(error);
    }
};

export const editSensor = sensor => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await editSensorService(sensor, auth);
      dispatch({
        type: EDIT_SENSOR,
        payload: sensor,
      });
  
      return Promise.resolve(response.status);
  
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteSensor = sensorId => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await deleteSensorService(sensorId, auth);
      dispatch({
        type: DELETE_SENSOR,
        payload: { sensorId },
      });
  
      return Promise.resolve(response.status);
    } catch (err) {
        return Promise.reject(err);
    }
  };

import {
    CREATE_SENSOR_FABRICATOR_TYPE,
    FETCH_SENSOR_FABRICATOR_LIST,
    RECEIVE_SENSOR_FABRICATOR_LIST,
    FAILED_SENSOR_FABRICATOR_LIST,
    EDIT_SENSOR_FABRICATOR,
    DELETE_SENSOR_FABRICATOR
} from "../types/sensors.types";

const defaultSensorFabricatorState = {
    sensorFabricatorData: [],
    totalSensorFabricator: 0,
    sensorFabricatorSelect: [],
    success: true,
    inProgress: false,
}

export function fetchSensorFabricatorListReducer(state = defaultSensorFabricatorState, action){
    const {type, payload} = action;
    switch (type) {
        case FETCH_SENSOR_FABRICATOR_LIST:
            return ({sensorFabricatorData: [], success: true, totalSensorFabricator: 0, sensorFabricatorSelect: [], inProgress: true})
        case RECEIVE_SENSOR_FABRICATOR_LIST:
            var _totalSensorFabricator = payload.result.length;
            var _selectSensorFabricator = []
            if(payload.result !== []) {
                var _locationData = payload.result

                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].sensor_fabricator_name + ' - ' + _locationData[index].sensor_fabricator_desc,
                        'value': _locationData[index].id
                    }
                    _selectSensorFabricator.push(_loc)
                })
            }
            return Object.assign({}, state, {sensorFabricatorData: payload, success: true, totalSensorFabricator: _totalSensorFabricator, sensorFabricatorSelect: _selectSensorFabricator, inProgress: false})
        case FAILED_SENSOR_FABRICATOR_LIST:
            return ({sensorFabricatorData: [], success: false, totalSensorFabricator: 0, sensorFabricatorSelect: [], inProgress: false})
        case CREATE_SENSOR_FABRICATOR_TYPE:
            return state
        case EDIT_SENSOR_FABRICATOR :
            return state
        case DELETE_SENSOR_FABRICATOR :
            return state
        default:
            return state
    }
}
import * as API_LINKS from './api.links';
import axios from 'axios';

let controllerProcessLiveMonitoring = null

export function fetchKwhAverageService(auth, idDevice, idSensor, startUnixTime, endUnixTime, meta){
    return  axios({
                method: 'post',
                url: API_LINKS.KWH_AVERAGE_URL,
                signal: controllerProcessLiveMonitoring.signal,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id_device : String(idDevice),
                    id_sensor : String(idSensor),
                    start_date : String(startUnixTime),
                    end_date : String(endUnixTime),
                    // page : meta.page,
                    // size : meta.size,
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
}

export function fetchSensorHistoryGroupingService(auth, idDevice, startUnixTime, endUnixTime, meta){
    return  axios({
                method: 'post',
                url: API_LINKS.SENSOR_HISTORY_GROUP_URL,
                signal: controllerProcessLiveMonitoring.signal,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id_device : idDevice,
                    start_date : startUnixTime,
                    end_date : endUnixTime,
                    // page : meta.page,
                    // size : meta.size,
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
        }

export function fetchSensorSensorAlertGroupingService(auth, idDevice, startUnixTime, endUnixTime, meta){
    return  axios({
                method: 'post',
                url: API_LINKS.SENSOR_ALERT_GROUP_URL,
                signal: controllerProcessLiveMonitoring.signal,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id_device : idDevice,
                    start_date : startUnixTime,
                    end_date : endUnixTime,
                    // page : meta.page,
                    // size : meta.size,
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
        }

export function fetchDeviceActiveTimeService(auth, idDevice, idSensor, startUnixTime, endUnixTime, meta){
    return  axios({
                method: 'post',
                url: API_LINKS.DEVICE_ACTIVE_TIME_URL,
                signal: controllerProcessLiveMonitoring.signal,
                headers: {
                    Authorization: auth,
                },
                data : {
                    id_device : String(idDevice),
                    id_sensor : String(idSensor),
                    start_date : String(startUnixTime),
                    end_date : String(endUnixTime),
                    // page : meta.page,
                    // size : meta.size,
                }
            }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
        }

export function fetchNormalPeakTarifService(auth, idSensorArr,  dateRange, tariff){
    return  axios({
        method: 'post',
        url: API_LINKS.NORMAL_PEAK_TARIFF,
        signal: controllerProcessLiveMonitoring.signal,
        headers: {
            Authorization: auth,
            
        },
        data : {
            id_sensor : String(idSensorArr),
            start_date : String(dateRange.start),
            end_date : String(dateRange.end),
            normal_tarif : String(tariff.normal),
            peak_tarif : String(tariff.peak),
            // page : meta.page,
            // size : meta.size,
        }
    }).catch((e)=> {console.warn(e.name === "AbortError" ? "Promise Aborted" : "Promise Rejected");})
}

export function processLiveMonitoringRestartService(){
    controllerProcessLiveMonitoring = new AbortController();
}

export function processLiveMonitoringFetchCancelationService(){
    controllerProcessLiveMonitoring.abort()
}
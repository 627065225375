import {
    UPLOAD_IMAGE,
    RECEIVE_UPLOAD_IMAGE,
    FAILED_UPLOAD_IMAGE
} from "../types/upload-image.types";

import {uploadImageService} from  "../../services/upload-image.service";

export const uploadImage = (uploadImageData) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: UPLOAD_IMAGE,
        });
        const response = await uploadImageService(auth, uploadImageData);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_UPLOAD_IMAGE,
                    payload: response.data
                });
                return Promise.resolve(response.data);
        }
        return Promise()
    } catch(error) {
        dispatch({
            type: FAILED_UPLOAD_IMAGE,
        });
       return Promise.reject(error);
    }
};

export const uploadImageForEdit = (uploadImageData) => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: UPLOAD_IMAGE,
        });
        if(typeof uploadImageData.file === 'object'){
            const response = await uploadImageService(auth, uploadImageData);
            if(response.status === 200){
                dispatch({
                    type: RECEIVE_UPLOAD_IMAGE,
                    payload: response.data
                });
                return Promise.resolve(response.data);
        }
        }else{
            return null
        }
        return Promise()
    } catch(error) {
        dispatch({
            type: FAILED_UPLOAD_IMAGE,
        });
       return Promise.reject(error);
    }
};
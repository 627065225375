import React, {Component} from 'react'
import {
    Row,
    Col,
    Progress,
} from 'reactstrap'
import {defaultIcon, UTILITIES_TYPE_ICON} from '../../constants/'; 

class UtilityUsageRank extends Component{

    constructor(props){
        super(props)
        this.state = {
            utilityUsage : [],
        }
    }

    sortUtilityFromHigestUsage = (utilityUsage) => {
        const PUT_IN_LOWER_POSITION = -1
        const PUT_IN_HIGHER_POSITION = 1
        let result =  utilityUsage.sort((a, b) => (a.usagePercentage > b.usagePercentage ? 
                                                        PUT_IN_LOWER_POSITION : PUT_IN_HIGHER_POSITION))
        return result
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {utilityUsage} = nextProps

        if(utilityUsage === undefined)
            return

        if(utilityUsage === [])
            return

        let sortedUtility = this.sortUtilityFromHigestUsage(utilityUsage)
        this.setState({
            utilityUsage : sortedUtility,
        })
    }

    getUtilityIconById = (utilityTypeId) => {
        let preCheckAvailablityIconObject = UTILITIES_TYPE_ICON.filter(item => item.id === utilityTypeId) || []
        let icon = preCheckAvailablityIconObject.length > 0 ? preCheckAvailablityIconObject[0].icon : defaultIcon
        return icon
    }

    getProgressBarColorBasedOnPercentage = (percentage) => {
        switch(true){
            case percentage > 75 : return "danger"
            case percentage > 50 : return "warning"
            case percentage > 35 : return "primary"
            default : return "success" 
        }
    }

    render(){
        const {utilityUsage} = this.state
        return(
            <React.Fragment>
                {utilityUsage.map(utility => (
                    <Row md={12} xs={12}>
                        <Col md={12}> 
                            <img src={this.getUtilityIconById(utility.typeId)} width={48} height={48} alt="utility-icon"/>
                            <div style={{ marginLeft: "12px", display: "inline-block", width: "90%"}}> 
                                <dt>{utility.utilityName}</dt>
                                <dd><Progress color={this.getProgressBarColorBasedOnPercentage(utility.usagePercentage)} 
                                              striped bar
                                              value={utility.usagePercentage}>{utility.usagePercentage}%</Progress></dd>
                            </div>
                        </Col>
                    </Row>   
                ))}             
            </React.Fragment>
        )
    }

}
export default UtilityUsageRank
import React, { Component } from 'react';
import {
    Row,
    Col,
    Button,
    // Modal,
    // ModalHeader,
    // ModalBody,
    Card,
    CardHeader,
    CardBody,
    Input,
} from 'reactstrap'
import { Stage, Layer, Image as KonvaImage, Group, Rect, Text, Transformer } from 'react-konva';
import {  
    VISUAL_DRAWING_LAST_APPLY_SENSORS,
    VISUAL_DRAWING_LAST_APPLY_UTILITIES,
    UTILITIES_TYPE_ICON,
    defaultIcon
} from '../../../constants/';
import { connect } from "react-redux";
import {handleErrorResponseCode} from '../../../helpers'
import {uploadImage, uploadImageForEdit} from '../../../redux/actions/upload-image.act';
import {fetchVisualModel, fetchVisualModelSensor, fetchVisualModelUtilities,
        createVisualModel, createVisualModelDevice, createVisualModelSensor, createVisualModelUtilities,
        editVisualModel, editVisualModelDevice, editVisualModelSensor, editVisualModelUtilities,
        deleteVisualModelDevice, deleteVisualModelSensor, deleteVisualModelUtilities} from '../../../redux/actions/visual-modeling.act';
import {toast}  from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {deviceValueFactory} from './live-data.helper'

class ViewSelectedComponent extends Component{

    constructor(props){
        super(props);

        this.state = { 
            selectedNode : null,
            objectDeleteConfirmation : false,
        }
    }
    
    componentWillReceiveProps(nextProps) {
        let newSelectedNode = nextProps.selectedObject;
        this.setState({
            selectedNode : newSelectedNode,
        })
    }

    viewConfirmObjectDelete = () => {
        return(
            <div>
                <Row>
                    <Col md="12">
                        <em class="text-muted">Are you sure want to delete this object?</em>
                    </Col>
                </Row><br/>
                <div>
                    <Button onClick={this.deleteSelectedObject()} size="sm" color="danger"><i className="fa fa-dot-circle-o"></i> Yes</Button>
                    <Button onClick={this.toggleOpenConfirmObjectDelete} size="sm" color="warning"><i className="fa fa-window-close"></i> No</Button>
                </div>
            </div>
        )
    }

    toggleOpenConfirmObjectDelete = () => {
        // this.setState(
        //     {
        //         objectDeleteConfirmation: !this.state.objectDeleteConfirmation,
        //     }
        // );
    }

    deleteSelectedObject(){
        const {selectedNode} = this.state;
        const objectName = selectedNode.attrs.object_name;
        const objectType = selectedNode.attrs.type;
        this.props.onObjectDelete(objectName, objectType);
    }

    render() {
        const {selectedNode} = this.state; //, objectDeleteConfirmation
        return (
            <React.Fragment>
                {selectedNode ? !selectedNode.attrs.object_name ? null :
                    <Card>
                    <CardHeader>
                        <Row style={{"display": "flex",
                                        "align-items": "center",
                                        "justify-content": "center"
                                        }}>
                            <Col md="8">
                                Selected Object Info of <b>{selectedNode.attrs.object_name}</b>
                            </Col>
                            <Col md="4">
                                <Button className="btn btn-danger" color="danger" size="md" onClick={() => this.deleteSelectedObject()} >
                                    <i className="fa fa-trash"></i>&nbsp;
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="6">
                            <dt>X Axis</dt>
                            <dd><Input type="text" 
                                        name="text-input" 
                                        id="xAxis" 
                                        value={selectedNode.attrs.x} 
                                        disabled={true} 
                                        autoComplete="off"/>
                            </dd>
                            </Col>
                            <Col md="6">
                                <dt>Y Axis</dt>
                                <dd><Input type="text" 
                                            name="text-input" 
                                            id="yAxis" 
                                            value={selectedNode.attrs.y} 
                                            disabled={true} 
                                            autoComplete="off"/>
                                </dd>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                            <dt>Width</dt>
                            <dd><Input type="text" 
                                        name="text-input" 
                                        id="xScale" 
                                        value={selectedNode.attrs.scaleX * selectedNode.attrs.width} 
                                        disabled={true} 
                                        autoComplete="off"/>
                            </dd>
                            </Col>
                            <Col md="6">
                                <dt>Height</dt>
                                <dd><Input type="text" 
                                            name="text-input" 
                                            id="yScale" 
                                            value={selectedNode.attrs.scaleY * selectedNode.attrs.height} 
                                            disabled={true} 
                                            autoComplete="off"/>
                                </dd>
                            </Col>
                        </Row>
                    </CardBody>
                    </Card>      
                    : null
            }
            {/* <Modal isOpen={objectDeleteConfirmation} toggle={this.toggleOpenConfirmObjectDelete} className={this.props.className} backdrop="static" >
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                        {this.viewConfirmObjectDelete()}
                </ModalBody>
            </Modal> */}
        </React.Fragment>
    )
    }
}

class TransformerComponent extends Component {
    
    componentDidMount() {
      this.checkNode();
    }

    componentDidUpdate() {
      this.checkNode();
    }

    checkNode() {
        const stage = this.transformer.getStage();
        const { selectedShapeName, isViewing } = this.props;
        const selectedNode = stage.findOne("." + selectedShapeName);
        if(!isViewing){
            if (selectedNode === this.transformer.node()) {
                return;
            }
            if (selectedNode) {
                this.transformer.attachTo(selectedNode);
            } else {
                this.transformer.detach();
            }
            this.transformer.getLayer().batchDraw();
        }
    }
    render() {
    return (
        <Transformer
        ref={node => {
            this.transformer = node;
        }}
        />
    );
    }
}
  
class VisualModelingDrawing extends Component {

    constructor(props){
        super(props);

        this.state = { 
            sensorDrawProperties : [],
            sensorLastIndexLoad: 0,
            utilityDrawProperties : [],
            utilityLastIndexLoad : 0,
            backgroundLoaded : false,
            selectedShapeName: "",
            selectedObject : null,
            loadEditOnce : true,
            deletedDeviceHistory: [],
            deletedUtilityHistory: []

        }
    }

    componentDidMount(){
        if(this.props.isView || this.props.isEdit){
            this.setState({
                sensorDrawProperties : this.props.sensors,
                utilityDrawProperties : this.props.utilites
            })
        }
    }

    convertImageToCanvas = (image) => {
        const {isView, isEdit} = this.props
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let img = new Image();
        if((isView || isEdit) && typeof image !== 'object' ){
            img.src = image
        }else{
            const reader = new FileReader();
            reader.addEventListener("load", function () {
                // convert image file to base64 string and save to localStorage
                localStorage.setItem('tempImgData', reader.result)
            }, false);
            reader.readAsDataURL(image);
            img.src = localStorage.getItem('tempImgData');
        }
        img.height = 650
        img.width = 720
        img.onload = function(){
            let ptrn = ctx.createPattern(img,'no-repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0,0,canvas.width/2,canvas.height/2);
        } 
        return img;
    }

    convertIconToCanvas = (image) => {
        let tempImage = image
        if(tempImage.length > 0)
            image = tempImage[0].icon
        else
            image = defaultIcon
        
        let canvas = document.createElement('canvas');  
        let ctx = canvas.getContext('2d');
        let img = new Image();
        img.src = image;
        img.onload = function(){
            let ptrn = ctx.createPattern(img,'no-repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0,0,canvas.width/2,canvas.height/2);
        }     
        return img;
    }

     //i still wrote so much confusing code, damn....
     componentWillReceiveProps(nextProps) {

        if(nextProps.isSaving){
            
            if(nextProps.isEdit){
                // const {editObjId} = this.props
                const {sensorDrawProperties, utilityDrawProperties} = this.state
                // const reader = new FileReader();
                // let drawingData = JSON.parse(localStorage.getItem('drawingData')) || [];
                // let getEditedObject = drawingData.filter(e => e.id === editObjId)[0]
                // if(getEditedObject !== undefined){
                    const editedObject = {
                        // id : editObjId,
                        // floorPlan : getEditedObject.floorPlan,
                        // idCompany : localStorage.getItem('compId'),
                        // layout_name :  nextProps.locationName,
                        number_of_boundaries : this.state.sensorDrawProperties.length,
                        boundaries : this.state.sensorDrawProperties,
                        number_of_utilites: this.state.utilityDrawProperties.length,
                        utilities : this.state.utilityDrawProperties
                    }
                    // if(typeof nextProps.floorPlan === 'object' ){
                    //     reader.addEventListener("load", function () {
                    //         // convert image file to base64 string and save to localStorage
                    //         newObjectToReplace.floorPlan = reader.result
                    //         drawingData = drawingData.filter(e => e.id !== editObjId)
                    //         console.log(newObjectToReplace)
                    //         drawingData.push(newObjectToReplace)
                    //         localStorage.setItem('drawingData', JSON.stringify(drawingData));
                    //     }, false);
                    //     reader.readAsDataURL(nextProps.floorPlan);
                    // } else {
                    //     drawingData = drawingData.filter(e => e.id !== editObjId)
                    //     console.log(newObjectToReplace)
                    //     drawingData.push(newObjectToReplace)
                    //     localStorage.setItem('drawingData', JSON.stringify(drawingData));
                    // }
                    let formDataUploadImage = new FormData();
                    formDataUploadImage.append('file',nextProps.floorPlan);
                    formDataUploadImage.append('image_desc',"Gambar Layout Enermo " + nextProps.locationName);
                    this.props.uploadImageForEdit(formDataUploadImage)
                            .then(response => {
                                let result = nextProps.floorPlan
                                if(response)
                                 result = response.result.link
                                let visualModelObj ={
                                    id:nextProps.idVisualModel,
                                    id_location: nextProps.locationId,
                                    floor_plan_image_link: result,
                                    number_of_sensor: editedObject.number_of_boundaries,
                                    number_of_utilities: editedObject.number_of_utilites
                                }
                                return this.props.editVisualModel(visualModelObj)
                            })
                            .then(response => {
                                editedObject.boundaries.forEach(device => {
                                    let checkIdDeviceAvailableOnCurrentLayout = sensorDrawProperties
                                                                                        .filter(s=> s.id_device === device.id
                                                                                             && s.id_visual_model === nextProps.idVisualModel)
                                    if(checkIdDeviceAvailableOnCurrentLayout.length > 0){
                                        this.props.editVisualModelDevice({
                                            id: device.idData,
                                            id_visual_model: nextProps.idVisualModel,
                                            id_device: device.id,
                                            x: device.x,
                                            y: device.y,
                                            default_width: device.width,
                                            width_after_resize: device.widthAfterResize,
                                            default_height: device.height,
                                            height_after_resize: device.heightAfterResize
                                        })
                                    } else {
                                        this.props.createVisualModelDevice({
                                            id_visual_model: nextProps.idVisualModel,
                                            id_device: device.id,
                                            x: device.x,
                                            y: device.y,
                                            default_width: device.width,
                                            width_after_resize: device.widthAfterResize,
                                            default_height: device.height,
                                            height_after_resize: device.heightAfterResize
                                        })
                                    }
                                })
                                return response
                            })
                            .then(() => {
                                editedObject.utilities.forEach(utility => {
                                    let checkIdUtilityAvailableOnCurrentLayout = utilityDrawProperties
                                                                                        .filter(s=> s.id_utility === utility.id
                                                                                             && s.id_visual_model === nextProps.idVisualModel)
                                    if(checkIdUtilityAvailableOnCurrentLayout.length > 0){
                                        this.props.editVisualModelUtilities({
                                            id: utility.idData,
                                            id_visual_model: nextProps.idVisualModel,
                                            id_utility: utility.id,
                                            x: utility.x,
                                            y: utility.y,
                                            default_width: utility.width,
                                            width_after_resize: utility.widthAfterResize,
                                            default_height: utility.height,
                                            height_after_resize: utility.heightAfterResize
                                        })
                                    } else {
                                        this.props.createVisualModelUtilities({
                                            id_visual_model: nextProps.idVisualModel,
                                            id_utility: utility.id,
                                            x: utility.x,
                                            y: utility.y,
                                            default_width: utility.width,
                                            width_after_resize: utility.widthAfterResize,
                                            default_height: utility.height,
                                            height_after_resize: utility.heightAfterResize
                                        })
                                    }
                                })
                            })
                            .then(() => {
                                this.state.deletedDeviceHistory.forEach(device => {
                                    this.props.deleteVisualModelDevice(device.idData)
                                })               
                            })
                            .then(() => {
                                this.state.deletedUtilityHistory.forEach(utility => {
                                    this.props.deleteVisualModelUtilities(utility.idData)
                                })               
                            })
                            .then(() => {
                                toast.success("Visual model successfully created!")
                            })
                            .catch(function(error){
                                toast.error(handleErrorResponseCode(error))
                            })
                }
            

            if(!nextProps.isEdit){
                localStorage.setItem('lastIdVM', localStorage.getItem('lastIdVM') ? (parseInt(localStorage.getItem('lastIdVM')) + 1) : 1);
                // const reader = new FileReader();
                const drawingData = {
                    id : localStorage.getItem('lastIdVM'),
                    idCompany : localStorage.getItem('compId'),
                    layout_name :  nextProps.locationName,
                    number_of_boundaries : this.state.sensorDrawProperties.length,
                    boundaries : this.state.sensorDrawProperties,
                    number_of_utilites: this.state.utilityDrawProperties.length,
                    utilities : this.state.utilityDrawProperties
                }
                // reader.addEventListener("load", function () {
                //     // convert image file to base64 string and save to localStorage
                //     drawingData.floorPlan = reader.result
                //     let doThePush = JSON.parse(localStorage.getItem('drawingData')) || [];
                //     doThePush.push(drawingData)
                //     localStorage.setItem('drawingData', JSON.stringify(doThePush));
                // }, false);
                // reader.readAsDataURL(nextProps.floorPlan);
                let formDataUploadImage = new FormData();
                formDataUploadImage.append('file',nextProps.floorPlan);
                formDataUploadImage.append('image_desc',"Gambar Layout Enermo " + nextProps.locationName);
                this.props.uploadImage(formDataUploadImage)
                        .then(response => {
                            if(!response)
                                throw Object.assign(
                                    { msg : "Failed to upload image!"}
                                );
                            if(response.status !== "success")
                                throw Object.assign(
                                    { msg : "Failed to upload image!"}
                                );
                            let visualModelObj ={
                                id_location: nextProps.locationId,
                                floor_plan_image_link: response.result.link,
                                number_of_sensor: drawingData.number_of_boundaries,
                                number_of_utilities: drawingData.number_of_utilites
                            }
                            return this.props.createVisualModel(visualModelObj)
                        })
                        .then(response => {
                            drawingData.boundaries.forEach(device => {
                                this.props.createVisualModelDevice({
                                    id_visual_model: response.result.id,
                                    id_device: device.id,
                                    x: device.x,
                                    y: device.y,
                                    default_width: device.width,
                                    width_after_resize: device.widthAfterResize,
                                    default_height: device.height,
                                    height_after_resize: device.heightAfterResize
                                })
                            })
                            return response
                        })
                        .then(response => {
                            drawingData.utilities.forEach(utility => {
                                this.props.createVisualModelUtilities({
                                    id_visual_model: response.result.id,
                                    id_utility: utility.id,
                                    x: utility.x,
                                    y: utility.y,
                                    default_width: utility.width,
                                    width_after_resize: utility.widthAfterResize,
                                    default_height: utility.height,
                                    height_after_resize: utility.heightAfterResize
                                })
                            })
                        })
                        .then(() => {
                            toast.success("Visual model successfully created!")
                        })
                        .catch(function(error){
                            toast.error(handleErrorResponseCode(error))
                        })
                }
         this.props.onAfterSaving(); 
        } else if(!nextProps.isView){
            if(!nextProps.locationName){
                this.setState({
                    sensorDrawProperties : [],
                    utilityDrawProperties : [],
                })
            }
            if(nextProps.lastDataApplied === VISUAL_DRAWING_LAST_APPLY_SENSORS){
                let sensorDrawProps = this.state.sensorDrawProperties;
                nextProps.sensors.forEach((sensor) => {
                    sensorDrawProps.push({id: sensor.id, 
                                        sensor_name: sensor.device_name, 
                                        sensor_type: sensor.device_type,
                                        sensor_desc: sensor.device_desc,
                                        x: 35, y: 35, 
                                        width: 100, widthAfterResize : 100,  
                                        height: 100, heightAfterResize : 100})
                });
                this.setState({
                    sensorDrawProperties : sensorDrawProps,
                    backgroundLoaded : true
                })
            }

            if(nextProps.lastDataApplied === VISUAL_DRAWING_LAST_APPLY_UTILITIES){
                let utilityDrawProps = this.state.utilityDrawProperties;
                nextProps.utilites.forEach((utility) => {
                    utilityDrawProps.push({id: utility.id, utility_name: 
                                            utility.utility_name, typeId : utility.utility_type_id,
                                            id_device : utility.utility_device_id,
                                            utility_type : utility.utility_type_name,
                                            x: 50, y: 50, width: 50, widthAfterResize : 50,
                                            height: 50, heightAfterResize : 50})
                });
                this.setState({
                    utilityDrawProperties : utilityDrawProps,
                    backgroundLoaded : true
                })
            }

            if(nextProps.isEdit && this.state.loadEditOnce){
                let sensorDrawProps = this.state.sensorDrawProperties;
                nextProps.sensors.forEach((sensor) => {
                    sensorDrawProps.push({id: sensor.id, 
                                        sensor_name: sensor.sensor_name, 
                                        sensor_type: sensor.sensor_type,
                                        sensor_desc: sensor.device_desc,
                                        x: sensor.x, y: sensor.y, 
                                        width: sensor.widthAfterResize, widthAfterResize : sensor.widthAfterResize,  
                                        height: sensor.heightAfterResize, heightAfterResize : sensor.heightAfterResize})
                });

                let utilityDrawProps = this.state.utilityDrawProperties;
                nextProps.utilites.forEach((utility) => {
                    utilityDrawProps.push({id: utility.id, utility_name: 
                                            utility.utility_name, typeId : utility.typeId,
                                            utility_type : utility.utility_type, id_device : utility.id_device,
                                            x: utility.x, y: utility.y, 
                                            width: utility.width, widthAfterResize : utility.widthAfterResize,
                                            height: utility.height, heightAfterResize : utility.heightAfterResize})
                });

                this.setState({
                    sensorDrawProperties : sensorDrawProps,
                    utilityDrawProperties : utilityDrawProps,
                    backgroundLoaded : true,
                    loadEditOnce: false
                })
            }
        } 

        if(nextProps.isView){
            this.setState({
                sensorDrawProperties : nextProps.sensors,
                utilityDrawProperties : nextProps.utilites
            })
        }
    }

    handleStageClick = e => {
        this.setState({
          selectedShapeName: e.target.parent.name(),
          selectedObject : e.target.parent
        });
    };

    deleteObjectFromArr(objectName, objectType){
        const {deletedDeviceHistory, deletedUtilityHistory} = this.state

        let tempObject = [];
        let tempDeleteHistorytoPush = []

        if(objectType === "sensor"){
            tempDeleteHistorytoPush = this.state.sensorDrawProperties.filter(item => item.sensor_desc === objectName)[0];
            tempObject = this.state.sensorDrawProperties.filter(item => item.sensor_desc !== objectName);
            this.setState({
                sensorDrawProperties: tempObject,
                deletedDeviceHistory: [...deletedDeviceHistory, tempDeleteHistorytoPush]
            }); 
        }

        if(objectType === "utility"){
            tempDeleteHistorytoPush = this.state.utilityDrawProperties.filter(item => item.utility_name === objectName)[0];
            tempObject = this.state.utilityDrawProperties.filter(item => item.utility_name !== objectName);
            this.setState({
                utilityDrawProperties: tempObject,
                deletedUtilityHistory: [...deletedUtilityHistory, tempDeleteHistorytoPush]
            }); 
        }

    }

    render(){
        const {locationName, floorPlan, isSaving, isView, read_value_electricity, read_value_tem_hum, read_value_door, showLiveData, sensor_type} = this.props; 
        const {sensorDrawProperties, utilityDrawProperties, selectedObject} = this.state; //backgroundLoaded, objectDeleteConfirmation
        const img = floorPlan ? this.convertImageToCanvas(floorPlan) : null
        return(
                <div>
                    <React.Fragment><br /> 
                        <Row>    
                            <Col md="8">
                                <Stage width={img ? img.width : window.innerWidth} height={img ? img.height : window.innerHeight-500} onClick={this.handleStageClick} >
                                    { !locationName ? null : 
                                            <Layer>
                                                <KonvaImage
                                                    image={img}
                                                />

                                                {sensorDrawProperties && !isSaving ? sensorDrawProperties.map((sensor, index) => ( 
                                                    <Group key={index} 
                                                            name ={index+"_sensor"}
                                                            object_name = {sensor.sensor_desc}
                                                            type="sensor"
                                                            width={!isView? sensor.width : sensor.widthAfterResize}
                                                            height={ !isView? sensor.height : sensor.heightAfterResize}
                                                            x={sensor.x} 
                                                            y={sensor.y} 
                                                            scaleX={1} 
                                                            scaleY={1} 
                                                            draggable = {isView ? false : true}
                                                            onTransformEnd={e => {
                                                                let tempDrawSProperties = [...sensorDrawProperties]
                                                                tempDrawSProperties[index] =  {...tempDrawSProperties[index],  
                                                                                                widthAfterResize : e.target.attrs.width * e.target.scaleX(),
                                                                                                heightAfterResize : e.target.attrs.height * e.target.scaleY()}
                                                                this.setState({
                                                                    sensorDrawProperties : tempDrawSProperties
                                                                });
                                                            }}
                                                            onDragEnd={e => {
                                                                let tempDrawSProperties = [...sensorDrawProperties]
                                                                tempDrawSProperties[index] =  {...tempDrawSProperties[index],  
                                                                                                x: e.target.x(),
                                                                                                y: e.target.y()}
                                                                this.setState({
                                                                    sensorDrawProperties : tempDrawSProperties
                                                                });
                                                            }}>
                                                        <Rect
                                                            width={!isView ? sensor.width : sensor.widthAfterResize}
                                                            height={!isView ? sensor.height : sensor.heightAfterResize}
                                                            fill="green"
                                                            opacity= {0.5}
                                                        />
                                                        {showLiveData?
                                                             <Text
                                                                fontSize={12}
                                                                fontStyle={"bold"}
                                                                width={!isView ? sensor.width : sensor.widthAfterResize}
                                                                height={!isView ? sensor.height : sensor.heightAfterResize}
                                                                text={deviceValueFactory({
                                                                    electricity: read_value_electricity.filter(item => item.device_id === sensor.id),
                                                                    temHum: read_value_tem_hum["device_"+sensor.id]? read_value_tem_hum["device_"+sensor.id]
                                                                                                        [read_value_tem_hum["device_"+sensor.id].length-1] : [],
                                                                    door:read_value_door["device_"+sensor.id]? read_value_door["device_"+sensor.id]
                                                                                [read_value_door["device_"+sensor.id].length-1] : []
                                                                }, sensor_type.filter(s => s.id_device === sensor.id) || null, sensor.sensor_desc)}
                                                                align="center"
                                                                verticalAlign="bottom" />
                                                        :        
                                                        
                                                        <Text
                                                        fontSize={12}
                                                        fontStyle={"bold"}
                                                        width={!isView ? sensor.width : sensor.widthAfterResize}
                                                        height={!isView ? sensor.height : sensor.heightAfterResize}
                                                        text={"\n\n" +sensor.sensor_desc}
                                                        align="center"
                                                        verticalAlign="bottom" />}
                                                 
                                                    </Group>
                                                )) : null}

                                                {utilityDrawProperties && !isSaving ? utilityDrawProperties.map((utility, index) => (
                                                    <Group  key={index}  
                                                            name ={index+"_utility"}
                                                            object_name = {utility.utility_name}
                                                            type="utility"
                                                            x={utility.x} 
                                                            y={utility.y}
                                                            scaleX={1} 
                                                            scaleY={1}
                                                            width={!isView ? utility.width : utility.widthAfterResize}
                                                            height={!isView ? utility.height : utility.heightAfterResize}
                                                            draggable = {isView ? false : true}
                                                            onTransformEnd={e => {
                                                                let tempDrawUProperties = [...utilityDrawProperties]
                                                                tempDrawUProperties[index] =  {...tempDrawUProperties[index],  
                                                                                                    widthAfterResize : e.target.attrs.width * e.target.scaleX(),
                                                                                                    heightAfterResize : e.target.attrs.height * e.target.scaleY()
                                                                                                    }
                                                                this.setState({
                                                                    utilityDrawProperties : tempDrawUProperties
                                                                });
                                                            }}
                                                            onDragEnd={e => {
                                                                let tempDrawUProperties = [...utilityDrawProperties]
                                                                tempDrawUProperties[index] =  {...tempDrawUProperties[index],  
                                                                                                            x: e.target.x(),
                                                                                                            y: e.target.y()
                                                                                                        }
                                                                this.setState({
                                                                    utilityDrawProperties : tempDrawUProperties
                                                                });
                                                            }}>
                                                        <KonvaImage 
                                                            image={this.convertIconToCanvas(UTILITIES_TYPE_ICON
                                                                                                .filter(item => item.id === utility.typeId) || [])}
                                                            width={ !isView ? utility.width : utility.widthAfterResize}
                                                            height={ !isView ? utility.height : utility.heightAfterResize}
                                                        />
                                                        <Text
                                                            fontSize={12}
                                                            height={!isView ? utility.height+10 : utility.heightAfterResize+10}
                                                            text={utility.utility_name}
                                                            align="center"
                                                            verticalAlign="bottom" />
                                                        
                                                        {isView ? 
                                                            <Text
                                                            fontSize={12}
                                                            fill={"blue"}
                                                            height={utility.heightAfterResize}
                                                            text={""}
                                                            align="center"/> : null}
                                                    </Group>
                                                )) : null}
                                                <TransformerComponent
                                                        selectedShapeName={this.state.selectedShapeName}
                                                        isViewing = {isView}
                                                    /> 
                                            </Layer>  
                                            }
                                        </Stage>
                                </Col>
                                <Col md="4">
                                        {isView ? null :
                                            <div className="animated fadeIn">
                                                <ViewSelectedComponent selectedObject={selectedObject} onObjectDelete={this.deleteObjectFromArr.bind(this)} />
                                            </div>
                                        }
                                </Col>
                            </Row>     
                        </React.Fragment>
                    </div>   
                )
        }
}

const mapStateToProps = (state) => ({
    inProgressVisualModel: state.visualModelReducerStore.inProgress,
    successVisualModel: state.visualModelReducerStore.success,
    visualModelData : state.visualModelReducerStore.visualModelData,

    inProgressVisualModelSensor: state.visualModelSensorReducerStore.inProgress,
    successVisualModelSensor: state.visualModelSensorReducerStore.success,
    visualModelSensorData : state.visualModelSensorReducerStore.visualModelSensorData,

    inProgressVisualModelUtilities: state.visualModelUtilitiesReducerStore.inProgress,
    successVisualModelUtilities: state.visualModelUtilitiesReducerStore.success,
    visualModelUtilitiesData : state.visualModelUtilitiesReducerStore.visualModelUtilitiesData,

    inProgressUploadImage: state.uploadImageReducerStore.inProgress,
    successUploadImage: state.uploadImageReducerStore.success,
    uploadImageData : state.uploadImageReducerStore.uploadImageData,
})

export default connect(mapStateToProps, {uploadImage, uploadImageForEdit, fetchVisualModel, fetchVisualModelSensor, fetchVisualModelUtilities,
    createVisualModel, createVisualModelDevice, createVisualModelSensor, createVisualModelUtilities,
    editVisualModel, editVisualModelDevice, editVisualModelSensor, editVisualModelUtilities,
    deleteVisualModelDevice, deleteVisualModelSensor, deleteVisualModelUtilities})(VisualModelingDrawing);


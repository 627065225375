import {
    FETCH_DEVICE_INSTALLATION_LIST,
    RECEIVE_DEVICE_INSTALLATION_LIST,
    FAILED_DEVICE_INSTALLATION_LIST
} from "../types/devices.types";

const defaultDeviceInstallationState = {
    deviceInstallationData: [],
    totalDeviceInstallation: 0,
    isDeviceInstalled: false,
    success: true,
    inProgress: false,
}

export function fetchDeviceInstallationListReducer(state = defaultDeviceInstallationState, action){
    const {type, payload} = action
    switch (type) {
        case FETCH_DEVICE_INSTALLATION_LIST:
            return ({deviceInstallationData: [], success: true, llTotaldevice: 0, isdeviceInstalled: true, inProgress: true})
        case RECEIVE_DEVICE_INSTALLATION_LIST:
            var _totalDeviceInstallation = payload.result.length;
            var _isInstalled = payload.result.filter( x => 
                (
                    x.installation_date != null &&
                    x.removal_date == null
                )
            );
            // console.log(_isInstalled)
            var _result = _isInstalled.length
            // if(_isInstalled == []) {
            //     _result = false
            // } else {
            //     _result = true
            // }
            // console.log(_isInstalled)
            return Object.assign({}, state, {deviceInstallationData: payload, success: true, totalDeviceInstallation: _totalDeviceInstallation, isDeviceInstalled: _result, inProgress: false})
        case FAILED_DEVICE_INSTALLATION_LIST:
            return ({deviceInstallationData: [], success: false, totalDeviceInstallation: 0, isDeviceInstalled: false, inProgress: false})
        default:
            return state
    }
}
import React, { Component } from 'react';
import {
    Col,
} from 'reactstrap'
import {countRunrateOnBudgetAll, countEstimation} from '../../../../helpers'
import Chart from './Chart'
import moment from 'moment'
import {connect} from 'react-redux'
import {fetchBudgetSetting} from '../../../../redux/actions/setting.act'
import { BarLoader } from 'react-spinners'
// import { getMonthlyKwhSets, setMonthlyKwhSets} from '../../../../redux/actions/Dashboard/monthly-kwh-sets.act'

class BudgetChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSetRealKwh: 0,
            monthlyKwhSets: []
        }
    }

    componentDidMount(){
        this.props.fetchBudgetSetting()
                  .then(() => {
                        const {budgetSettingData} = this.props
                        if(budgetSettingData.result) {
                            let getThisMonthBudgetLimit = budgetSettingData.result.filter(r => 
                                r.id_field_budget === Number(moment().format("MYYYY"))
                            )[0]
                            let getDailyBudget = budgetSettingData.result.filter(r => 
                                r.id_field_budget === 10004
                            )[0]
                            this.setState({
                                budgetKwh : getThisMonthBudgetLimit? getThisMonthBudgetLimit.budget_limit : 0,
                                dailyBudget: getDailyBudget? getDailyBudget.budget_limit : 0,
                            })
                        } 
                  })
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.monthlyKwhSets)  
        setTimeout(()=> {
            this.setState({
                monthlyKwhSets: nextProps.monthlyKwhSets,
                dataSetRealKwh : nextProps.monthlyKwhSets,
            })
        },5000)
    }

    buildGraphData = () => {
        const {budgetKwh, monthlyKwhSets, dailyBudget} = this.state
        let graphData = []
        let mth = 0
        if(monthlyKwhSets){
            for(let data of monthlyKwhSets){
                let date = new Date(data["date"])
                mth = Number(mth + data["kwhAvg"])
                graphData.push({
                    'Days' : moment(date).format("YYYY-MM-DD"),
                    'Budget' : budgetKwh,
                    'Daily Budget' : dailyBudget,
                    'Runrate On Budget' : Number(countRunrateOnBudgetAll(budgetKwh, date)).toFixed(2).toLocaleString(),
                    'Real Today Consumption' : Number(data["kwhAvg"]).toFixed(2).toLocaleString() ,
                    'Est. Consumption' :Number(countEstimation(data["kwhAvg"])).toFixed(2).toLocaleString(),
                    'Real Mtd. Consumption' : mth.toFixed(2).toLocaleString()
                })
            }
        }   
        return this.sortByDateAsc(graphData)
    }

    sortByDateAsc = (graphData) => {
        const PUT_IN_LOWER_POSITION = -1
        const PUT_IN_HIGHER_POSITION = 1
        let result =  graphData.sort((a, b) => (a['Days'] < b['Days'] ? 
                                                        PUT_IN_LOWER_POSITION : PUT_IN_HIGHER_POSITION))
        return result
    }

    render(){
        const {dataSetRealKwh} = this.state
        const {budgetSettingData} = this.props
        return(
            <React.Fragment>
                {dataSetRealKwh && budgetSettingData.result?
                    <Chart setsOfData = {this.buildGraphData()}/> : 
                    <Col md={4} xs={12}><BarLoader color={'#123abc'} loading={!dataSetRealKwh || budgetSettingData.result}/><br /></Col>
                }
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        budgetSettingData :  state.budgetSettingStore.budgetSettingData,
        inProgressBudgetSetting : state.budgetSettingStore.inProgress,
        successBudgetSetting : state.budgetSettingStore.success,

        // successGetMonthlyKwhSets: state.monthlyKwhSetsStore.success,
        // monthlyKwhSets : state.monthlyKwhSetsStore.sets,
    }
}

export default connect(mapStateToProps, {fetchBudgetSetting})(BudgetChart);
//getMonthlyKwhSets setMonthlyKwhSets
import {
    FETCH_GENERAL_SETTING_LIST,
    RECEIVE_GENERAL_SETTING_LIST,
    FAILED_GENERAL_SETTING_LIST,
    UPSERT_GENERAL_SETTING,

    FETCH_BUDGET_SETTING_LIST,
    RECEIVE_BUDGET_SETTING_LIST,
    FAILED_BUDGET_SETTING_LIST,
    UPSERT_BUDGET_SETTING
} from "../types/setting.types";

//General Settings
const defaultGeneralSettingState = {
   generalSettingData: [],
   totalGeneralSetting: 0,
   generalSettingSelect: [],
   success: true,
   inProgress: false,
}

export function generalSettingReducer(state = defaultGeneralSettingState, action){

    const { type, payload } = action

    switch (type) {
        case FETCH_GENERAL_SETTING_LIST:
            return ({generalSettingData: [], success: true, deviceTotal: 0,generalSettingSelect: [], inProgress: true})

        case RECEIVE_GENERAL_SETTING_LIST:
            var _totalGeneralSetting = payload.result.length;
            var _selectGeneralSetting = []
            return Object.assign({}, state, {generalSettingData: payload, success: true, totalGeneralSetting: _totalGeneralSetting,generalSettingSelect: _selectGeneralSetting, inProgress: false})

        case FAILED_GENERAL_SETTING_LIST:
            return ({generalSettingData: [], success: false, totalGeneralSetting: 0,generalSettingSelect: [], inProgress: false})

        case UPSERT_GENERAL_SETTING:
            return state

        default:
            return state
    }
}

//Budget Settings
const defaultBudgetSettingState = {
   budgetSettingData: [],
   totalBudgetSetting: 0,
   budgetSettingSelect: [],
   success: true,
   inProgress: false,
}

export function budgetSettingReducer(state = defaultBudgetSettingState, action){

    const { type, payload } = action

    switch (type) {
        case FETCH_BUDGET_SETTING_LIST:
            return ({budgetSettingData: [], success: true, deviceTotal: 0,budgetSettingSelect: [], inProgress: true})

        case RECEIVE_BUDGET_SETTING_LIST:
            var _totalBudgetSetting = payload.result.length;
            var _selectBudgetSetting = []
            return Object.assign({}, state, {budgetSettingData: payload, success: true, totalBudgetSetting: _totalBudgetSetting,budgetSettingSelect: _selectBudgetSetting, inProgress: false})

        case FAILED_BUDGET_SETTING_LIST:
            return ({budgetSettingData: [], success: false, totalBudgetSetting: 0,budgetSettingSelect: [], inProgress: false})

        case UPSERT_BUDGET_SETTING:
            return state

        default:
            return state
    }
}
import * as API_LINKS from './api.links';
import axios from 'axios';

export function login(userEmail, userPassword){

    return  axios({
                method: 'post',
                url: API_LINKS.LOGIN_URL,
                data: {
                    email: userEmail,
                    password: userPassword
                }
            });
}

export function changePasswordService(auth, userEmail, oldPassword, newPassword, confirmedNewPassword){
    return  axios({
                method: 'post',
                url: API_LINKS.CHANGE_PASSWORD_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    email: userEmail,
                    password: oldPassword,
                    new_password: newPassword,
                    confirm_new_password: confirmedNewPassword
                }
            });
}
import React, { Component } from 'react';
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from 'recharts';
  import {
    Form,
    FormGroup,
    Label,
} from 'reactstrap'

class Chart extends Component { 
    
// {
//     'Days' : '01-10-2021',
//     'Budget' : 1,
//     'Runrate On Budget' : 0.90,
//     'Real Today Consumption' : 0.60,
//     'Est. Consumption vs Budget Time' :0.70,
//     'Est. Consumption vs Budget Ratio' :0.80,
//     'Todays Consumption Ratio' :0.90,
//     'Realization to Budget Rate Ration' :0.10,
// },

    render() {
       const {setsOfData} = this.props;
       return (
        <React.Fragment>
          <Form style={{marginLeft : '27px'}}>
          <FormGroup>
              <Label style={{paddingLeft : '25px'}}>
                <div style={{float:'left',  display:'inline-block'}}>
                  <svg width="20px" height="20px" viewBox="0 0 30 30">
                      <rect style={{fill:'#20a8d8'}} x="0" y="0" width="20px" height="20px"/> 
                  </svg>
                </div>
                <div style={{float:'left',  display:'inline-block'}}>
                  Budget
                </div>
              </Label>
              <Label style={{paddingLeft : '25px'}}>
                <div style={{float:'left',  display:'inline-block'}}>
                  <svg width="20px" height="20px" viewBox="0 0 30 30">
                      <rect style={{fill:'#4dbd74'}} x="0" y="0" width="20px" height="20px"/> 
                  </svg>
                </div>
                <div style={{float:'left',  display:'inline-block'}}>
                  Runrate on Budget
                </div>
              </Label>
              <Label style={{paddingLeft : '25px'}}>
                <div style={{float:'left',  display:'inline-block'}}>
                  <svg width="20px" height="20px" viewBox="0 0 30 30">
                      <rect style={{fill:'#007bff'}} x="0" y="0" width="20px" height="20px"/> 
                  </svg>
                </div>
                <div style={{float:'left',  display:'inline-block'}}>
                  Daily Budget
                </div>
              </Label>
              <Label style={{paddingLeft : '25px'}}>
                <div style={{float:'left',  display:'inline-block'}}>
                  <svg width="20px" height="20px" viewBox="0 0 30 30">
                  <rect style={{fill:'#000000'}} x="0" y="0" width="20px" height="20px"/>
                  </svg>
                </div>
                <div style={{float:'left',  display:'inline-block'}}>
                Real Today Consumption
                </div>
              </Label>
              <Label style={{paddingLeft : '25px'}}>
                <div style={{float:'left',  display:'inline-block'}}>
                  <svg width="20px" height="20px" viewBox="0 0 30 30">
                  <rect style={{fill:'#ffc107'}} x="0" y="0" width="20px" height="20px"/> 
                  </svg>
                </div>
                <div style={{float:'left',  display:'inline-block'}}>
                  Est. Consumption
                </div>
              </Label>
              <Label style={{paddingLeft : '25px'}}>
                <div style={{float:'left',  display:'inline-block'}}>
                  <svg width="20px" height="20px" viewBox="0 0 30 30">
                  <rect style={{fill:'#c0392b'}} x="0" y="0" width="20px" height="20px"/> 
                  </svg>
                </div>
                <div style={{float:'left',  display:'inline-block'}}>
                  Mtd. Consumption
                </div>
              </Label>
          </FormGroup>
        </Form>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              width="100%" 
              height={250}
              data={setsOfData}
              margin={{ top: 25, right: 20, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Days" />
              <YAxis />
              <Tooltip />
            <Bar dataKey="Budget" stackId="1" barSize={20}   fill={"#20a8d8"} />
            <Bar dataKey="Runrate On Budget" stackId="2" barSize={20}  fill={"#4dbd74"} />
            <Bar dataKey="Daily Budget" stackId="3" barSize={20}  fill={"#007bff"} />
            {/* <Line type="monotone" dataKey="Real Today Consumption" strokeWidth={3}  stroke={"#000000"} />
            <Line type="monotone" dataKey="Est. Consumption" strokeWidth={3} stroke={"#ffc107"} /> */}
            <Bar type="monotone" dataKey="Real Today Consumption"  stackId="4" barSize={20}  fill={"#000000"} />
            <Bar type="monotone" dataKey="Est. Consumption"  stackId="5" barSize={20} fill={"#ffc107"} />
            <Bar type="monotone" dataKey="Real Mtd. Consumption"  stackId="6" barSize={20} fill={"#c0392b"} />
            </ComposedChart>
          </ResponsiveContainer>
        </React.Fragment>
        );
      }
}

export default Chart;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { mapToCssModules } from 'reactstrap/lib/utils';
import { BarLoader } from 'react-spinners'

const propTypes = {
  utility_name: PropTypes.string,
  utility_type: PropTypes.string,
  read_value : PropTypes.number,
  unit : PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  footer: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  utility_name: 'Utility Name',
  utility_type: 'Utility Type',
  read_value: '0',
  Unit: '',
  icon: 'fa fa-bolt',
  color: 'primary',
  variant: '0',
  link: '#',
};

class UtilitiesSummaryWidgets extends Component {
  render() {
    const { className, cssModule, utility_name, voltage, current, utility_type, read_value, unit, icon, color, footer, link, children, variant, monitored_by_device, ...attributes } = this.props;

    const padding = (variant === '0' ? { card: 'p-3', icon: 'p-3', lead: 'mt-2' } : (variant === '1' ? {
      card: 'p-0', icon: 'p-4', lead: 'pt-3',
    } : { card: 'p-0', icon: 'p-4 px-5', lead: 'pt-3' }));

    const card = { style: 'clearfix', color: color, icon: icon, classes: '' };
    card.classes = mapToCssModules(classNames(className, card.style, padding.card), cssModule);

    const lead = { style: 'h5 mb-0', color: color, classes: '' };
    lead.classes = classNames(lead.style, 'text-' + card.color, padding.lead, "font-weight-bold");

    const blockIcon = function (icon) {
      const classes = classNames(icon, padding.icon, 'font-2xl mr-3 float-right');
      return (<i className={classes}></i>);
    };

    //Maybe will be needed in te future
    // const cardFooter = function () {
    //   if (footer) {
    //     return (
    //       <CardFooter className="px-3 py-2">
    //         <a className="font-weight-bold font-xs btn-block text-muted" href={link}>View More
    //           <i className="fa fa-angle-right float-right font-lg"></i></a>
    //       </CardFooter>
    //     );
    //   }
    // };

    return (
      <Card >
        <CardBody className={card.classes} {...attributes}>
          <Row> 
            <Col md={12} xs={12} style={{backgroundColor: '#3498db'}}>{blockIcon(card.icon)} </Col>
            <Col md={12} xs={12} className={lead.classes}>{utility_name}</Col>
            <Col md={12} xs={12} className="text-muted text-uppercase font-weight-bold font-xs">{utility_type}</Col>
            <Col style={{color: monitored_by_device === "Device Not Available"? "red" : "black"}}  md={12} xs={12} className="font-weight-bold font-xs">{
              monitored_by_device ? monitored_by_device : ''
            }</Col>
             <Col md={12} xs={12} className="text-uppercase font-weight-bold font-xs" style={{color:'blue'}}>{`${voltage} V ${current} I`}</Col>
            {read_value === "Monitored"?
                <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'green'}}>{read_value}</Col>
                :
              read_value === "Unmonitored"?
                <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>{read_value}</Col>
                :
                <Col md={12} xs={12} className="text-uppercase font-weight-bold" style={{color:'red'}}>
                              <BarLoader  width={10} color={'#123abc'} loading={true}/>
                </Col>
            }
          </Row>
        </CardBody>
      </Card>
    );
  }
}

UtilitiesSummaryWidgets.propTypes = propTypes;
UtilitiesSummaryWidgets.defaultProps = defaultProps;

export default UtilitiesSummaryWidgets;
const permissionsByLevels = [

    {

        level : '4',

        permissions : {

            can : [

                //User Management
                { action : 'view', subject : 'main_menu@user_management'},

                //Device
                { action : 'view', subject : 'add_button@devices_datatable'},
                { action : 'view', subject : 'edit_button@devices_datatable'},
                { action : 'view', subject : 'delete_button@devices_datatable'},

                //Sensors
                { action : 'view', subject : 'add_button@sensors_datatable'},
                { action : 'view', subject : 'edit_button@sensors_datatable'},
                { action : 'view', subject : 'delete_button@sensors_datatable'},
                { action : 'view', subject : 'action_field@sensors_datatable'},

                //Sensors Class
                { action : 'view', subject : 'add_button@sensors_class_datatable'},
                { action : 'view', subject : 'edit_button@sensors_class_datatable'},
                { action : 'view', subject : 'delete_button@sensors_class_datatable'},
                { action : 'view', subject : 'action_field@sensors_class_datatable'},

                //Sensors Fabricator
                { action : 'view', subject : 'add_button@sensors_fabricator_datatable'},
                { action : 'view', subject : 'edit_button@sensors_fabricator_datatable'},
                { action : 'view', subject : 'delete_button@sensors_fabricator_datatable'},
                { action : 'view', subject : 'action_field@sensors_fabricator_datatable'},

                //Visual Modeling
                { action : 'view', subject : 'add_button@visual_modeling_datatable'},
                { action : 'view', subject : 'edit_button@visual_modeling_datatable'},
                { action : 'view', subject : 'delete_button@visual_modeling_datatable'},

                //Locations
                { action : 'view', subject : 'add_button@locations_datatable'},
                { action : 'view', subject : 'edit_button@locations_datatable'},
                { action : 'view', subject : 'delete_button@locations_datatable'},
                { action : 'view', subject : 'action_field@locations_datatable'},

                //Locations Type
                { action : 'view', subject : 'add_button@locations_type_datatable'},
                { action : 'view', subject : 'edit_button@locations_type_datatable'},
                { action : 'view', subject : 'delete_button@locations_type_datatable'},
                { action : 'view', subject : 'action_field@locations_type_datatable'},

                //Utilities
                { action : 'view', subject : 'add_button@utilities_datatable'},
                { action : 'view', subject : 'edit_button@utilities_datatable'},
                { action : 'view', subject : 'delete_button@utilities_datatable'},
                { action : 'view', subject : 'action_field@utilities_datatable'},

                //Utility Type
                { action : 'view', subject : 'add_button@utilities_type_datatable'},
                { action : 'view', subject : 'edit_button@utilities_type_datatable'},
                { action : 'view', subject : 'delete_button@utilities_type_datatable'},
                { action : 'view', subject : 'action_field@utilities_type_datatable'},

                //Management Datatable
                {action : 'view', subject : 'lock_unclock_and_reset_password_button@management_datatable'},

                //Management
                {action : 'view', subject : 'management_add_user@management'},
                {action : 'view', subject : 'card@management'},
                
                //Settings
                {action : 'enable', subject : 'all_settings@settings'},

                //Login, Select Company
                { action : 'view', subject : 'select_company@login'},

                //Energy Audit Menu
                { action : 'view', subject : 'main_menu@energy_audit'},

                //Variables Menu
                { action : 'view', subject : 'main_menu@variable'},
            ]

        },

    },

    {

        level : '1',

        permissions : {

            can : [

                //User Management
                { action : 'view', subject : 'main_menu@user_management'},

                //Device
                { action : 'view', subject : 'add_button@devices_datatable'},
                { action : 'view', subject : 'edit_button@devices_datatable'},

                //Sensors
                { action : 'view', subject : 'add_button@sensors_datatable'},
                { action : 'view', subject : 'edit_button@sensors_datatable'},
                { action : 'view', subject : 'action_field@sensors_datatable'},

                //Sensors Class
                { action : 'view', subject : 'add_button@sensors_class_datatable'},
                { action : 'view', subject : 'edit_button@sensors_class_datatable'},
                { action : 'view', subject : 'action_field@sensors_class_datatable'},

                //Sensors Fabricator
                { action : 'view', subject : 'add_button@sensors_fabricator_datatable'},
                { action : 'view', subject : 'edit_button@sensors_fabricator_datatable'},
                { action : 'view', subject : 'action_field@sensors_fabricator_datatable'},

                //Visual Modeling
                { action : 'view', subject : 'add_button@visual_modeling_datatable'},
                { action : 'view', subject : 'edit_button@visual_modeling_datatable'},

                //Locations
                { action : 'view', subject : 'add_button@locations_datatable'},
                { action : 'view', subject : 'edit_button@locations_datatable'},
                { action : 'view', subject : 'action_field@locations_datatable'},

                //Locations Type
                { action : 'view', subject : 'add_button@locations_type_datatable'},
                { action : 'view', subject : 'edit_button@locations_type_datatable'},
                { action : 'view', subject : 'action_field@locations_type_datatable'},

                //Utilities
                { action : 'view', subject : 'add_button@utilities_datatable'},
                { action : 'view', subject : 'edit_button@utilities_datatable'},
                { action : 'view', subject : 'action_field@utilities_datatable'},

                //Utility Type
                { action : 'view', subject : 'add_button@utilities_type_datatable'},
                { action : 'view', subject : 'edit_button@utilities_type_datatable'},
                { action : 'view', subject : 'action_field@utilities_type_datatable'},

                //Login, Select Company
                { action : 'view', subject : 'select_company@login'},

                //Energy Audit Menu
                { action : 'view', subject : 'main_menu@energy_audit'},

                //Variables Menu
                { action : 'view', subject : 'main_menu@variable'},
            
            ]

        },

    },

    {

        level : '2',

        permissions : {

            can : [

                //Login, Select Company
                { action : 'view', subject : 'select_company@login'},

                //Energy Audit Menu
                { action : 'view', subject : 'main_menu@energy_audit'},

                //Variables Menu
                { action : 'view', subject : 'main_menu@variable'},

            ]

        },

    },

]

export {
    permissionsByLevels,
}
import tvIcon from '../assets/img/icons/television.svg'
import airCondIcon from '../assets/img/icons/air-conditioner.png'
import laptopIcon from '../assets/img/icons/laptop.svg'
import refrigeratorIcon from '../assets/img/icons/refrigerator.png'
import electricalPanelIcon from '../assets/img/icons/electrical-panel.png'
import iceBox1 from '../assets/img/icons/ice-box.png'
import iceBox2 from '../assets/img/icons/ice-box2.png'
import server from '../assets/img/icons/server.png'
import cctvIcon from '../assets/img/icons/cctv.png'
import electricalSocket from '../assets/img/icons/wall-socket.png'
import lamp from '../assets/img/icons/lamp.png'
import defaultIcon from '../assets/img/icons/default.png'

const AUDIT_ROLES = 2

const COMPANY_SAMPLE = [
    {
        'label': 'Enermo',
        'value': '1'
    },
    {
        'label': 'Automa',
        'value': '2'
    },
    {
        'label': 'Test',
        'value': '3'
    },
]

const USERLEVEL_OPTION = [
    {
         'label': '-  Please select  -',
         'value': null
    },
    {
        'label': 'Admin',
        'value': '1'
    },
    {
        'label': 'Manager',
        'value': '2'
    },
    {
        'label': 'Internal Auditor',
        'value': '3'
    },
    {
        'label': 'Superadmin',
        'value': '4'
    },
]

const VISUAL_DRAWING_INITIAL_LAST_APPLY = -1;
const VISUAL_DRAWING_LAST_APPLY_SENSORS = 1;
const VISUAL_DRAWING_LAST_APPLY_UTILITIES = 2;
const VISUAL_DRAWING_LAST_APPLY_LAYOUT = 3;

const UTILITIES_TYPE_ICON = [
                                {
                                    "id": 2,
                                    "utility_type_name": "Test",
                                    "icon" : defaultIcon
                                },

                                {
                                    "id": 3,
                                    "utility_type_name": "AC 1/2 PK",
                                    "icon" : airCondIcon
                                },

                                {
                                    "id": 4,
                                    "utility_type_name": "AC 3/4 PK",
                                    "icon" : airCondIcon
                                },

                                {
                                    "id": 5,
                                    "utility_type_name": "AC 1 PK",
                                    "icon" : airCondIcon
                                },

                                {
                                    "id": 6,
                                    "utility_type_name": "Refrigrator - 200L",
                                    "icon" : refrigeratorIcon
                                },

                                {
                                    "id": 7,
                                    "utility_type_name": "Refrigrator - 350L",
                                    "icon" : refrigeratorIcon
                                },

                                {
                                    "id": 8,
                                    "utility_type_name": "Refrigrator - 500L",
                                    "icon" : refrigeratorIcon
                                },
                                
                                {
                                    "id": 9,
                                    "utility_type_name": "Refrigrator - 700L",
                                    "icon" : refrigeratorIcon
                                },

                                {
                                    "id": 10,
                                    "utility_type_name": "LED TV 24-32 inch",
                                    "icon" : tvIcon
                                },

                                {
                                    "id": 11,
                                    "utility_type_name": "LED TV 32-50 inch",
                                    "icon" : tvIcon
                                },

                                {
                                    "id": 12,
                                    "utility_type_name": "LED TV >50 inch",
                                    "icon" : tvIcon
                                },

                                {
                                    "id": 13,
                                    "utility_type_name": "Chest Freezer - 100-200L",
                                    "icon" : iceBox1
                                },

                                {
                                    "id": 14,
                                    "utility_type_name": "Chest Freezer - >200L",
                                    "icon" : iceBox2
                                },
                                {
                                    "id": 15,
                                    "utility_type_name": "LED < 20W",
                                    "icon" : lamp
                                },

                                {
                                    "id": 16,
                                    "utility_type_name": "LED < 50W",
                                    "icon" : lamp
                                },

                                {
                                    "id": 17,
                                    "utility_type_name": "LED > 50W",
                                    "icon" : lamp
                                },

                                {
                                    "id": 18,
                                    "utility_type_name": "Jet Pump",
                                    "icon" : defaultIcon
                                },

                                {
                                    "id": 19,
                                    "utility_type_name": "Laptop Charger < 65W",
                                    "icon" : laptopIcon
                                },

                                {
                                    "id": 20,
                                    "utility_type_name": "Laptop Charger < 150W",
                                    "icon" : laptopIcon
                                },

                                {
                                    "id": 21,
                                    "utility_type_name": "Laptop Charger > 150W",
                                    "icon" : laptopIcon
                                },

                                {
                                    "id": 22,
                                    "utility_type_name": "Jet Washer (>500W)",
                                    "icon" : defaultIcon
                                },

                                {
                                    "id": 23,
                                    "utility_type_name": "Induction Cooker (>1000W)",
                                    "icon" : defaultIcon
                                },

                                {
                                    "id": 24,
                                    "utility_type_name": "Rice Cooker (Small)",
                                    "icon" : defaultIcon
                                },
                                {
                                    "id": 38,
                                    "utility_type_name": "Electrical Panel",
                                    "icon" : electricalPanelIcon

                                },
                                {
                                    "id": 25,
                                    "utility_type_name": "Rice Cooker (Medium)",
                                    "icon" : defaultIcon

                                },
                                {
                                    "id": 33,
                                    "utility_type_name": "Work PC",
                                    "icon" : defaultIcon
                                },
                                {
                                    "id": 34,
                                    "utility_type_name": "Workstation",
                                    "icon" : defaultIcon
                                },
                                {
                                    "id": 39,
                                    "utility_type_name": "Server",
                                    "icon" : server
                                },
                                {
                                    "id": 38,
                                    "utility_type_name": "Electrical Panel",
                                    "icon" : electricalPanelIcon
                                },
                                {
                                    "id": 40,
                                    "utility_type_name": "CCTV",
                                    "icon" : cctvIcon
                                },
                                {
                                    "id": 41,
                                    "utility_type_name": "Electric Socket",
                                    "icon" : electricalSocket
                                },
                            ]

const SENSORS_COLOR_IN_GRAPH = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'gray', 'green', 
                        'lime', 'maroon', 'navy', 'olive', 'orange', 'purple', 'red', 
                        'silver', 'teal', 'white', 'black'];

const SENSOR_COLOR_PER_TYPE = {
    'volt' : '#0088FE',
    'curr' : '#00C49F',
    'actp' : '#FFBB28',
    'appp' : '#FF8042',
    'reap' : 'gray',
    'powf' : 'green',
    'edel' : 'maroon',
    'stat' : 'silver',
    
    'iavg' : '#0088FE',
    'vavg' : '#00C49F',
    'pfac' : '#FFBB28',
    'freq' : '#FF8042',
    'stot' : 'gray',
    'qtot' : 'maroon',
    'ptot' : 'green',
    'sdtc' : 'black',

    'tem1' : '#0088FE', 
    'hum1' : '#FF8042', 
    'tem4' : '#0088FE', 
    'hum4' : '#FF8042', 
    'tem5' : '#0088FE',
    'hum5' : '#FF8042', 
}


const TIME_FORECAST = [
    {
        "label" : "Next Month",
        "value" : 1
    },
    // {
    //     'label' : 'Next 6 Months',
    //      'value' : 6
    // }
]

const AUDIT_PERIODICITY_SELECTION = [
    {
        "label" : "Daily",
        "value" : 1
    },
    {
        "label" : "Weekly",
        "value" : 2
    },

    {
        "label" : "Monthly",
        "value" : 3
    },
    // {
    //     "label" : "Yearly",
    //     "value" : 4
    // },
]

// let generateIncrementalTemHumSensor = () => {
//     let result = []
//     console.log("Asaddasdasdasd")
//     for(let i = 1; i > 100; i++){
//         result.push('tem'+i)
//         result.push('hum'+i)
//     }
//     console.log("Asaddasdasdasd")
//     console.log(result)
//     return result
// }

const SENSOR_TYPES_BY_CATEGORY = {
    electricity: ['vavg', 'iavg', 'pfac', 'freq',
                    'stot', 'ptot', 'qtot', 'edel'],
    temperatureHumidity: ['tem1', 'hum1', 'tem4', 'hum4', 'tem5', 'hum5'],
    door:['door']
}

export {

    USERLEVEL_OPTION,
    VISUAL_DRAWING_INITIAL_LAST_APPLY,
    VISUAL_DRAWING_LAST_APPLY_SENSORS,
    VISUAL_DRAWING_LAST_APPLY_UTILITIES,
    VISUAL_DRAWING_LAST_APPLY_LAYOUT,
    UTILITIES_TYPE_ICON,
    SENSORS_COLOR_IN_GRAPH,
    TIME_FORECAST,
    AUDIT_PERIODICITY_SELECTION,
    SENSOR_COLOR_PER_TYPE,
    AUDIT_ROLES,
    COMPANY_SAMPLE,
    SENSOR_TYPES_BY_CATEGORY,
    defaultIcon,
    electricalPanelIcon
}
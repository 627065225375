import {SENSOR_TYPES_BY_CATEGORY} from '../../../constants/index'

export function deviceValueFactory(liveDataFromDashboard, sensor_type, deviceName){
    let sensor_type_arr = []
    sensor_type.map((t)=>(sensor_type_arr.push(t.sensor_type)))

    let isSensorElectricityCategory = SENSOR_TYPES_BY_CATEGORY['electricity'].some(r=> sensor_type_arr.indexOf(r) >= 0)
    if(isSensorElectricityCategory)
      return getElectricitySensorValue(liveDataFromDashboard, deviceName)

    let isSensorTempHumCategory = SENSOR_TYPES_BY_CATEGORY['temperatureHumidity'].some(r=> sensor_type_arr.indexOf(r) >= 0)
    if(isSensorTempHumCategory)
      return getTempHumSensorValue(liveDataFromDashboard, deviceName)

    let isSensorDoorCategory = SENSOR_TYPES_BY_CATEGORY['door'].some(r=> sensor_type_arr.indexOf(r) >= 0)
      if(isSensorDoorCategory)
        return getDoorSensorValue(liveDataFromDashboard, deviceName)
}

function getElectricitySensorValue(liveDataFromDashboard, deviceName){
    let valueStr = liveDataFromDashboard.electricity[0]? Number(liveDataFromDashboard.electricity[0].value).toFixed(2) +" kWH\n" : "-" 
    return "\n\n" +valueStr + "\n" + deviceName
}

function getTempHumSensorValue(liveDataFromDashboard, deviceName){
    let valueStr = ""
    if(!liveDataFromDashboard.temHum)
      return "\n\n" + "-" + "\n" + deviceName
    {Object.keys(liveDataFromDashboard.temHum).map(key=> {
        if(String(key).includes("tem"))
          return  valueStr += liveDataFromDashboard.temHum[key]? Number(liveDataFromDashboard.temHum[key]).toFixed(2) + " C\n" : "-" 
        if(String(key).includes("hum"))
          return  valueStr += liveDataFromDashboard.temHum[key]? Number(liveDataFromDashboard.temHum[key]).toFixed(2) + " RH\n" : "-" 
     })}
    return "\n\n" +valueStr + "\n" + deviceName
}

function getDoorSensorValue(liveDataFromDashboard, deviceName){
  if(! liveDataFromDashboard.door)
    return "\n\n... "  + "\n\n" + deviceName
  if(liveDataFromDashboard.door.length === 0)
    return "\n\... "  + "\n\n" + deviceName
  let valueStr = liveDataFromDashboard.door? Number(liveDataFromDashboard.door.door) === 1? "Open" : "Closed" : "..." 
  return "\n\n" +valueStr + "\n\n" + deviceName
}
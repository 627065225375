import * as API_LINKS from './api.links';
import axios from 'axios';


//Visual Model
export function fetchVisualModelService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_LIST,
        headers: {
            Authorization: auth
        },
    });
}

export function createVisualModelService(newVisualModel, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_ADD,
        headers: {
            Authorization: auth
        },
        data: newVisualModel 
    })
}

export function editVisualModelService(visualModel, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_EDIT,
        headers: {
            Authorization: auth,            
        },
        data: visualModel 
    })
}

export function deleteVisualModelService(visualModelId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_DELETE,
        headers: {
            Authorization: auth,
        },
        data : {id: visualModelId}
    })
}

//Visual Model Device
export function fetchVisualModelDeviceService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_DEVICE_LIST,
        headers: {
            Authorization: auth
        },
    });
}

export function createVisualModelDeviceService(newVisualModelDevice, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_DEVICE_ADD,
        headers: {
            Authorization: auth
        },
        data: newVisualModelDevice 
    })
}

export function editVisualModelDeviceService(visualModelDevice, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_DEVICE_EDIT,
        headers: {
            Authorization: auth
        },
        data: visualModelDevice 
    })
}

export function deleteVisualModelDeviceService(visualModelDeviceId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_DEVICE_DELETE,
        headers: {
            Authorization: auth
        },
        data : {id: visualModelDeviceId}
    })
}

//Visual Model Sensor
export function fetchVisualModelSensorService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_SENSOR_LIST,
        headers: {
            Authorization: auth
        },
    });
}

export function createVisualModelSensorService(newVisualModelSensor, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_SENSOR_ADD,
        headers: {
            Authorization: auth
        },
        data: newVisualModelSensor 
    })
}

export function editVisualModelSensorService(visualModelSensor, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_SENSOR_EDIT,
        headers: {
            Authorization: auth
        },
        data: visualModelSensor 
    })
}

export function deleteVisualModelSensorService(visualModelSensorId, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_SENSOR_DELETE,
        headers: {
            Authorization: auth
        },
        data : {id: visualModelSensorId}
    })
}

//Visual Model Utilities
export function fetchVisualModelUtilitiesService(auth){
    return  axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_UTILITIES_LIST,
        headers: {
            Authorization: auth
        },
    });
}

export function createVisualModelUtilitiesService(newVisualModelUtilities, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_UTILITIES_ADD,
        headers: {
            Authorization: auth
        },
        data: newVisualModelUtilities 
    })
}

export function editVisualModelUtilitiesService(visualModelUtilities, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_UTILITIES_EDIT,
        headers: {
            Authorization: auth
        },
        data: visualModelUtilities 
    })
}

export function deleteVisualModelUtilitiesService(visualModelUtilities, auth){
    return axios({
        method: 'post',
        url: API_LINKS.VISUAL_MODEL_UTILITIES_DELETE,
        headers: {
            Authorization: auth
        },
        data : {id: visualModelUtilities}
    })
}
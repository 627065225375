import {
    FETCH_LL_LOCATION_LIST,
    RECEIVE_LL_LOCATION_LIST,
    FAILED_LL_LOCATION_LIST,
    EDIT_LOCATION_TYPE,
    DELETE_LOCATION_TYPE
} from "../types/location-list.types";

const defaultLLLocationState = {
    llLocationData: [],
    llTotalLocation: 0,
    llLocationSelect: [],
    llLocationSelectWithIdCompany: [],
    llLocationParentSelect: [],
    success: true,
    inProgress: false,
}

export function fetchLocations(state = defaultLLLocationState, action){

    const { type, payload } = action;

    switch (type) {
        case FETCH_LL_LOCATION_LIST:
            return ({llLocationData: [], success: true, llTotalLocation: 0, llLocationSelect: [], llLocationParentSelect: [], inProgress: true})

        case RECEIVE_LL_LOCATION_LIST:
            var _totalLocation = payload.result.length;
            var _selectLocation = []
            let _selectLocationWithCompanyId = []
            var _llLocationParentData = [{'label': "-", 'value':null}]
            if(payload.result !== []) {
                var _locationData = payload.result
                _locationData.forEach((p, index) => {
                    var _loc = {
                        'label': _locationData[index].location_name + " ("+_locationData[index].location_code+")",
                        'value': _locationData[index].id
                    }

                    let _locWithIdCompany = {
                        'label': _locationData[index].location_name + " ("+_locationData[index].location_code+")",
                        'value': _locationData[index].id,
                        'id_company': _locationData[index].id_company
                    }

                    var _locParent = {
                        'label': _locationData[index].location_name + " ("+_locationData[index].location_code+")",
                        'value': _locationData[index].id
                    }

                    _selectLocation.push(_loc)
                    _selectLocationWithCompanyId.push(_locWithIdCompany)
                    _llLocationParentData.push(_locParent)
                })
            }
            return Object.assign({}, state, {llLocationData: payload, success: true, llTotalLocation: _totalLocation, llLocationParentSelect: _llLocationParentData,
                                        llLocationSelect: _selectLocation, llLocationSelectWithIdCompany:_selectLocationWithCompanyId, inProgress: false})
        
        case FAILED_LL_LOCATION_LIST:
            return ({llLocationData: [], success: false, llTotalLocation: 0, llLocationSelect: [], llLocationParentSelect: [], inProgress: false})
        
        case  EDIT_LOCATION_TYPE:
            return state

        case DELETE_LOCATION_TYPE:
            return state

        default:
            return state
    }
}
import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Redirect } from "react-router";
import { Provider } from "react-redux";
import store from './redux/store';
import { AbilityContext } from './casl/Can'
import ability from './casl/Ability.jsx'
import Loadable from 'react-loadable';
import './App.scss';
import Dashboard from './views/Enermo/Dashboard/';
import Logout from './views/Users/Logout/';

const Loading = () => <div className="animated fadeIn pt-3 text-center">
                          <div className="sk-spinner sk-spinner-pulse"></div>
                      </div>;

const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading: Loading
});

const Login = Loadable({
  loader: () => import('./views/Users/Login/'),
  loading: Loading
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AbilityContext.Provider value={ability}>
          <HashRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login}/>
              <Route path="/" name="Home" component={DefaultLayout} />
              <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
              <Route path="/logout" name="Logout" component={Logout}/>
              
              <Redirect from="/" to="/dashboard"/>
            </Switch>
          </HashRouter>
        </AbilityContext.Provider>
      </Provider>
    );
  }
}

export default App;

import {
    FETCH_LL_RESOURCE_USAGE_TYPE_LIST,
    RECEIVE_LL_RESOURCE_USAGE_TYPE_LIST,
    FAILED_LL_RESOURCE_USAGE_TYPE_LIST
} from "../types/resource-usage-types.types";

const defaultLLResourceUsageTypeState = {
    llResourceUsageTypeData: [],
    llTotalResourceUsageType: 0,
    llResourceUsageTypeSelect: [],
    success: true,
    inProgress: false,
}

export function fetchResourceUsageTypes(state = defaultLLResourceUsageTypeState, action){

    const { type, payload } = action

    switch (type) {
        case FETCH_LL_RESOURCE_USAGE_TYPE_LIST:
            return ({llResourceUsageTypeData: [], success: true, llTotalResourceUsageType: 0, llResourceUsageTypeSelect: [], inProgress: true})

        case RECEIVE_LL_RESOURCE_USAGE_TYPE_LIST:
            var _totalResourceUsageType = payload.result.length;
            var _selectResourceUsageType = []
            if(payload.result !== []) {
                var _resourceUsageTypeData = payload.result

                _resourceUsageTypeData.forEach((p, index) => {
                    var _resUsageType = {
                        'label': _resourceUsageTypeData[index].resource_usage_type_name ,
                        'value': _resourceUsageTypeData[index].id
                    }
                    _selectResourceUsageType.push(_resUsageType)
                })
            }
            return Object.assign({}, state, {llResourceUsageTypeData: payload, success: true, llTotalResourceUsageType: _totalResourceUsageType, llResourceUsageTypeSelect: _selectResourceUsageType, inProgress: false})

        case FAILED_LL_RESOURCE_USAGE_TYPE_LIST:
            return ({llResourceUsageTypeData: [], success: false, llTotalResourceUsageType: 0, llResourceUsageTypeSelect: [], inProgress: false})

        default:
            return state
    }
}
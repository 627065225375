export const FETCH_KWH_AVERAGE = 'FETCH_KWH_AVERAGE'
export const RECEIVE_KWH_AVERAGE = 'RECEIVE_KWH_AVERAGE'
export const FAILED_KWH_AVERAGE = 'FAILED_KWH_AVERAGE'

export const FETCH_SENSOR_HISTORY_GROUP_AVERAGE = 'FETCH_SENSOR_HISTORY_GROUP_AVERAGE'
export const RECEIVE_SENSOR_HISTORY_GROUP_AVERAGE = 'RECEIVE_SENSOR_HISTORY_GROUP_AVERAGE'
export const FAILED_SENSOR_HISTORY_GROUP_AVERAGE = 'FAILED_SENSOR_HISTORY_GROUP_AVERAGE'

export const FETCH_SENSOR_ALERT_GROUP_AVERAGE = 'FETCH_SENSOR_ALERT_GROUP_AVERAGE'
export const RECEIVE_SENSOR_ALERT_GROUP_AVERAGE = 'RECEIVE_SENSOR_ALERT_GROUP_AVERAGE'
export const FAILED_SENSOR_ALERT_GROUP_AVERAGE = 'FAILED_SENSOR_ALERT_GROUP_AVERAGE'

export const FETCH_DEVICE_ACTIVE_TIME = 'FETCH_DEVICE_ACTIVE_TIME'
export const RECEIVE_DEVICE_ACTIVE_TIME = 'RECEIVE_DEVICE_ACTIVE_TIME'
export const FAILED_DEVICE_ACTIVE_TIME = 'FAILED_DEVICE_ACTIVE_TIME'

export const FETCH_NORMAL_PEAK_TARIFF = 'FETCH_NORMAL_PEAK_TARIFF'
export const RECEIVE_NORMAL_PEAK_TARIFF = 'RECEIVE_NORMAL_PEAK_TARIFF'
export const FAILED_NORMAL_PEAK_TARIFF = 'FAILED_NORMAL_PEAK_TARIFF'

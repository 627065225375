import * as API_LINKS from './api.links';
import axios from 'axios';

export function uploadImageService(auth, uploadImageData){
    return  axios({
                method: 'post',
                url: API_LINKS.UPLOAD_IMAGE,
                headers: {
                    Authorization: auth,
                    'Content-Type': 'multipart/form-data'
                },
                data: uploadImageData
            });
}
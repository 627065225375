import { combineReducers } from "redux"
import { uploadImageReducer } from "./upload-image.red"
import { fetchAuthReducer, changePasswordReducer } from "./auth.red"
import { fetchLocations } from "./location-list.red"
import { locationTypesReducer } from "./location-types.red"
import { devicesReducer, fetchDeviceFabricatorListReducer } from "./devices.red"
import { fetchSensorsReducer } from "./sensor-list.red"
import { utilityReducer} from "./utilities.red"
import { utilityTypeReducer } from "./utility-types.red"
import { fetchUsageSchedules } from "./usage-schedules.red"
import { fetchResourceUsageTypes } from "./resource-usage-types.red"
import { fetchSensorClassListReducer } from "./sensor-class-list.red"
import { fetchSensorFabricatorListReducer } from "./sensor-fabrication-list.red"
import { fetchSensorTypeListReducer } from "./sensor-type-list.red"
import { fetchDeviceInstallationListReducer } from "./device-installation-list.red"
import { fetchUserListReducer } from "./user-list.red"
import { fetchLiveDataReducer, fetchLiveDataByIdDeviceTimeUnixReducer
        , fetchLiveDataByIdDeviceIdSensorTimeUnixReducer, fetchLiveDataByMultiIdDevicesTimeUnixReducer} from "./live-monitoring.red"
import { fetchKwhAverageReducer, fetchSensorHistoryGroupingReducer, 
        fetchAlertHistoryGroupingReducer, fetchDeviceActiveTimeReducer,
        fetchNormalPeakTariffReducer} from './processing-live-monitoring.red'
import { visualModelReducer, visualModelDeviceReducer, visualModelSensorReducer, visualModelUtilitiesReducer} from './visual-modeling.red'
import { companiesReducer } from "./companies.red"
import { generalSettingReducer, budgetSettingReducer } from "./setting.red"

import { monthlyKwhSetsReducer } from "./Dashboard/monthly-kwh-sets.red"
import { auditGroupSetsReducer } from "./Audit/audit-group.red"

export default combineReducers({
    authStore : fetchAuthReducer,
    changePasswordStore : changePasswordReducer,
    uploadImageReducerStore : uploadImageReducer,

    locationsStore: fetchLocations,

    devicesStore : devicesReducer,
    devicesFabricatorStore : fetchDeviceFabricatorListReducer,

    sensorsStore : fetchSensorsReducer,
    sensorClassStore : fetchSensorClassListReducer,
    sensorFabricatorStore : fetchSensorFabricatorListReducer,
    sensorTypeListStore : fetchSensorTypeListReducer,

    deviceInstallationListStore : fetchDeviceInstallationListReducer,

    userTableStore : fetchUserListReducer,

    locationTypesStore: locationTypesReducer,
    usageSchedulesStore: fetchUsageSchedules,
    resourceUsageTypesStore: fetchResourceUsageTypes,
    utilitiesStore: utilityReducer,
    utilityTypesStore: utilityTypeReducer,

    liveDataStore : fetchLiveDataReducer,
    liveDataByIdDeviceTimeUnixStore : fetchLiveDataByIdDeviceTimeUnixReducer,
    liveDataByIdDeviceIdSensorTimeUnixStore : fetchLiveDataByIdDeviceIdSensorTimeUnixReducer,
    liveDataByMultiIdDevicesTimeUnixStore: fetchLiveDataByMultiIdDevicesTimeUnixReducer,

    processingLiveMonitoringKwhAverageStore : fetchKwhAverageReducer,
    processingLiveMonitoringSensorHistoryGroupingStore : fetchSensorHistoryGroupingReducer,
    processingLiveMonitoringSensorAlertGroupingStore : fetchAlertHistoryGroupingReducer,
    processingLiveMonitoringDeviceActiveTimeStore : fetchDeviceActiveTimeReducer,
    processingLiveMonitoringNormalPeakTariffStore : fetchNormalPeakTariffReducer,

    visualModelReducerStore: visualModelReducer,
    visualModelSensorReducerStore: visualModelSensorReducer,
    visualModelUtilitiesReducerStore: visualModelUtilitiesReducer,
    visualModelDeviceReducerStore: visualModelDeviceReducer,
    
    companiesStore : companiesReducer,

    generalSettingStore : generalSettingReducer, 
    budgetSettingStore : budgetSettingReducer,

    //Non API
    //Dashboard
    monthlyKwhSetsStore : monthlyKwhSetsReducer,
    auditGroupSetsStore : auditGroupSetsReducer

})
import axios from 'axios'

const BASE_LINK = "https://secure-lowlands-34022.herokuapp.com/https://www.metaweather.com/api"

export async function fetchMyLocation(lat, long){
    let urlString = BASE_LINK+"/location/search/?lattlong="
    urlString += lat+","+long
    const response = await axios.get(urlString)
    return response.data[0].title
}

export async function fetchTodayWeather(yourLocation){
    let woeId = await _getLocationWoe(yourLocation)
    let weatherData = await _getTodaysWeather(woeId)
    return weatherData
}

async function _getLocationWoe(locationName){
    const URL_STRING = BASE_LINK+"/location/search/?query="+locationName
    const response = await axios.get(URL_STRING)
    return response.data[0].woeid
}

async function _getTodaysWeather(woeId){
    let URL_STRING = BASE_LINK+"/location/"+woeId 
    const response = await axios.get(URL_STRING)
    return response.data["consolidated_weather"][0]
}

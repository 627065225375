import {
    FETCH_LL_UTILITY_LIST,
    RECEIVE_LL_UTILITY_LIST,
    FAILED_LL_UTILITY_LIST,
    EDIT_LL_UTILITY,
    DELETE_LL_UTILITY
} from "../types/utilities.types";

import {fetchUtilitiesService, 
        editUtilityService, 
        deleteUtilityService} from  "../../services/utilities.service";

export const fetchUtilities = () => async (dispatch) => {
    try {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        dispatch({
            type: FETCH_LL_UTILITY_LIST,
        });
        const response = await fetchUtilitiesService(auth);
        if(response.status === 200){
                dispatch({
                    type: RECEIVE_LL_UTILITY_LIST,
                    payload: response.data
                });
        }
    } catch(error) {
        dispatch({
            type: FAILED_LL_UTILITY_LIST,
        });
       return Promise.reject(error);
    }
};

export const editUtility=  data => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await editUtilityService(data, auth);
      dispatch({
        type: EDIT_LL_UTILITY,
        payload: data,
      });

      return Promise.resolve(response.status);

    } catch (err) {
      return Promise.reject(err);
    }
};

export const deleteUtility = (id) => async (dispatch) => {
    try {
      let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      let auth = loginCreds.token;
      const response = await deleteUtilityService(id, auth);
      dispatch({
        type: DELETE_LL_UTILITY,
        payload: { id },
      });

      return Promise.resolve(response.status);
    } catch (err) {
        return Promise.reject(err);
    }
  };

import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid,
         ResponsiveContainer,Tooltip as RechartsToolTip } from 'recharts';
import {SENSOR_COLOR_PER_TYPE} from "../../constants";
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import moment from 'moment';
import { BarLoader } from 'react-spinners'

class PowerConsumptionChart extends Component { 
    
    constructor(props){
        super(props);

        this.state = {
            setsOfData: [],
            sensorCheckBox : [],
            sensorToShow: [],
            updateChartArea: true,
            deviceId: null
        }
    }

    checkDateDifference(setsOfDataLength, data){
       if(this.state.setsOfData[setsOfDataLength] === []){ 
             this.setState({
                setsOfData: [],
                updateChartArea : true
            }) 
            return;
        }

        if(this.state.setsOfData[setsOfDataLength].date !== data.date){
            this.setState({
                setsOfData: [],
                updateChartArea : true
            })
        }
    }
    
    componentWillReceiveProps(nextProps) {

        if(!nextProps.data || !nextProps.deviceId)
            return
        if(nextProps.deviceId !== this.state.deviceId){
            this.setState({
                setsOfData: [],
                sensorCheckBox : nextProps.sensors,
                sensorToShow: nextProps.sensors,
                deviceId: nextProps.deviceId,
            })
        }
        if(nextProps.data.length > 0){
            if(this.state.setsOfData.length === 0){
                this.setState({
                    setsOfData: nextProps.data
                })
                return
            }
            let lengthSetsOfData = this.state.setsOfData.length
            let lastData = nextProps.data
            let tempMonitoringData = []
            let dayDifferentHappend = false
            for(let i = 0; i < nextProps.data.length; i++) {
                let itemToCheck =  new Date(nextProps.data[nextProps.data.length-1].date + " " + nextProps.data[nextProps.data.length-1].time)
                let lastUpdateDateTime = new Date(this.state.setsOfData[lengthSetsOfData-1].date + " " + this.state.setsOfData[lengthSetsOfData-1].time)

                if(moment(new Date(this.state.setsOfData[lengthSetsOfData-1].date)).format('DD-MM-YYYY') 
                                             !== moment(new Date(nextProps.data[nextProps.data.length-1].date)).format('DD-MM-YYYY')){
                    dayDifferentHappend = true
                }

                if((lastUpdateDateTime < itemToCheck) && (nextProps.data.time !== nextProps.data[nextProps.data.length-1]).time) {
                    tempMonitoringData.push(nextProps.data[nextProps.data.length-1])
                }
            }
            if(dayDifferentHappend){
                this.setState({
                    setsOfData : tempMonitoringData,
                    // setsOfData: nextProps.data,
                    updateChartArea : true
                })
            } else {
                this.setState({
                    setsOfData : [...lastData, ...tempMonitoringData],
                    // setsOfData: nextProps.data,
                    updateChartArea : true
                })
            }
        } else {
            this.setState({
                setsOfData: [],
                sensorCheckBox : [],
                sensorToShow: [],
            })
        }
            // if(setsOfDataLength > 0){
            //     this.checkDateDifference(setsOfDataLength-1, nextProps.data);
            // }               
    }
    
    toggleCheckedSensorLegend(sensorName, isChecked){
        let tempSensorToShow = this.state.sensorToShow
        if(isChecked){
            tempSensorToShow.push(sensorName)
        }
        if(!isChecked){
            tempSensorToShow = tempSensorToShow.filter(item => item !== sensorName)
        }
        this.setState({
            sensorToShow: tempSensorToShow
        })
    }

    additionalInfo(sensor){
        switch(sensor){
            case "iavg" : return "(average current)"
            case "vavg" : return "(average voltage)"
            case "edel" : return "(energy deliver)"
            case "freq" : return "(frequency)"
            case "pfac" : return "(power factor)"
            case "stot" : return "(apparent power)" 
            case "ptot" : return "(active power)"
            case "qtot" : return "(reactive power)" 
            case "sdtc" : return "(data checker)" 
            case "tem1" : return "(Temperature 1)" 
            case "tem2" : return "(Temperature 2)" 
            case "tem3" : return "(Temperature 3)" 
            case "hum1" : return "(Humidity 1)" 
            case "hum2" : return "(Humidity 2)" 
            case "hum3" : return "(Humidity 3)" 
            case "door" : return "(Door)"
            default : return ""
        }
    }

    render(){
        const {sensorCheckBox, sensorToShow} = this.state
        return (
            <React.Fragment>
                {sensorCheckBox ? 
                    sensorCheckBox.length === 0 ? 
                    <Row>
                        <Col style={{marginLeft: '15px', float:'none'}}>
                            <BarLoader loading={sensorCheckBox.length === 0}/>
                        </Col>
                    </Row>
                     :
                     <React.Fragment>
                        <Form style={{marginLeft : '27px'}}>
                            <FormGroup check>
                            {
                                sensorCheckBox.map( s => (
                                    <Label check style={{paddingLeft : '25px', "color":SENSOR_COLOR_PER_TYPE[s]}}>
                                        <Input 
                                            name={s}
                                            defaultChecked={true}
                                            type="checkbox" 
                                            onChange={(e) => this.toggleCheckedSensorLegend(e.target.name, e.target.checked)}/>{' '}
                                            {s} {this.additionalInfo(s)}
                                    </Label>
                            ))}
                            </FormGroup>
                        </Form>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={this.state.setsOfData}
                                margin={{ top: 25, right: 20, left: 0, bottom: 0 }}>
                                {/* <defs>
                                    {
                                        sensors.map((s, index) => (
                                            <linearGradient id={s.replace(/\s/g, '')} x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor={SENSORS_COLOR_IN_GRAPH[index]} stopOpacity={0.7}/>
                                                <stop offset="95%" stopColor={SENSORS_COLOR_IN_GRAPH[index]} stopOpacity={0}/>
                                            </linearGradient>
                                        ))
                                    }
                                </defs> */}
                                <XAxis dataKey="time" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <RechartsToolTip />
                                    {
                                        sensorToShow.map(s => (
                                            <Line type="monotone" dataKey={s}
                                                        stroke={SENSOR_COLOR_PER_TYPE[s]} /*fillOpacity={1}*/
                                                        strokeWidth={3}
                                                        /*fill={`url(#${s.replace(/\s/g, '')})`}*/ />                        
                                        ))
                                    }
                            </LineChart>
                        </ResponsiveContainer>
                    </React.Fragment>
                : <Row>
                        <Col style={{marginLeft: '15px', float:'none'}}>
                            <BarLoader loading={!sensorCheckBox}/>
                        </Col>
                 </Row>}
            </React.Fragment>
        )
    };
}

export default PowerConsumptionChart;

import {
    FETCH_LOGIN ,
    RECEIVE_LOGIN,
    FAILED_LOGIN ,
    FETCH_LOGOUT ,
    RECEIVE_LOGOUT,
    FETCH_CHECK_AUTH,
    RECEIVE_CHECK_AUTH,
    GET_CURRENT_USER_PROFILE,
    CHANGE_PASSWORD,
    RECEIVE_CHANGE_PASSWORD_RESULT
} from "../types/auth.type";
  
  const initialState = {
    authData: [],
    inProgress: false,
    isAuthenticated: false 
  }
  
export function fetchAuthReducer(state = initialState, action) {

    const { type, payload } = action;
  
    switch (type) {

        case FETCH_LOGIN: 
            return ({
                authData: null,
                inProgress: true,
                isAuthenticated: false
            });

        case RECEIVE_LOGIN:
            return Object.assign(
                {},
                state,
                payload,
                {
                    authData: payload, 
                    inProgress: false,
                    isAuthenticated: true
                }
            );

        case FAILED_LOGIN: 
            return ({
                authData: null,
                inProgress: false,
                isAuthenticated: false
            });

        case FETCH_LOGOUT: 
            return ({
                ...state,
                inProgress: true
            });

        case RECEIVE_LOGOUT:
            return ({
                authData: null,
                inProgress: false,
                isAuthenticated: false
            });

        case FETCH_CHECK_AUTH:
            return ({
                ...state,
                inProgress: true,
                isAuthenticated: false
            });

        case RECEIVE_CHECK_AUTH:
            return checkPayloadWhenReceiveCheckAuth(payload, state);

        case GET_CURRENT_USER_PROFILE:
            return state

        default:
            return state;

    }

};

function checkPayloadWhenReceiveCheckAuth(payload, state){

    switch (payload) { 

        case payload === '[]':
            return ({
                authData: null,
                inProgress: false,
                isAuthenticated: true
            });
        
        case payload == null :
            return({
                ...state,
                inProgress: false,
                isAuthenticated: false
            });

        case payload != null :
            return({
                ...state,
                inProgress: false,
                isAuthenticated: true
            });

        default:
            return({
                ...state,
                inProgress: false,
                isAuthenticated: true
            });
    }
}

const changePasswordState = {
    changePasswordResult: [],
    success: true,
    inProgress: false,
}

export function changePasswordReducer(state = changePasswordState, action){

    const { type, payload } = action

    switch (type) {
        case CHANGE_PASSWORD:
            return ({changePasswordResult: [], success: true, inProgress: true})

        case RECEIVE_CHANGE_PASSWORD_RESULT:
            return Object.assign({}, state, {changePasswordResult: payload, success: true, inProgress: false})

        default:
            return state
    }
}

import {
    FETCH_KWH_AVERAGE,
    RECEIVE_KWH_AVERAGE,
    FAILED_KWH_AVERAGE,

    FETCH_SENSOR_HISTORY_GROUP_AVERAGE,
    RECEIVE_SENSOR_HISTORY_GROUP_AVERAGE,
    FAILED_SENSOR_HISTORY_GROUP_AVERAGE,

    FETCH_SENSOR_ALERT_GROUP_AVERAGE,
    RECEIVE_SENSOR_ALERT_GROUP_AVERAGE,
    FAILED_SENSOR_ALERT_GROUP_AVERAGE,

    FETCH_DEVICE_ACTIVE_TIME,
    RECEIVE_DEVICE_ACTIVE_TIME,
    FAILED_DEVICE_ACTIVE_TIME,

    FETCH_NORMAL_PEAK_TARIFF,
    RECEIVE_NORMAL_PEAK_TARIFF,
    FAILED_NORMAL_PEAK_TARIFF
} from "../types/processing-live-monitoring.types";

const defaultKwhAverage = {
    kwhAverage: [],
    success: true,
    inProgress: false,
}

export function fetchKwhAverageReducer(state = defaultKwhAverage, action){
    const {type, payload} = action
    switch (type) {
        case FETCH_KWH_AVERAGE:
            return ({kwhAverage: [], inProgress: true})
        case RECEIVE_KWH_AVERAGE:
            return Object.assign({}, state, {kwhAverage: payload, inProgress: false})
        case FAILED_KWH_AVERAGE:
            return ({kwhAverage: [], inProgress: false})
        default:
            return state
    }
}

const defaultSensorHistoryGrouping = {
    sensorHistoryGrouping: [],
    success: true,
    inProgress: false,
}

export function fetchSensorHistoryGroupingReducer(state = defaultSensorHistoryGrouping, action){
    const {type, payload} = action
    switch (type) {
        case FETCH_SENSOR_HISTORY_GROUP_AVERAGE:
            return ({sensorHistoryGrouping: [], inProgress: true})
        case RECEIVE_SENSOR_HISTORY_GROUP_AVERAGE:
            return Object.assign({}, state, {sensorHistoryGrouping: payload, inProgress: false})
        case FAILED_SENSOR_HISTORY_GROUP_AVERAGE:
            return ({sensorHistoryGrouping: [], inProgress: false})
        default:
            return state
    }
}

const defaultAlertHistoryGrouping = {
    sensorAlertrouping: [],
    success: true,
    inProgress: false,
}

export function fetchAlertHistoryGroupingReducer(state = defaultAlertHistoryGrouping, action){
    const {type, payload} = action
    switch (type) {
        case FETCH_SENSOR_ALERT_GROUP_AVERAGE:
            return ({sensorAlertrouping: [], inProgress: true})
        case RECEIVE_SENSOR_ALERT_GROUP_AVERAGE:
            return Object.assign({}, state, {sensorAlertrouping: payload, inProgress: false})
        case FAILED_SENSOR_ALERT_GROUP_AVERAGE:
            return ({sensorAlertrouping: [], inProgress: false})
        default:
            return state
    }
}

const defaultDeviceActiveTime = {
    deviceActiveTime: [],
    success: true,
    inProgress: false,
}

export function fetchDeviceActiveTimeReducer(state = defaultDeviceActiveTime, action){
    const {type, payload} = action
    switch (type) {
        case FETCH_DEVICE_ACTIVE_TIME:
            return ({deviceActiveTime: [], inProgress: true})
        case RECEIVE_DEVICE_ACTIVE_TIME:
            return Object.assign({}, state, {deviceActiveTime: payload, inProgress: false})
        case FAILED_DEVICE_ACTIVE_TIME:
            return ({deviceActiveTime: [], inProgress: false})
        default:
            return state
    }
}

const defaultNormalPeakTariff = {
    normalPeakTariff: [],
    success: true,
    inProgress: false,
}

export function fetchNormalPeakTariffReducer(state = defaultNormalPeakTariff, action){
    const {type, payload} = action
    switch (type) {
        case FETCH_NORMAL_PEAK_TARIFF:
            return ({normalPeakTariff: [], inProgress: true})
        case RECEIVE_NORMAL_PEAK_TARIFF:
            return Object.assign({}, state, {normalPeakTariff: payload, inProgress: false})
        case FAILED_NORMAL_PEAK_TARIFF:
            return ({normalPeakTariff: [], inProgress: false})
        default:
            return state
    }
} 